import React from 'react';

const slide = (props) => {
	return (
		<div>
  		<div className="profile">
  		  <img src={props.photo} alt={props.name + '' + props.title}/>
  		</div>
			<blockquote className={props.side}>
				<p>&ldquo;{props.quote}&rdquo;</p>
				<cite><strong>{props.name}</strong>{props.title}</cite>
				{props.desc ? <em>{props.desc}</em> : null}
			</blockquote>
		</div>
	)
}

export default slide;
// Imports
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

// Components
import Header from '../../components/Header/Header';
import Banner from '../../components/Banner/Banner';
import Footer from '../../components/Footer/Footer';


class Providers extends Component {

  render() {

    return (
      <article>
				<Helmet>
					<title>Providers - Seamlessly Integrate Behavioral Health Care for Better Patient Outcomes</title>
					<meta name="description" content="Integrate aptihealth and fast-track your patients into expert, evidence-based care tailored to help your patients achieve positive results within 90 days."/>
				</Helmet>
        
        <Header />
        <Banner page='page-provider' heading='Seamlessly Integrate Behavioral Health Care for Better Patient Outcomes'/>
        
        <section>
            
            <div className="provider">
              <div className="col">
                <div className="content">
                  <h2 className="divide">Behavioral Health Therapists</h2>
                  <p>Be empowered to deliver personalized, goal-oriented teletherapy as part of a fully collaborative, member-centric care team that inspires life-changing outcomes.</p>
                  <p>aptihealth’s <strong>integrated, intelligent platform</strong> pioneers significant clinical efficiencies that free you to focus on members in your care without the traditional administrative burdens.</p>
                  <Link to="/providers/behavioral-specialists/" className='btn sm'>Get Started</Link>                
                </div>
              </div>
              <div className="col photo behavioral"></div>
            </div>
            
            <div className="provider alt">
              <div className="col">
                <div className="content">
                  <h2 className="divide">Psychiatric Prescribers</h2>
                  <p>Provide personalized, goal-oriented care as part of an <strong>integrated, fully collaborative care team.</strong> You have the flexibility and freedom to provide the right level of prescribing support to help your member find relief, manage their condition, and achieve their goals. </p>
                  <Link to="/providers/prescribers/" className='btn sm'>Learn More</Link>                
                </div>
              </div>
              <div className="col photo prescribers"></div>
            </div>            
        </section>
                
				<Footer/>

			</article>
    );
  }
}

export default Providers;

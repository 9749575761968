import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Analytics from "react-router-ga";
import TagManager from "react-gtm-module";

// import { Cookies } from 'react-cookie';
// import ReactResizeDetector from 'react-resize-detector';

import Home from "./Pages/Home";

import Providers from "./Pages/Providers";
import BehavioralSpecialists from "./Pages/BehavioralSpecialists";
import Prescribers from "./Pages/Prescribers";

//import HealthSystems from './Pages/HealthSystems';
import PrimaryCare from "./Pages/PrimaryCare";
import HospitalSystems from "./Pages/HospitalSystems";

import HealthPlans from "./Pages/HealthPlans";
// import Individuals from './Pages/Individuals';
import Careers from "./Pages/Careers";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import News from "./News/News";
import Post from "./News/Post";
import Privacy from "./Pages/Privacy";
import HipaaPrivacy from "./Pages/HipaaPrivacy";
import EndUserAgreement from "./Pages/EndUserAgreement";
import Consent from "./Pages/Consent";

import CDPHP from "./LandingPages/Cdphp";
// import Firefighters from './LandingPages/Firefighters';
// import PCP from './LandingPages/Pcp';
import HCP from "./LandingPages/Hcp";
import Register from "./LandingPages/Default";
import Video from "./LandingPages/Video";
import Event from "./LandingPages/Event";
// import AptiHour from './LandingPages/Aptihour';
import Employees from "./LandingPages/Employees";
import Partners from "./LandingPages/Partners";
import Mvp from "./LandingPages/Mvp";
import Impact from "./LandingPages/Impact";
import Demo from "./Pages/Demo";

class Routes extends Component {
  state = {
    isTop: true,
    lockButton: false,
  };

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll);

    const tagManagerArgs = {
      gtmId: "GTM-TZ2MJFG",
    };

    TagManager.initialize(tagManagerArgs);
  }

  handleScroll = () => {
    const isTop = window.scrollY < 50;
    if (isTop !== this.state.isTop) {
      this.setState({ isTop });
    }

    var getStarted = document.getElementById("getStartedButton");
    var introduction = document.getElementById("introduction");
    if (getStarted) {
      const difference = introduction.offsetTop + getStarted.offsetTop;
      const lockButton = window.scrollY > difference;

      if (lockButton !== this.state.lockButton) {
        this.setState({ lockButton });
      }
    }
  };

  render() {
    const isSafari =
      /Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor);
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    let url = window.location.href;
    let arr = url.split("/");
    let result = arr[2];
    let aptiGA = "UA-141035648-1";
    let aptiGA2 = "UA-141035648-5";

    if (
      result === "localhost:3000" ||
      result === "localhost:3001" ||
      result === "localhost:3002" ||
      result === "apti.ruggedpeak.co/"
    ) {
      aptiGA = null;
      aptiGA2 = null;
    }

    return (
      <div
        className={
          (isSafari ? "safari " : "") +
          (this.state.isTop ? "" : "scroll ") +
          (isIE11 ? "ie " : "") +
          (this.state.lockButton ? "lockButton" : "")
        }
      >
        <Analytics id={aptiGA} debug>
          <Analytics id={aptiGA2} debug>
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => (
                  <Home {...props} checkURLParams={this.checkURLParams} />
                )}
              />

              {/* Providers */}
              <Route
                path="/providers/"
                exact
                render={(props) => (
                  <Providers {...props} checkURLParams={this.checkURLParams} />
                )}
              />
              <Route
                path="/providers/behavioral-specialists/"
                exact
                render={(props) => (
                  <BehavioralSpecialists
                    {...props}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />
              <Route
                path="/providers/prescribers/"
                exact
                render={(props) => (
                  <Prescribers
                    {...props}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />

              {/* Health Systems */}
              <Route
                path="/health-systems/"
                exact
                render={(props) => (
                  <HospitalSystems
                    {...props}
                    checkURLParams={this.checkURLParams}
                    alert={false}
                  />
                )}
              />
              <Route
                path="/health-systems/primary-care/"
                exact
                render={(props) => (
                  <PrimaryCare
                    {...props}
                    checkURLParams={this.checkURLParams}
                    alert={false}
                  />
                )}
              />

              {/* Remaining Pages */}
              <Route
                path="/health-plans/"
                exact
                render={(props) => (
                  <HealthPlans
                    {...props}
                    checkURLParams={this.checkURLParams}
                    alert={false}
                  />
                )}
              />
              <Route
                path="/about/"
                exact
                render={(props) => (
                  <About {...props} checkURLParams={this.checkURLParams} />
                )}
              />
              <Route
                path="/careers/"
                exact
                render={(props) => (
                  <Careers {...props} checkURLParams={this.checkURLParams} />
                )}
              />
              <Route
                path="/privacy/"
                exact
                render={(props) => (
                  <Privacy {...props} checkURLParams={this.checkURLParams} />
                )}
              />
              <Route
                path="/hipaaprivacy/"
                exact
                render={(props) => (
                  <HipaaPrivacy
                    {...props}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />
              <Route
                path="/consent/"
                exact
                render={(props) => (
                  <Consent {...props} checkURLParams={this.checkURLParams} />
                )}
              />

              <Route
                path="/terms-and-conditions/"
                exact
                render={(props) => (
                  <EndUserAgreement
                    {...props}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />
              <Route
                path="/contact/"
                exact
                render={(props) => (
                  <Contact {...props} checkURLParams={this.checkURLParams} />
                )}
              />
              <Route
                path="/Our-Impact/"
                exact
                render={(props) => (
                  <Impact {...props} checkURLParams={this.checkURLParams} />
                )}
              />
              <Route
                path="/news/"
                exact
                render={(props) => (
                  <News {...props} checkURLParams={this.checkURLParams} />
                )}
              />
              <Route
                path="/news/case-study/"
                exact
                render={(props) => (
                  <News
                    {...props}
                    modal={true}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />
              <Route
                path="/news/:article/"
                exact
                render={(props) => (
                  <Post {...props} checkURLParams={this.checkURLParams} />
                )}
              />

              {/* Modals */}
              <Route
                path="/health-plans/join/"
                render={(props) => (
                  <HealthPlans
                    {...props}
                    modal={true}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />
              <Route
                path="/health-systems/primary-care/integrate/"
                render={(props) => (
                  <PrimaryCare
                    {...props}
                    modal={true}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />
              <Route
                path="/health-systems/join/"
                render={(props) => (
                  <HospitalSystems
                    {...props}
                    modal={true}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />
              <Route
                path="/health-systems/find/"
                render={(props) => (
                  <HospitalSystems
                    {...props}
                    modal={true}
                    find={true}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />
              <Route
                path="/providers/behavioral-specialists/start/"
                exact
                render={(props) => (
                  <BehavioralSpecialists
                    {...props}
                    modal={true}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />
              <Route
                path="/providers/prescribers/start/"
                exact
                render={(props) => (
                  <Prescribers
                    {...props}
                    modal={true}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />

              {/* Case Studies */}
              <Route
                path="/case-study"
                render={(props) => (
                  <Home
                    {...props}
                    modal={true}
                    cs={true}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />
              <Route
                path="/health-plans/case-study/"
                render={(props) => (
                  <HealthPlans
                    {...props}
                    modal={true}
                    cs={true}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />
              <Route
                path="/health-systems/case-study/"
                render={(props) => (
                  <HospitalSystems
                    {...props}
                    modal={true}
                    cs={true}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />
              <Route
                path="/health-systems/primary-care/case-study"
                render={(props) => (
                  <PrimaryCare
                    {...props}
                    modal={true}
                    cs={true}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />

              {/* Landing Pages */}
              <Route
                path="/welcome/teen/"
                exact
                render={(props) => (
                  <Redirect to={{ pathname: "/welcome/child/" }} />
                )}
              />
              <Route
                path="/welcome/"
                render={(props) => (
                  <CDPHP
                    {...props}
                    checkURLParams={this.checkURLParams}
                    page="welcome"
                    pageTitle="Individuals Registration"
                  />
                )}
              />
              <Route
                path="/mhm/"
                render={(props) => (
                  <CDPHP
                    {...props}
                    checkURLParams={this.checkURLParams}
                    page="welcome"
                    pageTitle="Individuals Registration"
                  />
                )}
              />
              <Route
                path="/mhm"
                render={(props) => (
                  <CDPHP
                    {...props}
                    checkURLParams={this.checkURLParams}
                    page="welcome"
                    pageTitle="Individuals Registration"
                  />
                )}
              />
              <Route
                path="/amc/"
                render={(props) => (
                  <CDPHP
                    {...props}
                    checkURLParams={this.checkURLParams}
                    pageTitle="AMC Registration"
                  />
                )}
              />
              <Route
                path="/a5/"
                render={(props) => (
                  <CDPHP
                    {...props}
                    checkURLParams={this.checkURLParams}
                    pageTitle="A5 Registration"
                  />
                )}
              />
              <Route
                path="/cdphp/"
                render={(props) => (
                  <CDPHP
                    {...props}
                    checkURLParams={this.checkURLParams}
                    page="cdphp"
                    pageTitle="CDPHP"
                  />
                )}
              />
              <Route
                path="/mvp/"
                render={(props) => (
                  <Mvp
                    {...props}
                    checkURLParams={this.checkURLParams}
                    page="mvp"
                    pageTitle="MVP"
                  />
                )}
              />
              <Route
                path="/affu/"
                render={(props) => (
                  <CDPHP
                    {...props}
                    page="affu"
                    checkURLParams={this.checkURLParams}
                    pageTitle="Albany Fire Department"
                  />
                )}
              />
              <Route
                path="/nyspffa/"
                render={(props) => (
                  <CDPHP
                    {...props}
                    page="nyspffa"
                    checkURLParams={this.checkURLParams}
                    pageTitle="New York State Professional Fire Fighters Association"
                  />
                )}
              />
              <Route
                path="/fidelis/"
                render={(props) => (
                  <CDPHP
                    {...props}
                    checkURLParams={this.checkURLParams}
                    page="fidelis"
                    pageTitle="Fidelis Care"
                  />
                )}
              />
              <Route
                path="/fourwinds/"
                render={(props) => (
                  <CDPHP
                    {...props}
                    checkURLParams={this.checkURLParams}
                    page="fourwinds"
                    pageTitle="Four Winds"
                  />
                )}
              />
              <Route
                path="/referpatient/"
                exact
                render={(props) => (
                  <HCP {...props} checkURLParams={this.checkURLParams} />
                )}
              />
              <Route
                path="/referpatient/case-study/"
                render={(props) => (
                  <HCP
                    {...props}
                    modal={true}
                    cs={true}
                    checkURLParams={this.checkURLParams}
                  />
                )}
              />
              <Route
                path="/albanymed/"
                render={(props) => (
                  <Register
                    {...props}
                    checkURLParams={this.checkURLParams}
                    page="albanymed"
                    pageTitle="Albany Med"
                  />
                )}
              />
              <Route
                path="/albanymedteam/"
                render={(props) => (
                  <Employees
                    {...props}
                    checkURLParams={this.checkURLParams}
                    page="albanymedteam"
                    pageTitle="Albany Med Team"
                    partnerName="Albany Med"
                  />
                )}
              />
              <Route
                path="/saratogahospitalteam/"
                render={(props) => (
                  <Employees
                    {...props}
                    checkURLParams={this.checkURLParams}
                    page="saratogahospitalteam"
                    pageTitle="Saratoga Hospital Team"
                    partnerName="Saratoga Hospital"
                  />
                )}
              />
              <Route
                path="/sphp/"
                render={(props) => (
                  <CDPHP
                    {...props}
                    checkURLParams={this.checkURLParams}
                    page="sphp"
                    pageTitle="St. Peter&rsquo;s Health Partners"
                  />
                )}
              />
              <Route
                path="/sphpemployees/"
                render={(props) => (
                  <Employees
                    {...props}
                    checkURLParams={this.checkURLParams}
                    page="sphp"
                    pageTitle="St. Peter&rsquo;s Health Partners"
                  />
                )}
              />
              <Route
                path="/rpiteam/"
                render={(props) => (
                  <Partners
                    {...props}
                    checkURLParams={this.checkURLParams}
                    page="rpi"
                    pageTitle="RPI"
                    partnerName="Rensselaer"
                  />
                )}
              />

              <Route
                path="/platform-demo"
                exact
                render={(props) => (
                  <Demo
                    {...props}
                    checkURLParams={this.checkURLParams}
                    alert={false}
                  />
                )}
              />

              <Route
                path="/webinar/"
                render={(props) => (
                  <Event {...props} checkURLParams={this.checkURLParams} />
                )}
              />
              {/* <Route path="/pcp/" render={(props) => <PCP {...props} checkURLParams={this.checkURLParams} />}/>
  					<Route path="/pcp/schedule" render={(props) => <PCP {...props} modal={true} checkURLParams={this.checkURLParams} />}/>
  					<Route path="/aptihour/" exact render={(props) => <AptiHour {...props} checkURLParams={this.checkURLParams} />}/>
  					<Route path="/aptihour/register" render={(props) => <AptiHour {...props} modal={true} checkURLParams={this.checkURLParams} />}/>
						<Route path="/aptihour/case-study" render={(props) => <AptiHour {...props} modal={true} cs={true} checkURLParams={this.checkURLParams} />}/> */}

              {/* Video Landing Pages */}
              <Route
                path="/founders"
                render={(props) => (
                  <Video
                    {...props}
                    checkURLParams={this.checkURLParams}
                    videoID="lclzza9ohr"
                    pageTitle="Empowering Better Mental Health: aptihealth Member Experience"
                  />
                )}
              />
              <Route
                path="/hannah"
                render={(props) => (
                  <Video
                    {...props}
                    checkURLParams={this.checkURLParams}
                    videoID="ezxn0imyu9"
                    pageTitle="Hannah&rsquo;s Inspiring Journey"
                  />
                )}
              />
              <Route
                path="/1785/"
                render={(props) => (
                  <Video
                    {...props}
                    checkURLParams={this.checkURLParams}
                    videoID="b6xczr0z5v"
                    pageTitle="1785"
                    pageHeading="Your Fast Track to Better Mental Health"
                  />
                )}
              />

              {/* PDFs */}
              <Route
                path="/virtual-care-efficacy"
                component={() => {
                  window.location.href =
                    "/pdf/The-Efficacy-of-Virtual-Care-for-Patients-with-High-Behavioral-Health-Needs-A-Comprehensive-Analysis-White-Paper-September-2024.pdf";
                  return null;
                }}
              />
              <Route
                path="/comprehensive-SUD-treatment"
                component={() => {
                  window.location.href =
                    "/pdf/aptihealth-comprehensive-SUD-treatment.pdf";
                  return null;
                }}
              />

              {/* Redirects */}
              <Route
                path="/providers/primary-care/"
                exact
                render={(props) => (
                  <Redirect
                    to={{ pathname: "/health-systems/primary-care/" }}
                  />
                )}
              />
              <Route
                path="/providers/hospital-systems/"
                exact
                render={(props) => (
                  <Redirect
                    to={{ pathname: "/health-systems/hospital-systems/" }}
                  />
                )}
              />
              <Route
                path="/individuals/"
                exact
                render={(props) => <Redirect to={{ pathname: "/welcome/" }} />}
              />
              <Route
                path="/investor/"
                component={() => {
                  window.location.href =
                    "http://aptihealth-6812895.hs-sites.com/investor";
                  return null;
                }}
              />
              <Route
                path="/investor"
                component={() => {
                  window.location.href =
                    "http://aptihealth-6812895.hs-sites.com/investor";
                  return null;
                }}
              />
              <Route
                path="/aptiparty"
                component={() => {
                  window.location.href =
                    "https://aptihealth-6812895.hs-sites.com/en/aptihealth-customer-appreciation-night-rsvp";
                  return null;
                }}
              />
              <Route
                path="/RSVP"
                component={() => {
                  window.location.href =
                    "https://aptihealth-6812895.hs-sites.com/en/aptihealth-customer-appreciation-night-rsvp";
                  return null;
                }}
              />

              <Route render={(props) => <Redirect to={{ pathname: "/" }} />} />

              {/* 
            <Route path='/AFFU' component={() => { window.location.href = 'https://aptihealth-6812895.hs-sites.com/affu'; return null;}} />
    			  <Route path="/individuals/" exact render={(props) => <Individuals {...props} checkURLParams={this.checkURLParams}/>}/>  			  
  					<Route path="/individuals/connect/" render={(props) => <Individuals {...props} modal={true} checkURLParams={this.checkURLParams} />}/>              
            <Route path="/terms/" exact render={(props) => <SinglePage {...props} checkURLParams={this.checkURLParams}/>}/>  			  
    			  <Route path="/enterprises/" exact render={(props) => <Enterprise {...props} checkURLParams={this.checkURLParams}/>}/>
    			  <Route path="/enterprises/employers/" exact render={(props) => <Employers {...props} checkURLParams={this.checkURLParams}/>}/>
    			  <Route path="/enterprises/colleges-universities/" exact render={(props) => <Colleges {...props} checkURLParams={this.checkURLParams}/>}/> 
            <Route path="/enterprises/employers/join/" exact render={(props) => <Employers {...props} modal={true} checkURLParams={this.checkURLParams}/>}/>  			  
            <Route path="/enterprises/colleges-universities/integrate/" exact render={(props) => <Colleges {...props} modal={true} checkURLParams={this.checkURLParams}/>}/>  			  
    			  */}
            </Switch>
          </Analytics>
        </Analytics>
      </div>
    );
  }
}

export default Routes;

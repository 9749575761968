import React, { Component, useEffect } from 'react';
import aptiLogo from'../../assets/Apti_Logo.svg';
import { NavLink, withRouter } from 'react-router-dom';

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

class Header extends Component {
	state = {
		openMenu: false,
	}
	
	openMenuHandler = () => {
  	if (this.state.openMenu === true){
		  this.setState({openMenu:false});
  	} else {
		  this.setState({openMenu:true});
  	}
	}
		
  render() {
    
    const openTheMenu = this.state.openMenu ? 'openMenu' : '';
    

    return (
			<header className={openTheMenu}>
        <ScrollToTopOnMount />
				
				<div className="wrap">
          <a href="/" className="logo">
            <span><img src={aptiLogo} alt="aptihealth"/></span>
            <strong>Transforming<br/> Behavioral Healthcare</strong>
          </a>	
          <button id="menu" onClick={this.openMenuHandler}><span></span></button>
          <a href='https://home.aptihealth.com/auth/sign-in' target="_blank" id="login" className="btn mobile"><span>Log In</span></a>                                                           
          	          
          <nav>				
            <ul>
              <li className="mobile"><NavLink exact to='/' activeClassName="active">Home</NavLink></li>            
              <li><NavLink to='/health-plans/' activeClassName="active">Health Plans</NavLink></li>            
              <li>
                <NavLink to='/health-systems/' activeClassName="active">Health Systems</NavLink>
                <ul className='sm one'>
                  <li><NavLink to='/health-systems/primary-care/' activeClassName="active">Primary Care</NavLink></li>
                </ul>
              </li>
              <li>
                <NavLink to='/providers/' activeClassName="active">Providers</NavLink>
                <ul>
                  <li><NavLink to='/providers/behavioral-specialists/'>Behavioral Specialists</NavLink></li>                
                  <li><NavLink to='/providers/prescribers/'>Prescribers</NavLink></li>                
                </ul>
              </li>
              <li><NavLink to='/careers/' activeClassName="active">Careers</NavLink></li>
              <li className='mobile'><NavLink to='/about/' activeClassName="active">About Us</NavLink></li>            
              <li className='mobile'><NavLink to='/contact/' activeClassName="active">Contact</NavLink></li>            
              <li className='btnNav'>
                <span>
                  <strong>Get Care</strong>
                  <ul>
                    <li><NavLink to='/welcome/' activeClassName="active">For Me</NavLink></li>                
                    <li><NavLink to='/welcome/child/' activeClassName="active">For My Child</NavLink></li>                
                    <li><NavLink to='/referpatient/' activeClassName="active">For My Patient</NavLink></li>                                
                  </ul>
                </span>
              </li>                                                                            
              <li className='login pay'><a href='https://www.medentlink.com/openedge/guestpay/index.php?practice_id=AE5sjG3S&auth_key=15147750-8015-11eb-9439-0242ac130002' target="_blank"><span>Pay Bill</span></a></li>                                                              
              <li className='login'><a href='https://home.aptihealth.com/auth/sign-in' target="_blank"><span>Log In</span></a></li>                                                              
            </ul>
          </nav>
				</div>
				
			</header>
		)
	}
}
export default withRouter(Header);
import React from "react";

import onlineAssessment from "../../assets/svg/icon-lp-online-assessment.svg";
import personalizedCare from "../../assets/svg/icon-lp-personalized-care.svg";
import therapy from "../../assets/svg/icon-lp-therapy.svg";
import medicationManagement from "../../assets/svg/icon-lp-medication.svg";
import directMessaging from "../../assets/svg/icon-lp-direct-messaging.svg";
import twentyFourSeven from "../../assets/svg/icon-lp-twenty-four-seven.svg";
import CTA from "../CTA/CTA";

export default function Steps(props) {
  const headline = (
    <p>
      With aptihealth, you and your loved ones are{" "}
      <strong>quickly matched to a collaborative care team</strong> that
      provides confidential, goal-oriented, online mental health therapy,
      substance use therapy, and care management.* You&rsquo;ll{" "}
      <strong>
        receive support from a licensed therapist and psychiatric prescriber
      </strong>{" "}
      (if needed) who are dedicated to your emotional health and wellness.
      Receive your initial clinical consultation within 48 hours and have your
      first therapy session within 30 days of your clinical consultation. The
      frequency of your therapy, medication management, and care plan are
      adapted to your needs so you feel better faster.
    </p>
  );

  const cdphphead =
    props.partner === "cdphp" ? (
      <p>
        aptihealth is available to CDPHP members who are 5 years of age and
        older, a New York State resident, and possess an email address and
        video-enabled smartphone or&nbsp;computer.
      </p>
    ) : null;

  const callout =
    props.partner === "cdphp" ? (
      <p className="disclaimer left">
        <em>
          *Some employers who self-insure do not have access to care management.
          If you are unsure if your plan includes this benefit, please contact
          CDPHP member services. Copays may apply for these services.
        </em>
      </p>
    ) : null;
  return (
    <section className="p30">
      <div className="wrap">
        <h2 className="subhead text-center">Comprehensive Care and Support</h2>
        {headline}
        {cdphphead}
        <div className="three-col steps">
          <div className="col">
            <img src={onlineAssessment} alt="Online Assessment" />
            <h3>Online Assessment</h3>
            <p>
              Your assessment will help your therapist better understand your
              needs and how they can help&nbsp;you.
            </p>
          </div>
          <div className="col">
            <img src={personalizedCare} alt="Personalized Care Plan" />
            <h3>Personalized Care Plan</h3>
            <p>
              Created by you and your therapist, your personalized care plan
              helps you set, stay on track, and quickly achieve your goals for
              better mental&nbsp;health.
            </p>
          </div>
          <div className="col therapy">
            <img src={therapy} alt="Therapy" />
            <h3>Therapy</h3>
            <p>
              Weekly online video sessions with your therapist where and when
              it&rsquo;s convenient&nbsp;for&nbsp;you.
            </p>
          </div>
          <div className="col">
            <img src={medicationManagement} alt="Medication Management" />
            <h3>Medication Management</h3>
            <p>
              If recommended, a prescriber is added to your care team and you
              meet every two weeks via video session. Your therapist and
              prescriber work together to support&nbsp;you.
            </p>
          </div>
          <div className="col">
            <img src={directMessaging} alt="Direct Messaging" />
            <h3>Direct Messaging</h3>
            <p>
              For support between sessions, you can communicate with your
              therapist through direct messaging on aptihealth’s
              secure&nbsp;platform.
            </p>
          </div>
          <div className="col">
            <img src={twentyFourSeven} alt="24/7 Support" />
            <h3>24/7 Support</h3>
            <p>
              We offer 24/7 support services through our dedicated helpline.
              Anywhere, anytime, we’re here for&nbsp;you.
            </p>
          </div>
        </div>
        {callout}
      </div>
    </section>
  );
}

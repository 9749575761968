import React from 'react';
// import aptiLogo from'../../assets/logo.png';
import aptiLogo from'../../assets/Apti_Logo.svg';
import { Link } from 'react-router-dom';

const footer = (props) => {
	return (
		<footer>
		  <div className="wrap">
        
        <div className="links">
          <ul className="policies">
            <li><Link to='/privacy/'>Privacy Policy</Link></li>
            <li><Link to='/hipaaprivacy/'>HIPAA Privacy Policy</Link></li>          
            <li><Link to='/terms-and-conditions/'>Terms and Conditions of Use</Link></li>
            <li><Link to='/consent/'>Consent to Treatment</Link></li>
          </ul>

    			<ul>
            <li><Link to='/referpatient/'>Refer a Patient</Link></li>
            <li><Link to='/about/'>About Us</Link></li>
            <li><Link to='/news/'>News</Link></li>
            <li><Link to='/contact/'>Contact</Link></li>
          </ul>
          
        </div>        
        
        <img src={aptiLogo} alt="aptihealth"/>
        
        <address>        
    		  <p>268 Broadway, Suite 101 Saratoga Springs, NY 12866&nbsp;&nbsp;&bull;&nbsp;&nbsp;<span>(888) 454-3827</span></p>
          <p className="copyright">Copyright &copy; 2023 aptihealth</p>                  
  		  </address>        
        
        
			</div>
		</footer>
	)
}

export default footer;

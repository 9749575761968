import React, {Component} from 'react';
import HubspotForm from 'react-hubspot-form';

class Modal extends Component {

	closeModal = () => {
		this.props.closeModal();
	}

  render() {
		const display = (this.props.show === true ? 'show' : '');

		return (
			<section className={'modal ' + display}>
				<div id="overlayBG" onClick={this.closeModal}>
        <div id="overlay" onClick={(event)=>{event.stopPropagation()}}>
					<div className="wrap sm text-center">
            <button className="close" onClick={this.closeModal}>Close</button>
             {this.props.children}
             <HubspotForm
               portalId='6812895'
               formId={this.props.hubspot}
               onSubmit={() => console.log('Submit!')}
               onReady={(form) => console.log('Form ready!')}
               loading={<div>Loading...</div>}
               />                                          
  				</div>
  			</div>
        </div>
  		</section>

		)
	}
}

export default Modal;

import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import WistiaEmbed from '../../components/WistiaEmbed/WistiaEmbed';

import Header from '../../components/Header/ClientHeader';
import CTA from '../../components/CTA/CTA';
import Prevention from '../../components/Footer/Prevention';
import Footer from '../../components/Footer/Footer';

import appleDownload from'../../assets/apple-download.svg';
import schedule from'../../assets/svg/icon-lp-schedule.svg';
import therapy from'../../assets/svg/icon-lp-therapy.svg';
import directMessaging from'../../assets/svg/icon-lp-direct-messaging.svg';
import twentyFourSeven from'../../assets/svg/icon-lp-twenty-four-seven.svg';


class Video extends Component {   

  render() {
    const videoID =  this.props.videoID;
    const pageTitle =  this.props.pageTitle;
    const pageHeading = this.props.pageHeading ? this.props.pageHeading : this.props.pageTitle;

    let headerButtonText = 'Log In';
    let headerButtonLink = 'https://home.aptihealth.com/auth/sign-in'; 
    let headerButtonStyle = null;    
    let paddingBottom = null;
    let introCallout = null;
    let mainDescription = null;
    
    let downloadSection = (
      <div className='download'>
        <p><a href="https://home.aptihealth.com/auth/sign-in" target="_blank" className="btn lg">Log In</a></p>
        <p><strong className='or'>or</strong></p>
        <p><a href="https://apps.apple.com/us/app/aptihealth/id1477170874?l=is" target="_blank" rel="noopener noreferrer" className='app'><img src={appleDownload} alt='Download the iOS App' /></a></p>
      </div>      
    );    

    if (pageTitle === '1785') {
      
      headerButtonText = 'Get Started';
      headerButtonLink = 'https://home.aptihealth.com/auth/self-signup'; 
      headerButtonStyle = 'alt';          
      paddingBottom = 'pb30';
      
      introCallout = (
        <section id="introScreening" className='clientIntro'>  						
          <div className='wrap'>
            <CTA btnText='Get Started' btnLink='https://home.aptihealth.com/auth/self-signup' />          
            <div className='copy'>
              <h4>Receive <strong><em>personalized, goal-oriented teletherapy</em></strong> that empowers you to make positive life changes and feel better within 90&nbsp;days.</h4>
            </div>
          </div>
        </section>        
      );
      
      mainDescription = (
        <>  						
          <h3>Comprehensive Care and Support</h3>
          <p>With <strong>aptihealth</strong> you’re quickly connected with an expert care team dedicated to your emotional health and wellness. We match you with a licensed therapist based on your needs and a prescriber if medication is recommended. aptihealth’s experts are dedicated to your success—frequency of therapy, medication management, and your care plan are all personalized to help you feel better faster. aptihealth accepts health insurance, co-pays may apply.</p>
        </>
      );
      
      downloadSection = (
        <div className='download'>
          <CTA btnText='Get Started' btnLink='https://home.aptihealth.com/auth/self-signup' btnStyle='alt lg' />                  
        </div>        
      );
    }

     
    return (
      <article>
  			<Helmet>
  				<title>{pageTitle + ' | aptihealth'}</title>
  				<meta name="description" content='Your Fast Track to Better Mental Health. Receive personalized, goal-oriented teletherapy that empowers you to make positive life changes and feel better within 90 days.' />
  			</Helmet>
        
        <Header btnText={headerButtonText} btnLink={headerButtonLink} btnStyle={headerButtonStyle} />
        
        <div className='videoContent'>
      	  <section className={paddingBottom}>
      	    <div className="wrap">
      	      <h2>{pageHeading}</h2>
              <WistiaEmbed hashedId={videoID} playerColor='#0CD0A7' />
            </div>
          </section>
          
          {introCallout}
              
      	  <section>
      	    <div className="wrap">
              <div className="description">
                {mainDescription}
                <h3>It&rsquo;s Easy to Use aptihealth</h3>
                <p>aptihealth uses secure video technology to connect you to care. Just before your appointment, log in to the <a href="https://apps.apple.com/us/app/aptihealth/id1477170874?l=is" target="_blank"  rel="noopener noreferrer">aptihealth mobile app</a> or to <a href="https://home.aptihealth.com/auth/sign-in" target="_blank">aptihealth.com</a> from your video-enabled computer, tablet or smartphone. Our web platform works best on Chrome, Firefox or Safari. It&rsquo;s easy to use aptihealth for:</p>
                 
                <div className="four-col">
                  <div className="col">
                    <img src={schedule} alt="Scheduling appointments"/>
                    <p>Scheduling appointments</p>
                  </div>
                  <div className="col therapy">
                    <img src={therapy} alt="Therapy"/>
                    <p>Online video therapy sessions</p>
                  </div>
                  <div className="col">
                    <img src={directMessaging} alt="Direct Messaging"/>
                    <p>Direct messaging your therapist</p>
                  </div>
                  <div className="col">
                    <img src={twentyFourSeven} alt="24/7 Support"/>
                    <p>24/7 support, anytime, anywhere, we&rsquo;re here for&nbsp;you</p>
                  </div>
                </div>
  
                {downloadSection}
                
              </div>
              
            </div>        
          </section>
        </div>
    	  
  			<Prevention/>
  			<Footer/>
  
      </article>
  	);
	}
}
export default Video;
import React from 'react';
import { Link } from 'react-router-dom';

export default function Alert(props) {
  return (
		<div id="alert">
			<div className="wrap">
  			<p><strong>Join us for aptihour</strong> to learn how you can put your patients on the fast track to better mental health. See how our dedicated provider network, integrated care approach, and user-friendly digital platform help your patients feel better within 90 days. <Link to='/aptihour/'>Register for free</Link>.</p>
			</div>
		</div>
	)
}
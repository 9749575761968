import React from 'react';
import albanyMedLogo from '../../assets/logo-ko-albanymed.png';
import cdphpLogo from '../../assets/svg/logo-ko-cdphp.svg';
import saratogaHospitalLogo from '../../assets/svg/logo-ko-saratogahospital.svg';
import rpilLogo from '../../assets/logo-ko-rpi.png';

export default function PartnerBanner(props) {

  const page = props.page; 
  // let hospital;
  let logo; 
  let intro = "Being a healthcare worker doesn’t prevent you from feeling tired, anxious, sad, and hopeless. When it’s time to ask for help—and you’re the one who needs care—aptihealth is here for you.";
	switch (page) {
    case 'albanymedteam':
      logo = <img src={albanyMedLogo} className="amc" alt="Albany Med"/>;
      break;
    case "saratogahospitalteam":
      logo = <img src={saratogaHospitalLogo} className="toga" alt="Saratoga Hospital"/>;
      break
    case "rpi":
      logo = <img src={rpilLogo} className="rpi" alt="Rensselaer"/>;
      intro = "Tired. Anxious. Sad. Hopeless. When it’s time to ask for help—and you or a loved one needs care—aptihealth is here for you.";
      break
    default:
      break;
  }
  
	return (
		<section id="banner" className={'partner-banner ' + page}>
			<div className="wrap">
        <div className={'partnerLogos ' + page}>
          {logo}
          <img src={cdphpLogo} className="cdphp" alt="CDPHP"/>
        </div>
        <h2>YOUR WELLNESS IS ESSENTIAL</h2>
        <h1>ARE<span>YOU</span><strong>OK?</strong></h1>
        <p>{intro}</p>
        <a href="https://home.aptihealth.com/auth/sign-in" className='btn lg'>Let’s get you feeling better.</a>
			</div>
		</section>
	)
}
import React from 'react';
// import { Link } from 'react-router-dom';

const article = (props) => {
  let articlePhoto = require(`../../assets/${props.figure}.jpg`);
  const external = props.link.indexOf('https://') > -1 ? '_blank' : null;
  const addClass = props.figure.substring(0, 5) === "news-" ? 'full' : null;

	return (
    <a href={props.link} className="article" target={external}>
      <div className="border">
        <figure className={addClass} style={{ backgroundImage: `url(${articlePhoto}`}}></figure>
        <div>            
          <h3 dangerouslySetInnerHTML={{ __html: props.title}}></h3>
          <span className="date">{props.date}</span>
          <button className="toggle"><span>Read Article</span></button>                 
        </div>
      </div>
    </a>
	)
}

export default article;

import React from 'react';

const prevention = (props) => {
	return (
    <section>
      <div className="wrap">
        <h6>If you are in a life-threatening situation, call the <strong className="highlight">National Suicide Prevention Lifeline</strong> at 988 or <span>1-800-273-8255.</span></h6>
      </div>
    </section>
	)
}

export default prevention;

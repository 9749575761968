import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import WistiaEmbed from '../../components/WistiaEmbed/WistiaEmbed';
import Cookie from "../../components/Cookie/Cookie";

import Header from '../../components/Header/ClientHeader';
import Banner from '../../components/Banner/ClientBanner';
import PartnerBanner from '../../components/Banner/PartnerBanner';
import Testimonials from '../../components/Testimonials/Testimonials';
import CTA from '../../components/CTA/CTA';
import Prevention from '../../components/Footer/Prevention';
import Footer from '../../components/Footer/Footer';

import appDemo from'../../assets/cdphp-app-demo.png';
import onlineAssessment from'../../assets/svg/icon-lp-online-assessment.svg';
import personalizedCare from'../../assets/svg/icon-lp-personalized-care.svg';
import therapy from'../../assets/svg/icon-lp-therapy.svg';
import medicationManagement from'../../assets/svg/icon-lp-medication.svg';
import directMessaging from'../../assets/svg/icon-lp-direct-messaging.svg';
import twentyFourSeven from'../../assets/svg/icon-lp-twenty-four-seven.svg';


class Employees extends Component {


  render() {
    const pageTitle = this.props.pageTitle;
    const partner = this.props.page;
    const partnerName = this.props.partnerName;
    const alertClass = this.props.alert ?  'show-alert' : null;
    
    let intro;
    let pandemic;
    let partnership;
    let disclaimer;
    let screening;
    let age;
    let banner = <Banner page={partner} />
    if (partner === 'albanymedteam' || partner === 'saratogahospitalteam' || partner === 'rpi') {
      banner = <PartnerBanner page={partner}/>
    }
    if (partner === 'sphp') {
      intro = <h4>Being an SPHP healthcare hero doesn&rsquo;t prevent you from sometimes feeling tired, irritable, sad, and hopeless—even heroes need support. If you&rsquo;re experiencing these symptoms for more than two weeks, it&rsquo;s time to ask for&nbsp;help. </h4>;
      pandemic = <p className='headline left full'>The pandemic landscape is far different from anything healthcare workers have been trained to face, adding to the burnout already common in the healthcare industry. If you&rsquo;re working on the front lines, <strong>you&rsquo;ve noticed the symptoms of all that stress—and may be experiencing them yourself</strong>.</p>;
      partnership = 'St. Peter’s Health Partners has partnered with aptihealth to provide employees';
      screening = <section id="introScreening" className='clientIntro'>  						
        <div className='wrap'>
          <CTA btnText='Get Started' btnLink='https://home.aptihealth.com/auth/self-signup' />          
          <div className='copy'>
            {intro}
          </div>
        </div>
      </section>
    } else {
      intro = <h4>It&rsquo;s okay to sometimes feel tired, irritable, sad, and hopeless—but if you&rsquo;re experiencing these symptoms for more than two weeks, it&rsquo;s time to ask for help.</h4>;
      pandemic = <p className='headline left full'>The pandemic has added significant stress to the lives of healthcare workers, increasing the burnout already common in the industry. <strong>You may be noticing the symptoms of stress in others, or be experiencing them yourself</strong>.</p>;      
      partnership = 'aptihealth has partnered with CDPHP to provide ' + partnerName + ' employees* and their families';
      disclaimer = <p><em>*aptihealth is available to all {partnerName} employees and family members ages 5+, who are NYS residents, and are enrolled in coverage through CDPHP. Copays may apply for these services. aptihealth is covered by most major health plans.</em></p>
    }
    if (partner === 'rpi'){
      pandemic = <p className='headline left full'>The pandemic has added significant stress to our lives, increasing the rate of burnout. <strong>You may be noticing the symptoms of stress in others, or be experiencing them yourself</strong>.</p>;      
    }
    // if (partner === 'albanymedteam' || partner === 'saratogahospitalteam' || partner === 'rpi') {
    //   age = " for ages 5 and up"
    // }

    return (
      <article className={alertClass}>
  			<Helmet>
  				<title>{'aptihealth ' + pageTitle}</title>
  				<meta name="description" content='Your Fast Track to Better Mental Health. Receive personalized, goal-oriented teletherapy that empowers you to make positive life changes and feel better within 90 days.' />
  			</Helmet>
        
        <Header partner={partner} btnText='Log In' btnLink='https://home.aptihealth.com/auth/sign-in' btnStyle='alt' />
        {banner}
        {screening}
        
        <section className="pt30 hcp">
          <div className="wrap">
            <h2 className='subhead'>Virtual Behavioral Care That Takes Care Of You</h2>
            {pandemic}
            <p className='headline left full'>{partnership} with <strong>fast, easy access to confidential, goal-oriented online therapy</strong>{age}. Receive support from a licensed therapist and prescriber (if needed) with a clinical consultation within 48-hours, first therapy session within five days of your clinical consultation, and be empowered to feel better within 90&nbsp;days.</p>
            <div className="three-col steps">
              <div className="col">
                <img src={onlineAssessment} alt="Online Assessment"/>
                <h3>Online Assessment</h3>
                <p>Your assessment will help your therapist better understand your needs and how they can help&nbsp;you.</p>
              </div>
              <div className="col">
                <img src={personalizedCare} alt="Personalized Care Plan"/>
                <h3>Personalized Care Plan</h3>
                <p>Created by you and your therapist, your personalized care plan helps you set, stay on track, and quickly achieve your goals for better mental&nbsp;health.</p>
              </div>
              <div className="col therapy">
                <img src={therapy} alt="Therapy"/>
                <h3>Therapy</h3>
                <p>Weekly online video sessions with your therapist where and when it&rsquo;s convenient&nbsp;for&nbsp;you.</p>
              </div>
              <div className="col">
                <img src={medicationManagement} alt="Medication Management"/>
                <h3>Medication Management</h3>
                <p>If recommended, a prescriber is added to your care team and you meet every two weeks via video session. Your therapist and prescriber work together to support&nbsp;you.</p>
              </div>
              <div className="col">
                <img src={directMessaging} alt="Direct Messaging"/>
                <h3>Direct Messaging</h3>
                <p>For support between sessions, you can communicate with your therapist through direct messaging on aptihealth’s secure&nbsp;platform.</p>
              </div>
              <div className="col">
                <img src={twentyFourSeven} alt="24/7 Support"/>
                <h3>24/7 Support</h3>
                <p>We offer 24/7 support services through our dedicated helpline. Anywhere, anytime, we’re here for&nbsp;you.</p>
              </div>
            </div>
            {disclaimer}
          </div>
        </section>

  			<section className="pt60 clientPages grey">
          <div className="wrap">
            <div className="two-col pad">
              <div className="col">
                <h2 className='subhead'>It&rsquo;s Easy to Use aptihealth</h2>
                <p>All you need is a video-enabled smartphone, tablet, or computer to access our secure portal.</p>
                <ul className="checks">
                  <li>Logging into aptihealth from your browser<br/>(Chrome, Firefox, or Safari) at <a href="http://www.aptihealth.com" target="_blank">aptihealth.com</a></li>
                  <li>Downloading our <a href="https://apps.apple.com/us/app/aptihealth/id1477170874?l=is" target="_blank" rel="noopener noreferrer">iOS</a> or <a href="https://play.google.com/store/apps/details?id=com.aptihealth.aptihealth&pli=1" target="_blank" rel="noopener noreferrer">Android</a> app</li>
                </ul>
                <CTA btnText='Get Started' btnLink='https://home.aptihealth.com/auth/self-signup' btnStyle='alt lg' />
              </div>
              <div className="col">
                <img src={appDemo} className='demoPhoto' alt="aptihealth iPhone Demo" />
              </div>
            </div>
          </div>        
        </section>
         			
  			<section className="pt60 clientPages">
          <div className="wrap">
            <div className="two-col pad">
              <div className="col">
                <h2 className='subhead'>aptihealth Member Success&nbsp;Stories</h2>
                <p>Watch Hannah’s inspiring journey to learn more about the aptihealth experience.</p>
                <blockquote>
                  <p>&ldquo;aptihealth hasn’t just improved my life, it completely changed the way I’m able to interact in the world. aptihealth helped me be able to see the beauty in the world&nbsp;again.&rdquo;</p>
                  <cite><strong>Hannah*</strong>, aptihealth Member</cite>
                  <em>*Name has been changed to protect privacy.</em>
                </blockquote>
              </div>
              <div className="col">
                <WistiaEmbed hashedId='27a7jiak5v' playerColor='#0CD0A7' />
              </div>
            </div>
          </div>        
        </section>

        <Testimonials slides="cdphp" />
        <Prevention/>
        <Footer/>
        <Cookie/>
  			
      </article>
  	);
	}
}
export default Employees;
// Imports
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

// Components
import Header from '../../components/Header/Header';
import Alert from '../../components/Header/AlertBanner';
import Banner from '../../components/Banner/Banner';
import Intro from '../../components/Intro/Intro';
import Stats from '../../components/Stats/Stats';
import Footer from '../../components/Footer/Footer';
import Modal from '../../components/Modal/Modal';

// Assets
import care from'../../assets/svg/icn-care.svg';
import access from'../../assets/svg/icn-access.svg';


class HospitalSystems extends Component {


  state = {
		showModal: false,
		showModal2: false,
		showCaseStudy: false,        
  };
  
	openModalHandler = (overlay) => {
    if(overlay === 'cs' || this.props.cs){
		  this.setState({showCaseStudy:true});
		  if(!this.props.modal){  		
  		  this.props.history.push('case-study/');		  
  		}
    } else if(overlay === '2' || this.props.find){
		  this.setState({showModal2:true});
  		if(!this.props.modal){  		
  		  this.props.history.push('find/');
  		}
    } else {
  		this.setState({showModal:true});
		  if(!this.props.modal){  		
    		this.props.history.push('join/');
      }
    }
	}

	closeModalHandler = () => {
		this.props.history.push('/health-systems/');
		this.setState({showModal:false});
		this.setState({showModal2:false});
		this.setState({showCaseStudy:false});
	}

	componentDidMount () {
		if(this.props.modal){
			this.openModalHandler();
		}
	}  
	
  render() {
    
    const alert = this.props.alert ? <Alert /> : null;
    const alertClass = this.props.alert ?  'show-alert' : null;            

    return (
      <article className={alertClass}>
				<Helmet>
					<title>Hospital Systems - Speed Access to Better, More Meaningful Care for Behavioral Health Patients</title>
					<meta name="description" content="With aptihealth incorporated into your provider care stream, your organization will benefit as patients receive improved access to care, improved quality care, and improved outcomes"/>
				</Helmet>
        
        <Header />
        {alert}
        <Banner page='page-hospitals' heading='Speed Access to Behavioral Healthcare for Patients Ages 5 and&nbsp;Up' eyebrow='Health Systems'/>

        <Intro video='zij4phw2vo' bg='grey'>
          <p>Approximately one in eight visits to emergency departments in the United States involves mental and substance use disorders, and the rate is growing.  aptihealth is here to help with the technology and services that health systems need to provide in-patient, emergency department and physician practices with fast effective access to integrated behavioral healthcare.</p>
          <p><strong>With aptihealth incorporated at each point of care, you can:</strong></p>
          <ul className="disc">
            <li>Help patients ages 5 and up get better, faster</li>
            <li>Expedite discharges and improve patient flow in ED and inpatient&nbsp;units</li>
            <li>Reduce wait times, length of stay and abandonment&nbsp;rates</li>
            <li>Easy and seamless discharge from in-patient to comprehensive out-patient care for patients living with Severe Persistent Mental Illnesses&nbsp;(SPMI)</li>
            <li>Provide physicians with comprehensive, organized behavioral health information for better&nbsp;care</li>
          </ul>
        </Intro>
        
        
        <section className="pt30 results">
          <div className="wrap">
            <div className="two-col">
              <div className="col">
                <img src={access} alt='Expedite and Expand Access'/>
                <h3>Expedite and Expand Access</h3>
                <ul className="disc">
                  <li>Immediate access to a technology enabled patient <span className="nobreak">self-assessment</span></li>
                  <li>Expedited access to a consultation with a licensed&nbsp;therapist</li>
                  <li>Network of licensed behavioral health specialists, ready to take on patients&nbsp;immediately</li>
                  <li>Comprehensive physician summary and behavioral assessment reporting for integrated&nbsp;care </li>
                  <li>Personalized, goal-oriented care plans for patients ages 5 and&nbsp;up</li>
                </ul>
              </div>
            
              <div className="col">
                <img src={care} alt='Improved Care and Satisfaction'/>
                <h3>Improved Care and Satisfaction</h3>
                <ul className="disc">
                  <li>Easy to use, intuitive telehealth engagement platform</li>
                  <li>Structured protocol and evidence-based behavioral health treatment programs based upon each member&rsquo;s acuity</li>
                  <li>90-day, focused, short-term care, tailored to each individual patient&rsquo;s needs and goals based upon each member&rsquo;s acuity</li>
                  <li>Extended care continuity for patients that require ongoing treatment</li>
                  <li>24/7 support from our dedicated team of licensed specialists</li>
                </ul>
              </div>
            </div>
            
          </div>
        </section>  
        
        <Stats 
          heading="Driving Breakthrough Results" 
          background="grey"
          openModalHandler={this.openModalHandler} 
        />		
        
        <section className="p30 actions">
          <div className="wrap">
            <h3>With You Every Step Of The Way</h3>
            <p>
              To ensure similar results for your organization, aptihealth provides a <strong>customer success manager</strong> to support you every step of the way. Look to aptihealth for the behavioral health resources and greater efficiency your organization needs to contain costs and deliver superior patient care.  <Link className="link" onClick={()=>this.openModalHandler('2')}>Find out more today</Link>
            </p>          
          </div>        
        </section>        
        

        <section id="cta" className="p30 dark">
          <div className="wrap sm">          
            <h3>Interested in how aptihealth provides fast, virtual-first access to behavioral healthcare for children, teens, and adults? <strong>Click Learn More and we&rsquo;ll be in&nbsp;touch</strong>.</h3>
            <Link className="btn" to='/contact'>Learn More</Link>
            {/* <Link className="btn" onClick={this.openModalHandler}>Learn More</Link> */}
          </div>        
        </section>   
        
        <Modal 
          closeModal={this.closeModalHandler} 
          show={this.state.showModal} 
          hubspot='aa07c68d-62fc-4e3a-986c-75d4538201f1'
        >
          <h3>Learn More About aptihealth.</h3>
        </Modal> 
        
        <Modal 
          closeModal={this.closeModalHandler} 
          show={this.state.showModal2} 
          hubspot='aa07c68d-62fc-4e3a-986c-75d4538201f1'
        >
          <h3>Find out more today!</h3>
        </Modal>                    

        <Modal 
          closeModal={this.closeModalHandler} 
          show={this.state.showCaseStudy} 
          hubspot='ad3fffe4-0a99-4277-9817-2d55c9d7fa25'
        >
          <h3>Sign Up for the Case Study!</h3>
        </Modal>                    

                        
				<Footer/>

			</article>
    );
  }
}

export default HospitalSystems;

// Imports
import React, { Component } from 'react';
import {Helmet} from "react-helmet";
// import { Link } from 'react-router-dom';
// import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';


// Components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';


class HipaaPrivacy extends Component {

  render() {

    return (
      <article id="single">
				<Helmet>
					<title></title>
					<meta name="description" content=""/>
				</Helmet>
        
        <Header />
          
        <section className="single privacy">
          <div className="wrap">
            <h1>HIPAA Notice of Privacy Practices</h1>
            <p><strong>EFFECTIVE DATE: MAY 5, 2022</strong></p>
<p>This HIPAA Notice of Privacy Practices (the &ldquo;HIPAA Notice&rdquo;) describes how AptiHealth Medical, PLLC (&ldquo;AptiHealth Medical&rdquo;) AptiHealth, Inc. (&ldquo;AptiHealth&rdquo;) and OCHA Members (collectively, &ldquo;we,&rdquo; &ldquo;our,&rdquo; or &ldquo;us&rdquo;) may use and disclose your &ldquo;Protected Health Information&rdquo; to carry out treatment, payment or healthcare operations and for other purposes that are permitted or required by law.  We provide each of our members with this information and ask each of our members to acknowledge receipt of our HIPAA Notice.  Please note that by registering on the website (the &ldquo;Site&rdquo;) on your behalf or on behalf of a minor, or by using the services provided by AptiHealth Medical, you accept the practices described in this HIPAA Notice.  If you do not agree to this HIPAA Notice, please do not use the Site or AptiHealth’s services. IF YOU ARE UNDER 12 YEARS OF AGE OR RESIDE OUTSIDE OF THE UNITED STATES, PLEASE DO NOT USE OUR SITE.  </p>
<p>&ldquo;Protected health information&rdquo; or &ldquo;PHI&rdquo; is information about you, including demographic information, that may identify you and that relates to your past, present or future physical health or condition, treatment or payment for health care services.  This HIPAA Notice also describes your rights to access and control your protected health information. </p>
<h2>Your Rights</h2>
<p>When it comes to your health information, you have certain rights. This section explains your rights and some of our responsibilities to help you.</p>

<p><strong>Get an electronic or paper copy of your medical record</strong></p>
<ul className="disc">
<li>You can ask to see or get an electronic or paper copy of your medical record and other health information we have about you. Ask us how to do&nbsp;this.</li>
<li>We will provide a copy or a summary of your health information, usually within 30 days of your request. We may charge a reasonable, cost‐based fee.</li>
</ul>
<p><strong>Ask us to correct your medical record</strong></p>
<ul className="disc">
<li>You can ask us to correct health information about you that you think is incorrect or incomplete. Ask us how to do this.</li>
<li>We may say &ldquo;no&rdquo; to your request, but we&rsquo;ll tell you why in writing within 60 days.</li>
</ul>

<p><strong>Request confidential communications</strong></p>
<ul className="disc">
<li>You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a different&nbsp;address.</li>
<li>We will say &ldquo;yes&rdquo; to all reasonable requests.</li>
</ul>

<p><strong>Ask us to limit what we use or share</strong></p>
<ul className="disc">
<li>You can ask us not to use or share certain health information for treatment, payment, or our operations. We are not required to agree to your request, and we may say &ldquo;no&rdquo; if it would affect your care.</li>
<li>If you pay for a service or healthcare item out‐of‐pocket in full, you can ask us not to share that information for the purpose of payment or our operations with your health insurer. We will say &ldquo;yes&rdquo; unless a law requires us to share that information.</li>
</ul>

<p><strong>Get a list of those with whom we&rsquo;ve shared information</strong></p>
<ul className="disc">
<li>You can ask for a list (accounting) of the times we&rsquo;ve shared your health information for six years prior to the date you ask, who we shared it with, and why.</li>
<li>We will include all the disclosures except for those about treatment, payment, and healthcare operations, and certain other disclosures (such as any you asked us to make). We&rsquo;ll provide one accounting a year for free but will charge a reasonable, cost‐based fee if you ask for another one within 12 months.</li>
</ul>

<p><strong>Get a copy of this HIPAA Notice.</strong></p>
<p>You can ask for a paper copy of this HIPAA Notice at any time, even if you have agreed to receive the HIPAA Notice electronically. We will provide you with a paper copy promptly.</p>

<p><strong>Choose someone to act for you</strong></p>
<ul className="disc">
  <li>If you have given someone medical power of attorney or if someone is your legal guardian, that person can exercise your rights and make choices about your health information.</li>
  <li>We will make sure the person has this authority and can act for you before we take any action.</li>
</ul>

<p><strong>File a complaint if you feel your rights are violated</strong></p>
<ul className="disc">
  <li>
    <p>You can complain if you feel we have violated your rights by contacting us:</p>
    <p>
    AptiHealth, Inc. <br/>
    Attn:  Privacy Officer<br/>    
    340 Broadway, Suite 7<br/>
    Saratoga Springs, NY 12866<br/>
    (888) 454-3827 <br/>
    <a href="mailto:privacy@aptihealth.com">privacy@aptihealth.com</a>
    </p>
  </li>
  <li>You can file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1‐877‐696‐6775, or visiting www.hhs.gov/ocr/privacy/hipaa/complaints/.</li>
  <li>We will not retaliate against you for filing a complaint.</li>
</ul>


<h2>Your Choices</h2>
<p>For certain health information, you can tell us your choices about what we share. If you have a clear preference for how we share your information in the situations described below, talk to us. Tell us what you want us to do, and we will follow your instructions.</p>

<p><strong>In these cases, you have both the right and choice to tell us to:</strong></p>

<ul className="disc">
<li>Share information with your family, close friends, or others involved in your care</li>

<li>Share information in a disaster relief situation</li>

<li>Include your information in a hospital directory</li>
</ul>

<p><em>If you are not able to tell us your preference, for example if you are unconscious, we may go ahead and share your information if we believe it is in your best interest.  We may also share your information when needed to lessen a serious and imminent threat to health or safety. </em></p>

<p><strong>In these cases, we never share your information unless you give us written permission:</strong></p>

<ul className="disc">
<li>Marketing purposes</li>

<li>Sale of your information</li>

<li>Psychotherapy notes, unless otherwise required by law</li>
</ul>

<p><strong>In the case of fundraising:</strong></p>
<ul className="disc">
  <li>We may contact you for fundraising efforts, but you can &ldquo;opt out&rdquo; or &ldquo;unsubscribe&rdquo; and we will not contact you again with this type of communication.</li>
</ul>

<h2>Our Uses and Disclosures</h2>
<p><strong>How do we typically use or share your health information? </strong><br/>
We typically use or share your health information in the following ways.</p>

<p><strong>Treat you</strong><br/>
We can use your health information and share it with other professionals who are treating you.<br/>
<em>Example: A doctor treating you for a health condition asks us about your behavioral health conditions that may affect treatment. </em></p>

<p><strong>Run our organization</strong><br/>
We can use and share your health information to run our practice, improve your care, and contact you when necessary. <br/>
<em>Example: We use health information about you to manage your treatment and services.</em></p>

<p><strong>Bill for your services</strong><br/>
We can use and share your health information to bill and get payment from health plans or other entities.<br/>
<em>Example: We give information about you to your health insurance plan so it will pay for your services.</em></p>

<p><strong>How else can we use or share your health information? </strong><br/>
We are allowed or required to share your information in other ways – usually in ways that contribute to the public good, such as public health and research. We must meet many conditions in the law before we can share your information for these purposes. For more information see: <a href="https://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html" target="_blank" rel="noopener noreferrer">www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html</a>.</p>

<p><strong>Help with public health and safety issues</strong><br/>
We can share health information about you for certain situations such as:</p>
<ul className="disc">
<li>Preventing disease</li>
<li>Helping with product recalls</li>
<li>Reporting adverse reactions to medications</li>
<li>Reporting suspected child abuse or neglect, and under certain circumstances, abuse, neglect, or domestic violence involving adults</li>
<li>Preventing or reducing a serious threat to anyone&rsquo;s health or safety<br/><br/></li>
</ul>

<p><strong>Do research</strong><br/>
We can use your information for health research, in aggregate (your personal information will not be separately identifiable).</p>

<p><strong>Comply with the law</strong><br/>
We will share information about you if state or federal laws require it, including with the Department of Health and Human Services if it wants to see that we&rsquo;re complying with federal privacy law.</p>

<p><strong>Work with a medical examiner or funeral director</strong><br/>
We can share health information with a coroner, medical examiner, or funeral director when an individual dies.</p>

<p><strong>Address workers&rsquo; compensation, law enforcement, and other government requests</strong><br/>
We can use or share health information about you:</p>

<ul className="disc">
<li>For workers&rsquo; compensation claims</li>
<li>For law enforcement purposes or with a law enforcement official</li>
<li>With health oversight agencies for activities authorized by law</li>
<li>For special government functions such as military, national security, and presidential protective services<br/><br/></li>
</ul>

<p><strong>Respond to lawsuits and legal actions</strong><br/>
We can share health information about you in response to a court or administrative order, or in response to a subpoena.</p>

<p><strong>Our Responsibilities</strong></p>

<ul className="disc">
<li>We are required by law to maintain the privacy and security of your protected health information.</li>
<li>We will notify you if a reportable breach of your unsecured protected health information is discovered.  Notification will be made to you no later than 60 days from the breach discovery and will include a brief description of how the breach occurred, the protected health information involved and contact information for you to ask questions.</li>
<li>We must follow the duties and privacy practices described in this Notice and give you a copy of it.</li>
<li>We will not use or share your protected health information other than as described here unless you tell us we can in writing. If you tell us we can, you may change your mind at any time. Let us know in writing if you change your mind.</li>
</ul>

<p>For more information see: <a href="https://www.hhs.gov/hipaa/for-individuals/notice-privacy-practices/index.html" target='_blank' rel="noopener noreferrer">www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html</a>.</p>

<p><strong>Changes to the Terms of this HIPAA Notice</strong></p>
<p>We can change the terms of this HIPAA Notice, and the changes will apply to all information we have about you. Any significant changes to this HIPAA Notice will be posted within the Application. The new HIPAA Notice will also be available upon request.  </p>

<p><strong>Other Instructions for this HIPAA Notice:</strong></p>
<ul className="disc">
<li>
<p>For questions about this Notice, please contact: </p>
<p>
AptiHealth, Inc.<br/>
Attn: Privacy Officer<br/>
340 Broadway, Suite 7<br/>
Saratoga Springs, NY 12866<br/>
(888) 454-3827 <br/>
<a href="mailto:support@aptihealth.com">support@aptihealth.com</a>
</p>
</li>

<li>Using the AptiHealth platform, AptiHealth Medical and primary care providers have come together as an OHCA (organized healthcare arrangement), which is a clinically integrated group in which patients receive healthcare from more than one health care provider. AptiHealth provides certain services, as a business associate, to the providers within the OHCA.</li>
<li>An OHCA allows legally separate covered entities and their business associates to use and disclose protected health information for the joint operations of the arrangement. This HIPAA Notice applies to any licensed professional or practice utilizing the AptiHealth engagement platform, which are members of an organized health care arrangement referred throughout this HIPAA Notice as “OHCA Members” and “we”.</li>
<li>OHCA Members are required by law to maintain the privacy of protected health information, give you notice of our legal duties and privacy practices requiring health information about you, and follow the terms of our HIPAA Notice that is currently in effect.</li>
</ul>

</div>
</section>

                                    
				<Footer/>

			</article>
    );
  }
}

export default HipaaPrivacy;
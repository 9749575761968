// Imports
import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import $ from 'jquery';


// Components
import Header from '../../components/Header/Header';
import Banner from '../../components/Banner/Banner';
import Footer from '../../components/Footer/Footer';

// Assets
import insight from'../../assets/svg/icn-insight.svg';
import execution from'../../assets/svg/icn-execute.svg';
import results from'../../assets/svg/icn-results.svg';


class About extends Component {

  componentDidMount() {
    let script = document.createElement('script');
    script.setAttribute('id', 'whr');
    script.onload = function() {
      let newScript = document.createElement('script');
      let inlineScript = document.createTextNode("whr(document).ready(function(){whr_embed(415413, {detail: 'titles', base: 'jobs', zoom: 'country', grouping: 'departments'});});");
      newScript.setAttribute('id', 'whr');
      newScript.appendChild(inlineScript); 
      document.getElementsByTagName('head')[0].appendChild(newScript); 
    
    };

    script.src = "https://www.workable.com/assets/embed.js";
    document.getElementsByTagName('head')[0].appendChild(script);
  
    // Open Links in New Window
    $(document).on('click', 'li.whr-item a', function(e) {
      e.preventDefault();
      window.open(this.href, '_blank');
    }); 
    

    if(window.location.hash === '#clinical') {
      var directory = document.getElementById("jobs");
      var scroll = directory.offsetTop
      console.log(scroll);

      setTimeout(function(){ 
        window.scrollTo({
          top:scroll,
          behavior: 'smooth'
        });

      }, 700);

    } 

    
  }  


  render() {
    
    return (
      <article>
				<Helmet>
					<title>Careers - Join aptihealth Today!</title>
					<meta name="description" content=""/>
				</Helmet>
        
        <Header />
        <Banner page='page-careers' heading='Welcome to aptihealth, where together, we are transforming behavioral healthcare.' />

        <section className="p30">
          <div className="wrap">

            <div className="three-col values">
              <div className="col">
                <div>
                  <img src={insight} alt='Insight'/>
                  <h3>Insight</h3>
                  <p>We&rsquo;re an inspired team of innovative thinkers, relentless in our pursuit of visionary ideas.</p>
                </div>
              </div>
              <div className="col">
                <div>
                  <img src={execution} alt='Execution'/>
                  <h3>Execution</h3>
                  <p>We commit to turning ideas into actions that lead to fresh, groundbreaking solutions.</p>
                </div>
              </div>
              <div className="col">
                <div>              
                  <img src={results} alt='Results'/>
                  <h3>Results</h3>
                  <p>We don&rsquo;t rest until our actions deliver breakthrough results – outcomes that mean more for people.</p>
                </div>
              </div>
            </div>

          </div>

          <div className='wrap sm'>
            <div className="careers">
              <h4>aptihealth is a behavioral health engagement company that seamlessly integrates physical and behavioral healthcare. </h4>
              <p>The aptihealth team are health care, technology, research, and user experience experts passionate about transforming behavioral&nbsp;healthcare. Everything we do at aptihealth is centered around our mission to revolutionize care and to ensure that the millions of people who need and deserve it have access to it. Together, we&rsquo;ll create and innovate transformative behavioral&nbsp;healthcare.</p>
              <a href="https://apply.workable.com/aptihealth/" target='_blank' className='btn lg'>Browse our current openings</a>
            </div>
          </div>
        </section>
        
        <section className="p30 grey">
          <div className="wrap sm">
            <p>At aptihealth, we don&rsquo;t just accept difference—we celebrate it, we support it, and we thrive on it for the benefit of our team, products, and community. aptihealth is proud to be an equal opportunity workplace and is an affirmative action employer. We are committed to equal employment opportunity regardless of race, color, ancestry, religion, sex, national origin, sexual orientation, age, citizenship, marital status, disability, gender identity or Veteran status. If you have a disability or special need that requires accommodation, please let us know by contacting us.</p>
          </div>          
        </section>
                        
				<Footer/>

			</article>
    );
  }
}

export default About;

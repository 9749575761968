import React, { Component } from 'react';
import Slide from './Slide';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import tony from'../../assets/profile-tony.jpg';
import hannah from'../../assets/profile-hannah.jpg';
import william from'../../assets/profile-william.jpg';
import james from'../../assets/profile-james.jpg';
import joe from'../../assets/profile-joe.jpg';
import mia from'../../assets/profile-mia.jpg';
import kristen from'../../assets/profile-kristen.jpg';
import rachel from'../../assets/profile-rachel.png';
import amanda from'../../assets/profile-amanda.png';
import kristin from'../../assets/profile-kristin.png';


class Testimonials extends Component {

	constructor(props) {
		super(props);
	  this.state = {
	    activeSlide: 0,
	    activeSlide2: 0
	  };
	}

  render() {
    let settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 6000,
      adaptiveHeight: true,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      afterChange: current => this.setState({ activeSlide2: current })      
    };

		const slides = this.props.slides;
		const Hannah = "My treatment plan was very personalized and goal-oriented. My psychologist and I made a plan and stuck to that plan to help me stay on track to achieve my goals. aptihealth truly helped me feel more empowered. I feel like my old self again."
		const Tony = ["I had been seeking treatment for 12 years. 12 constant years of trying everything. And in three months, this program just did me wonders. ", <strong key="02" className='italic'>aptihealth gave me my life&nbsp;back</strong>,"."]
		const William = "My patients are very happy with aptihealth. They’re happy with the timeliness of their visits and the care they receive. aptihealth is proactive and patient-friendly."
		const James = "The aptihealth assessment tool allows us to see the patient in dimensions that we hadn’t been able to previously. As a result, it allows us to have a more comprehensive view of the patient’s diagnosis and what their needs are moving forward. With the aptihealth care plan, they’re empowered and have the tools to enrich their therapeutic process. I find that they’re more likely to engage and participate in their own self-care needs."
		const Joe = "aptihealth speeds the delivery of care to the patient. There is a significantly reduced waiting time between detection of problems, assessment, and the beginning of treatment."
		const Mia = [<strong key="01">aptihealth</strong>, " fosters meaningful connections with my patients from the start of therapy, during, and between sessions. The assessment builds their skills to help them tell their story in a way that’s productive to their growth in therapy. Using this technology, I can support them wherever they are, and between sessions with direct messaging."]
		const Kristen = [<strong key="01">aptihealth</strong>, " is a completely different model of care—a virtual care model that’s more supportive, goal-oriented, and efficient. It has significantly enhanced my understanding of my clients and our relationships. With ", <strong key="03">aptihealth</strong>, ", my time is spent focused on their care vs administrative tasks, which is amazing!"]
		const Rachel = "As part of aptihealth’s care model, I deliver comprehensive care that’s accessible—meeting a person where they are allows me to experience the complexities of their world in a way that a traditional office visit would never reveal. I love that we demonstrate and define our positive outcomes through the platform and closely monitor and measure progress over time. I’m empowered to provide personalized care that’s actually tailored to our members needs and meet with them more frequently, and at their convenience, so they stay on track and get better faster."
    const Amanda = "At aptihealth I’m part of a heartfelt company that’s invested in members getting well and achieving their goals for treatment. Because of this we see the positive results of the care we provide, which is truly rewarding. As a provider, I love that my voice and feedback are valued and that the aptihealth platform is built and enhanced based on clinical best practices and real-world experience. The aptihealth Guided Session has created efficiency in care and provider collaboration that dramatically reduces administration time and ensures I’m aligned with the needs of the member and the care team."
    const Kristin = ["The ", <strong key="01">aptihealth</strong>, " 90-Day Care Program and my dedicated care team helped me get through a very difficult time in my life. My therapist and prescriber worked together to support me and ensure I was on the right medication and doctor was kept in the loop on my progress. I feel so much better, am more relaxed, and optimistic."]


		let testimonials = '';
		switch (slides) {
	    case 'home':
	    		testimonials = (
		    		<Slider {...settings}>
							<Slide name="Tony" title=", aptihealth Member" quote={Tony} photo={tony}/>		    		
							<Slide name="Hannah*" title=", aptihealth Member" quote={Hannah} photo={hannah} desc='*Name has been changed to protect privacy.'/>
							<Slide name="Dr. William Forlano" title=", Family Medicine Specialist" quote={William} photo={william}/>
							<Slide name="Dr. James Aram" title=", Family Physician, Geriatrician" quote={James} photo={james}/>
							<Slide name="Joe Kelly" title=", Licensed Clinical Social Worker" quote={Joe} photo={joe}/>
						</Slider>
	    		)
	        break;
	    case 'medicare':
	    		testimonials = (
		    		<Slider {...settings}>
							<Slide name="Hannah*, patient" quote={Hannah}/>
							<Slide name="Tony, patient" quote={Tony}/>
							<Slide name="Dr. William Forlano" quote={William}/>
							<Slide name="Dr. James Aram" quote={James}/>
							<Slide name="Joe Kelly, LCSW" quote={Joe}/>
						</Slider>
	    		)
	        break;
	    case 'consumer':
	    		testimonials = (
		    		<Slider {...settings}>
							<Slide name="Hannah*, patient" quote={Hannah}/>
							<Slide name="Tony, patient" quote={Tony}/>
							<Slide name="Dr. William Forlano" quote={William}/>
							<Slide name="Dr. James Aram" quote={James}/>
							<Slide name="Joe Kelly, LCSW" quote={Joe}/>
						</Slider>
	    		)
	        break;
	    case 'bhs':
          settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 11000,
            adaptiveHeight: true,
            beforeChange: (current, next) => this.setState({ activeSlide: next }),
            afterChange: current => this.setState({ activeSlide2: current })      
          };	    
	    		testimonials = (
		    		<Slider {...settings}>
							<Slide name="Mia" title=", aptihealth Licensed Master Social Worker" quote={Mia} photo={mia} />
							<Slide name="Kristen" title=", aptihealth Behavioral Health Specialist" quote={Kristen} photo={kristen} />
						</Slider>
	    		)
	        break;
	    case 'cdphp':
          settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 11000,
            adaptiveHeight: true,
            beforeChange: (current, next) => this.setState({ activeSlide: next }),
            afterChange: current => this.setState({ activeSlide2: current })      
          };	    
	    		testimonials = (
		    		<Slider {...settings}>
							<Slide name="Kristin" title=", aptihealth Member" quote={Kristin} photo={kristin} />
							<Slide name="Tony" title=", aptihealth Member" quote={Tony} photo={tony} />		    		
							<Slide name="Hannah*" title=", aptihealth Member" quote={Hannah} photo={hannah} desc='*Name has been changed to protect privacy.'/>
						</Slider>
	    		)
	        break;	        
	    case 'prescriber':
          settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 15000,
            adaptiveHeight: true,
            beforeChange: (current, next) => this.setState({ activeSlide: next }),
            afterChange: current => this.setState({ activeSlide2: current })      
          };
	    		testimonials = (
		    		<Slider {...settings}>
							<Slide name="Rachel" title=", aptihealth Psychiatric Nurse Practitioner" quote={Rachel} photo={rachel} />
							<Slide name="Amanda" title=", PMHNP-BC" quote={Amanda} photo={amanda} />
						</Slider>
	    		)
	        break;
	    default:
	    		testimonials = (
		    		<Slider {...settings}>
							<Slide name="Hannah*, patient" quote={Hannah}/>
							<Slide name="Tony, patient" quote={Tony}/>
							<Slide name="Dr. William Forlano" quote={William}/>
							<Slide name="Dr. James Aram" quote={James}/>
							<Slide name="Joe Kelly, LCSW" quote={Joe}/>
						</Slider>
	    		)
	        break;
		}


		  return (
  			<section id="testimonials" className={'bg' + this.state.activeSlide + ' ' + slides + " pt30"} >
  				<div className="wrap">
  					<div className="slider">
  						{testimonials}
  					</div>
  				</div>
  			</section>  		  
  		)      


	}
}

export default Testimonials;

// Imports
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

// Components
import Header from '../../components/Header/Header';
import Alert from '../../components/Header/AlertBanner';
import Banner from '../../components/Banner/Banner';
import Intro from '../../components/Intro/Intro';
import Stats from '../../components/Stats/Stats';
import Footer from '../../components/Footer/Footer';
import Modal from '../../components/Modal/Modal';


// Assets
import laptop from'../../assets/screen-health-plans.png';
import care from'../../assets/svg/icn-care.svg';
import access from'../../assets/svg/icn-access.svg';


class HealthPlans extends Component {

  state = {
		showModal: false,
		showCaseStudy: false,        		    
  };
  
	openModalHandler = (overlay) => {
  	console.log('CLICK! ' + overlay);
    if(overlay === 'cs' || this.props.cs){
      console.log('YES');
		  this.setState({showCaseStudy:true});
		  if(!this.props.modal){
    		this.props.history.push('case-study/');  
		  }	  
    } else {
  		this.setState({showModal:true});
		  if(!this.props.modal){
        this.props.history.push('join/');
		  }	  
    }
	}

	closeModalHandler = () => {
		this.props.history.push('/health-plans/');
		this.setState({showModal:false});
		this.setState({showCaseStudy:false});		
	}

	componentDidMount () {
		if(this.props.modal){
			this.openModalHandler();
		}
	}
  

  render() {
    
    const alert = this.props.alert ? <Alert /> : null;
    const alertClass = this.props.alert ?  'show-alert' : null;        
    
    return (
      <article className={alertClass}>
				<Helmet>
					<title>Health Plans - Expand Access, Speed Treatment, and Improve Results with aptihealth</title>
					<meta name="description" content="We help you Personalize Care, Increase Patient Engagement, and Lower Costs"/>
				</Helmet>
        
        <Header />
        {alert}        
        <Banner page='page-healthplans' heading='Expand Access, Speed Treatment, and Improve Results for Patients Ages 5 and up' eyebrow='Health Plans'/>

        <Intro photo={laptop} bg='grey computer' alt='aptihealth Dashboard Computer Screen'>
          <h3>Personalize Care, Increase Patient Engagement, and Lower Costs</h3>
          <p>More than 20% of patients today have behavioral health conditions, which accounts for over $300 billion in annual healthcare spending. And because care for patients with behavioral health challenges is three times more costly, it&rsquo;s critical to the health of patients and your plan to address their needs quickly and efficiently.</p>
          <p>aptihealth is here to help by providing expedited and integrated care to patients ages 5 and up with a powerful platform, provider network, and easy-to-use tools. With aptihealth incorporated into your provider care stream, your plan will benefit as patients receive improved access to care, improved quality care, increased engagement, and improved outcomes.</p>
        </Intro>
        
        <section className="pt30 results">
          <div className="wrap">
            <div className="two-col">
              <div className="col">
                <img src={access} alt='Improved Access'/>
                <h3>Improved Access</h3>
                <ul className="disc">
                  <li>Enable enrollment at any access point that&rsquo;s <strong>fast and easy</strong></li>
                  <li>Network of <strong>licensed therapists and prescribers</strong>, ready to take on members immediately</li>
                  <li>Care delivered virtually in the <strong>privacy</strong> of members&rsquo; homes</li>
                  <li><strong>HIPAA-compliant</strong>, scalable digital platform for seamless patient assessment, scheduling, treatment, data collection, and coordination between the care team</li>                
                </ul>
              </div>
            
              <div className="col">
                <img src={care} alt='Improved Care'/>
                <h3>Improved Care</h3>
                <ul className="disc">
                  <li>Evidence-based treatment <strong>for patients&rsquo; ages 5 and up</strong></li>
                  <li>Precision care programs based upon each member&rsquo;s acuity</li>
                  <li><strong>Personalized therapy</strong> and medication management tailored to meet each member&rsquo;s unique needs</li>
                  <li>Primary care to manage medical comorbidities and <strong>24/7 support</strong> from our dedicated team of licensed specialists</li>
                  <li><strong>Secure data</strong> sharing to coordinate care between care team&nbsp;members</li>
                </ul>
              </div>
            </div>
            
          </div>
        </section>  
        
        
        <Stats heading="Improved Outcomes" background="grey" openModalHandler={this.openModalHandler} />		
      

        <section id="cta" className="p30 dark">
          <div className="wrap sm">          
            <h3>Interested in how aptihealth provides fast, virtual-first access to behavioral healthcare for children, teens, and adults? <strong>Click Learn More and we'll be in touch</strong>.</h3>
            <Link className="btn" to='/contact'>Learn More</Link>
            {/* <Link className="btn" onClick={this.openModalHandler}>Learn More</Link> */}
          </div>        
        </section>  
        

        <Modal 
          closeModal={this.closeModalHandler} 
          show={this.state.showModal} 
          hubspot='0d643b0c-1ad6-43c7-8303-cca9811d3482'
        >
          <h3>Learn More About aptihealth</h3>
        </Modal> 
        
        <Modal 
          closeModal={this.closeModalHandler} 
          show={this.state.showCaseStudy} 
          hubspot='ad3fffe4-0a99-4277-9817-2d55c9d7fa25'
        >
          <h3>Sign Up for the Case Study!</h3>
        </Modal>                    
                           
                        
				<Footer/>

			</article>
    );
  }
}

export default HealthPlans;

import React from 'react';

export default function CTA(props) {
  
  const handlePartnerToggle = event => {
    console.log('here');
    if (document.body.classList.contains('openPartners')) {
      console.log('YES');
      //document.body.classList.remove('openPartners');
    } else {
      document.body.classList.add('openPartners');
    }
    
    var directory = document.getElementById("introScreening");
    var header = document.getElementById("header");
    var scroll = directory.offsetTop - header.offsetHeight;
    window.scrollTo({
      top:scroll,
      behavior: 'smooth'
    });
    
    event.preventDefault();
  }
  
  const trackExternalLink = (url, text) => {
    console.log('TRACK ::'+ url);
    console.log('TRACK ::'+ text);
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event' : 'Send To Application',
      'eventCategory': 'Click',
      'eventAction': 'Load Website',
      'eventLabel': text,      
      'destination' : url
    });

	}   
  
  const partnerButton = <a href={props.btnLink} className={'btn ' + props.btnStyle} onClick={(e) => trackExternalLink(props.btnLink, props.btnText)}><span dangerouslySetInnerHTML={{ __html: `${props.btnText}`}}></span></a>
  const homeButton = <button className="btn alt" onClick={handlePartnerToggle} dangerouslySetInnerHTML={{ __html: `${props.copy}`}}></button>
  const button = props.btnLink ? partnerButton : homeButton;  
   
  return (
    <>
      {button}
    </>
	)
}
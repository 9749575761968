// Imports
import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import Collapse from "@kunukn/react-collapse";
import cx from 'classnames/bind'; 

// Components
import Header from '../../components/Header/Header';
import AnimatedBanner from '../../components/Banner/AnimatedBanner';
import Intro from '../../components/Intro/Intro';
import Testimonials from '../../components/Testimonials/Testimonials';
import Footer from '../../components/Footer/Footer';
// import Modal from '../../components/Modal/MailChimp';
import Cookie from "../../components/Cookie/Cookie";


// Assets
import statDepression from'../../assets/svg/grph-stat-depression-dec.svg';
import statAnxiety from'../../assets/svg/grph-stat-anxiety-dec.svg';
import statGraduate from'../../assets/svg/grph-stat-graduate.svg';
import statCompletion from'../../assets/svg/grph-stat-completion.svg';


import iconBenefits from'../../assets/bhs_icon_benefits.png';
import iconHassleFree from'../../assets/bhs_icon_hassle_free.png';
import iconTeam from'../../assets/bhs_icon_team.png';


class BehavioralSpecialists extends Component {

  state = {
    index: 1,
		showModal: false, 
		showCaseStudy: false,        		           
  };

  onToggle = index =>
    this.setState(state => ({ index: state.index === index ? null : index })
  );
  
	openModalHandler = (overlay) => {
		this.setState({showModal:true});
	  if(!this.props.modal){  		
  		this.props.history.push('start/');
    }
	}	

	closeModalHandler = () => {
		this.props.history.push('/providers/behavioral-specialists/');
		this.setState({showModal:false});
		this.setState({showCaseStudy:false});		
	}
		

	componentDidMount () {
		if(this.props.modal){
			this.openModalHandler();
		}
	}  

  render() {

    return (
      <article id='lightUp'>
				<Helmet>
					<title>Join aptihealth Today: Integrate Physician and Prescriber Care Virtually</title>
					<meta name="description" content="aptihealth is partnering with behavioral specialists focused on providing the highest-quality care through innovative, evidence-based treatment that&rsquo;s tailored to help patients achieve success."/>
				</Helmet>
        
        <Header />
        <AnimatedBanner heading='Light up their hope' />

        <Intro video='hz41pj7fti' bg='campaign'>
          <p><strong>aptihealth</strong> empowers you to know the members in your care with unprecedented speed and depth, so you can deliver personalized treatment and inspire life-changing outcomes within 90 days.</p>
          <p>If you&rsquo;re looking to devote more time to care, it&rsquo;s time to join <strong>aptihealth</strong>. We are the only tech-enabled, people-first, behavioral health platform that connects you to an integrated, fully collaborative, mission-driven team that&rsquo;s needed now more than ever.</p>
          {/* <button id="getStartedButton" className="btn" onClick={this.openModalHandler}>Let&rsquo;s get started...</button> */}
          {/* <p id="getStartedButtonSecondary"><button className="btn" onClick={this.openModalHandler}>Let&rsquo;s get started...</button></p> */}
          <a id="getStartedButton" href="https://apply.workable.com/aptihealth/" target='_blank' className='btn'>Browse Current Openings</a>
          <p id="getStartedButtonSecondary"><a id="getStartedButton" href="https://apply.workable.com/aptihealth/" target='_blank' className='btn'>Browse Current Openings</a></p>          
        </Intro>
        
        
        <section id="accordion" className="p30 laptop">
          <div className="wrap">
            <h2>Transformative technology and personalized treatment that lights the&nbsp;way</h2>
            <p>Access deeper connections, meaningfully engage with patients, and deliver the light of hope. Driven by extensive research and user-friendly technology, <strong>aptihealth&rsquo;s integrated intelligent care</strong> pioneers significant clinical efficiencies that free you to focus on patients in your care without the traditional administrative burdens.</p>
            
            <div className='accordion'>
              <ul>
              
                <li className={cx("item", { "active": this.state.index === 1 })}>
                  <h4 onClick={() => this.onToggle(1)}>
                    <span>Teamwork and collaboration</span>
                  </h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 1}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {collapseState => (
                      <div className={"app__content " + collapseState}>
                        <p><em>When teams collaborate, close communication gaps, and focus as one, patients&rsquo; lives quickly change for the better.</em> You will be part of a seamlessly connected, integrated, fully collaborative care team comprised of a behavioral health specialist, prescriber, care manager, and primary care provider. As a like-minded, people-first, multidisciplinary team you&rsquo;ll deliver hope—and a higher level of care that surrounds patients and is proven to quickly change&nbsp;lives.</p>
                      </div>
                    )}
                  </Collapse>
                </li>
  
                <li className={cx("item", { "active": this.state.index === 2 })}>
                  <h4 onClick={() => this.onToggle(2)}>Access a deeper connection</h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 2}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {collapseState => (
                      <div className={"app__content " + collapseState}>
                        <p><em>Feel professionally fulfilled as you quickly gain an in-depth understanding of the <strong>aptihealth</strong> members in your care, creating a meaningful connection that results in highly engaged, motivated members who are committed to care. </em> How? Proprietary <strong>aptihealth</strong> screening and assessment tools deliver an in-depth understanding of a member&rsquo;s needs before the first session. This is the spark that lights the way to delivering positive outcomes. In fact, 81% of members graduate our 90-day behavioral health program.</p>
                      </div>
                    )}
                  </Collapse>
                </li>
                
                <li className={cx("item", { "active": this.state.index === 3 })}>
                  <h4 onClick={() => this.onToggle(3)}>Personalized, goal-oriented treatment</h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 3}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {collapseState => (
                      <div className={"app__content " + collapseState}>
                      <p><em>It&rsquo;s your time to be a trailblazer in the delivery of behavioral healthcare.</em> With our comprehensive assessment and care plan wizard, you&rsquo;re in control of creating a personalized, goal-oriented treatment plan. You&rsquo;ll also monitor and track progress weekly through our user-friendly digital tools and monthly through standardized screenings, so you can help ensure members stay on track and achieve measurable, life-changing results within 90 days.</p>
                      </div>
                    )}
                  </Collapse>
                </li>
                
                <li className={cx("item", { "active": this.state.index === 4 })}>
                  <h4 onClick={() => this.onToggle(4)}>Supercharged productivity</h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 4}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {collapseState => (
                      <div className={"app__content " + collapseState}>
                      <p><em><strong>aptihealth</strong> technology brings behavioral health into the 21st century with user-friendly smart tools to relieve your administrative burdens so you can focus on member care.</em> We&rsquo;ve made taking and sharing notes, scheduling, care planning, and securely communicating with members and the full collaborative care team more convenient than ever. Consolidate your screens to just one interface during <strong>aptihealth</strong> Guided Sessions&trade; and keep your focus where it belongs: with members who are motivated and focused on their treatment goals.</p>
                      </div>
                    )}
                  </Collapse>
                </li>
                
              </ul>                        
            </div>
            <div id="laptop">
              <div className="device">
                <div className="container lightUp">
                <div className={cx("s1", { "active-screen": this.state.index === 1 })}></div>
                <div className={cx("s2", { "active-screen": this.state.index === 2 })}></div>
                <div className={cx("s3", { "active-screen": this.state.index === 3 })}></div>
                <div className={cx("s4", { "active-screen": this.state.index === 4 })}></div>
                <div className={cx("s5", { "active-screen": this.state.index === 5 })}></div>                                                                
                <div className={cx("s6", { "active-screen": this.state.index === 6 })}></div>                                                                
                </div>
              </div>
            </div>
            
          </div>
        </section>  
        
        <section className="benefits grey p30">
          <div className="wrap">
            <h2>aptihealth offers industry leading benefits</h2>
            <div className="three-col benefits-cols">
              <div className="col">
                <img src={iconTeam} alt="Distinguished Team of Experts"/>
                <h4>Distinguished Team of&nbsp;Experts</h4>
                <ul>
                  <li><span>Collaborate with a community of like-minded therapists and prescribers</span></li>
                  <li><span>Continuing education, career development, and training</span></li>
                </ul>
              </div>
              <div className="col">
                <img src={iconHassleFree} alt="Hassle-Free Care Delivery"/>
                <h4>Hassle-Free Care&nbsp;Delivery</h4>
                <ul>
                  <li><span>Flexible hours so you work on your own&nbsp;schedule</span></li>
                  <li><span>Reduce administrative burden creating more member-oriented time</span></li>
                  <li><span>Never deal with billing, leave that to us</span></li>
                </ul>
              </div>
              <div className="col">
                <img src={iconBenefits} alt="First-Class Compensation and Benefits"/>
                <h4>First-Class Compensation and&nbsp;Benefits</h4>
                <p>Benefits for both full and part-time team members include:</p>
                <ul>
                  <li><span>Insurance</span></li>
                  <li><span>401k</span></li>
                  <li><span>Paid time off</span></li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        
        <Testimonials slides="bhs" />
        
        <section className="p30 grey">
          <div className="wrap">
            <div className="two-col bhs-stats">
              <div className="col">
                <h3>Connect with Highly Engaged Members<sup>1</sup></h3>
                <img src={statCompletion} className="" alt='96% completion rate of our proprietary A30 Assessment'/>              
                <img src={statGraduate} className="" alt='81% of members graduate our 90-day behavioral health program'/>              
              </div>
              <div className="col right">
                <h3>Deliver Life-Changing Results within 90 Days<sup>1</sup></h3>
                <img src={statDepression} className="" alt='60% Reduction in depression symptoms'/>              
                <img src={statAnxiety} className="" alt='57% Reduction in anxiety symptoms'/>              
              </div>              
            </div>
            <p className='ref'><strong>Reference:</strong> 1. aptihealth Clinical Intelligence Engine. Data on&nbsp;File. </p>            
          </div>
        </section>   

        {/* <Modal 
          closeModal={this.closeModalHandler} 
          show={this.state.showModal}
          type='therapy' 
        >
          <h3>Let&rsquo;s discuss your bright future with aptihealth</h3>
          <p>We&rsquo;re looking for behavioral health specialists to deliver hope and faster outcomes in clinical care. If that&rsquo;s you, let&rsquo;s start a conversation…</p>
        </Modal>   */}
                                                
				<Footer />
				<Cookie />

			</article>
    );
  }
}

export default BehavioralSpecialists;

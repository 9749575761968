// Imports
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import Collapse from "@kunukn/react-collapse";
import cx from 'classnames/bind'; 

// Components
import Header from '../../components/Header/Header';
import Alert from '../../components/Header/AlertBanner';
import Banner from '../../components/Banner/Banner';
import Intro from '../../components/Intro/Intro';
import Modal from '../../components/Modal/Modal';
import Footer from '../../components/Footer/Footer';

// Assets
import stat03 from'../../assets/svg/grph-stat2-66.svg';




class PrimaryCare extends Component {

  state = {
		showModal: false,        
    index: 1,
		showCaseStudy: false,            
  };

  onToggle = index =>
    this.setState(state => ({ index: state.index === index ? null : index })
  );
  	
	openModalHandler = (overlay) => {
    if(overlay === 'cs' || this.props.cs){
		  this.setState({showCaseStudy:true});
		  if(!this.props.modal){  		
  		  this.props.history.push('case-study/');		  
  		}
    } else {
  		this.setState({showModal:true});
		  if(!this.props.modal){  		
    		this.props.history.push('integrate/');
      }
    }
	}	

	closeModalHandler = () => {
		this.props.history.push('/health-systems/primary-care/');
		this.setState({showModal:false});
		this.setState({showCaseStudy:false});		
	}

	componentDidMount () {
		if(this.props.modal){
			this.openModalHandler();
		}
	}  

  render() {
    
    const alert = this.props.alert ? <Alert /> : null;
    const alertClass = this.props.alert ?  'show-alert' : null;                

    return (
      <article className={alertClass}>
				<Helmet>
					<title>Primary Care Providers - Quickly Connect Patients With the Behavioral Health Care They Need</title>
					<meta name="description" content=""/>
				</Helmet>
        
        <Header />
        {alert}
        <Banner page='page-primary' heading='Quickly Connect Patients With the Behavioral Health Care They Need' eyebrow="Primary Care Providers" />

        <Intro video='orfgr8vj0v'>
          <h3>Fully Integrated Care for Teens and Adults</h3>
          <p>When patients present with behavioral health challenges, they need a specialist’s care as soon as possible. aptihealth is here to help by eliminating barriers that hamper the referral process. Driven by extensive research and user-friendly technology, aptihealth seamlessly expands your practice to include a fully integrated virtual behavioral healthcare team that serves patients 5 and up.</p>
        </Intro>
        
        <section id="accordion" className="p30 laptop">
          <div className="wrap">
            <h2>The 5 Fast, Effective Steps to Better Mental Health</h2>          
            
            <div className='accordion'>
              <ul>
              
                <li className={cx("item", { "active": this.state.index === 1 })}>
                  <h4 onClick={() => this.onToggle(1)}>
                    <span>1. Online Patient Self-Assessment</span>
                  </h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 1}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {collapseState => (
                      <div className={"app__content " + collapseState}>
                        <p>Proprietary online patient self-assessment across a 15 Life Domain Clinical Framework<sup>&copy;</sup> that generates a comprehensive report for you and the care team.</p>
                      </div>
                    )}
                  </Collapse>
                </li>
  
                <li className={cx("item", { "active": this.state.index === 2 })}>
                  <h4 onClick={() => this.onToggle(2)}>2. Licensed Therapist Consultation</h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 2}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {collapseState => (
                      <div className={"app__content " + collapseState}>
                        <p>One-on-one video consult between the patient and the behavioral specialist to review the self-assessment and discuss behavioral issues, treatment goals, a treatment plan, and the patient&rsquo;s path to success.</p>
                      </div>
                    )}
                  </Collapse>
                </li>
                
                <li className={cx("item", { "active": this.state.index === 3 })}>
                  <h4 onClick={() => this.onToggle(3)}>3. Comprehensive Patient Reports</h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 3}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {collapseState => (
                      <div className={"app__content " + collapseState}>
                      <p>A detailed Behavioral Assessment Report (BAR) and Physician Summary Report (PSR) for you and the care team to clearly understand the patient&rsquo;s condition, treatment plan, progress, and overall health needs.</p>
                      </div>
                    )}
                  </Collapse>
                </li>
                
                <li className={cx("item", { "active": this.state.index === 4 })}>
                  <h4 onClick={() => this.onToggle(4)}>4. 90-Day Care</h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 4}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {collapseState => (
                      <div className={"app__content " + collapseState}>
                      <p>A focused period of intense care, including telehealth behavioral therapy, chat therapy, medication management and care team collaboration tailored to each patient&rsquo;s unique needs and goals.</p>
                      </div>
                    )}
                  </Collapse>
                </li>
                
                <li className={cx("item", { "active": this.state.index === 5 })}>
                  <h4 onClick={() => this.onToggle(5)}>5. Extended Care</h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 5}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {collapseState => (
                      <div className={"app__content " + collapseState}>
                      <p>If prescribed, a seamless transition to less intense ongoing treatment, including telehealth behavioral therapy, chat therapy, medication management and care team collaboration – for care that keeps patients on track over the long term.</p>
                      </div>
                    )}
                  </Collapse>
                </li>                            

              </ul>                        
            </div>
            <div id="laptop">
              <div className="device">
                <div className="container">
                <div className={cx("s1", { "active-screen": this.state.index === 1 })}></div>
                <div className={cx("s2", { "active-screen": this.state.index === 2 })}></div>
                <div className={cx("s3", { "active-screen": this.state.index === 3 })}></div>
                <div className={cx("s4", { "active-screen": this.state.index === 4 })}></div>
                <div className={cx("s5", { "active-screen": this.state.index === 5 })}></div>                                                                
                </div>
              </div>
            </div>
            
          </div>
        </section>
        
        <div className="provider alt">
          <div className="col">
            <div className="content">
              <h3>An Indispensable Tool For Your Practice</h3>
              <ul className="checks">
                <li>Fully integrated evidence-based care with proven results</li>
                <li>Innovative HIPAA-compliant technology</li>
                <li>Simple referral process to expert care</li>
                <li>Proprietary on-line patient self-assessment</li>
                <li>Network of licensed behavioral health practitioners</li>
                <li>Real-time reporting to keep you and the care team fully&nbsp;informed</li>
                <li>An aptihealth customer success manager to support you every step of the&nbsp;way</li>
              </ul>
            </div>
          </div>
          <div className="col photo tool"></div>
        </div>

        <section className="p30 grey">
          <div className="wrap">
            <div className="two-col resultsMatter">
              <div className="col">
                <h3>Results That Matter</h3>
                <p>In a six-month study across 4 clinical sites with 37 physicians and 75,000 covered patient lives:</p>                
                <ul className="disc">
                  <li>Most individuals have initial assessment in <strong>less than 5 days</strong></li>
                  <li>Satisfaction is <strong>89% greater with the aptihealth</strong> video-based assessment than with traditional clinical interviews</li>
                  <li>Individuals with depression or anxiety experience <strong>significant improvements</strong> in their symptoms within the first 90 days of care</li>
                </ul>
                <p>
                  Read the case study to learn more about these results and how aptihealth is transforming behavioral healthcare. <a href="/pdf/Aptihealth_Case_Study_08-14-2019.pdf" className='link' target='_blank' >Get the Case Study</a>.
                  {/* <Link className="link" onClick={()=>this.openModalHandler('cs')}>Get the Case Study</Link>. */}
                </p> 
              </div>
              <div className="col statFirst">
                <img src={stat03} className="primary-stat" alt='2/3rds of Patientes Completed the assessment process within 5 days'/>              
              </div>              
            </div>
          </div>
        </section>  
        
        <section id="cta" className="p30 dark">
          <div className="wrap sm">
            <h3>Interested in how aptihealth provides fast, virtual-first access to behavioral healthcare for children, teens, and adults? <strong>Click Learn More and we'll be in touch</strong>.</h3>
            <Link className="btn" to='/contact'>Learn More</Link>
            {/* <Link className="btn" onClick={this.openModalHandler}>Learn More</Link> */}
          </div>        
        </section> 
        
        <Modal 
          closeModal={this.closeModalHandler} 
          show={this.state.showModal} 
          hubspot='aa07c68d-62fc-4e3a-986c-75d4538201f1'
        >
          <h3>Learn More About aptihealth</h3>
        </Modal>                     
                        
        <Modal 
          closeModal={this.closeModalHandler} 
          show={this.state.showCaseStudy} 
          hubspot='ad3fffe4-0a99-4277-9817-2d55c9d7fa25'
        >
          <h3>Sign Up for the Case Study!</h3>
        </Modal>                    

				<Footer/>

			</article>
    );
  }
}

export default PrimaryCare;

// Imports
import React, { Component } from "react";
import { Helmet } from "react-helmet";

// Components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

class Consent extends Component {
  render() {
    return (
      <article id="single">
        <Helmet>
          <title>Consent to Treatment via Telehealth</title>
        </Helmet>

        <Header />

        <section className="single privacy terms">
          <div className="wrap">
            <h1>Consent to Treatment via Telehealth</h1>

            <p>
              <em>
                The purpose of this form is to provide you with information
                about treatment via telehealth and to obtain your informed
                consent.
              </em>
            </p>
            <p>
              <strong>CONSENT FOR TREATMENT:</strong> By signing this document,
              I do hereby consent to, request and authorize aptihealth Medical,
              PLLC (“aptihealth”), its medical practices and providers including
              physicians, allied health professionals, mental health counselors,
              clinical social workers, social workers, nurses, and other
              qualified personnel to perform evaluation and treatment services
              and procedures as may be indicated to advance me toward the goals
              of treatment and when necessary or required by Law, in accordance
              with the judgment of the attending medical practitioner(s). I
              acknowledge that no warranty of guarantee can be made by anyone
              concerning the results of treatments, counseling, assessment or
              screenings, examinations or procedures.
            </p>
            <p>I acknowledge I have the right to be informed of:</p>

            <ul className="disc">
              <li>
                The nature of the treatment, including other care, treatments,
                or medications
              </li>
              <li>
                Potential benefits, risks or side effects of the treatment
              </li>
              <li>The likelihood of achieving treatment goals</li>
              <li>
                Reasonable alternatives and the relevant risks, benefits and
                side effects related to such alternatives, including the
                possible results of not receiving care or treatment
              </li>
              <li>
                Any independent medical research or significant economic
                interests your provider may have related to the performance of
                the proposed treatment.
              </li>
            </ul>
            <p>
              <strong>NATURE OF TELEHEALTH:</strong> Telehealth is the use of
              electronic information and communication technologies to enable a
              healthcare provider and a patient at different locations to share
              medical information for the purpose of improving patient care. The
              information you provide may be used for diagnosis, therapy,
              medication management, follow-up and/or patient education. The
              delivery of healthcare via telehealth allows the patient and
              provider to establish a relationship, much as they would during a
              traditional face-to-face appointment. For example, your telehealth
              encounter may include interaction through and with the use of some
              of the following: synchronous video (e.g. videoconferencing)
              and/or asynchronous technology, such as store-and-forward
              technology to exchange medical data and secure messaging portal
              communication. Telehealth is not to be used for urgent or
              emergency consults, nor is it a replacement for primary care.
            </p>

            <p>
              <strong>ALTERNATIVES:</strong> Alternative methods of care, such
              as in-person services, may be available to you through aptihealth
              or another provider. In some cases, in-person services may not be
              available and aptihealth will help you to locate an alternate
              provider.
            </p>
            <p>
              <em>
                I acknowledge that I may choose an alternative at any time.
              </em>
            </p>

            <p>
              <strong>YOUR PRIVACY RIGHTS:</strong> aptihealth uses security
              protocols to protect the confidentiality of your patient health
              information. Information about your medical and psychiatric health
              is protected by federal laws: The Health Insurance Portability and
              Accountability Act of 1996 and its subsequent amendments
              (“HIPAA”). Personal information that identifies you or contains
              protected health information will not be disclosed to any third
              party without your consent, except as authorized by law for the
              purposes of consultation, treatment, payment/billing, and certain
              administrative purposes, or as otherwise set forth in aptihealth’s
              Notice of Privacy Practices.
            </p>

            <p>
              <strong>
                EMERGENCIES: In the event of an urgent health issue or concern,
                you must seek care in-person, at a facility or provider equipped
                to deliver urgent or emergent care.
              </strong>
            </p>
            <p>
              <strong>
                <u>
                  IF YOU ARE THINKING ABOUT SUICIDE OR ARE WORRIED ABOUT A
                  FAMILY MEMBER, FRIEND, OR LOVED ONE COMMITTING SUICIDE or
                  believe you may harm yourself or others or that your child may
                  harm herself or himself or others, you must immediately call
                  911 or take your child to the nearest emergency room for help.
                </u>{" "}
                You may also call 988 or dial 1-800-273-8255 to reach the
                Suicide & Crisis Lifeline (formerly known as the National
                Suicide Prevention Lifeline), which provides free and
                confidential emotional support to people in suicidal crisis or
                emotional distress 24 hours a day, 7 days a week, across the
                United States.
              </strong>
            </p>

            <p>
              <strong>LICENSE VERIFICATION:</strong> To verify a mental health
              provider’s license in New York State, you can visit the New York
              State Department of Health website. The website provides a
              Verification of Information page that explains how to request
              verification of NYS certification. You will need to provide a
              signed consent from the provider, and certification information
              will not be supplied over the phone.
            </p>
            <p>
              <strong>RECOMMENDED:</strong> Alternatively, you can visit the
              Office of the Professions website at nysed.gov. The website
              provides a Verification Search page that allows you to search for
              license verification information. The database is updated daily
              and is considered to be a secure, primary source for license
              verification. By entering the name of a provider in a search by
              “licensee name” from ALL Professions ALL, and clicking GO, you can
              verify the licensing status of any provider.
            </p>
            <p>
              <strong>BY SIGNING THIS FORM, I UNDERSTAND</strong> that
              telehealth involves the use of electronic information and
              communication technologies by a healthcare provider to deliver
              services to a patient when the patient is located at a different
              site than the provider, and I hereby consent to aptihealth
              providers delivering services to me via telehealth.
            </p>
            <p>
              I have been given the opportunity to ask questions regarding the
              telehealth technology. I understand that this visit will not be
              the same as an in-person visit due to the fact that I will not be
              in the same physical location as the healthcare provider at the
              distant site. I further understand that the provider will
              determine whether telehealth is appropriate for me at this time. I
              understand that I may benefit from telehealth, but that results
              cannot be guaranteed.
            </p>
            <p>
              The provider will inform me who will be present at the provider’s
              location during the telehealth service, and I have the right to
              exclude anyone from being present, if I so choose. I understand
              that the laws that protect privacy and the confidentiality of
              medical information also apply to telehealth. I understand that I
              have the right to inspect and obtain copies of all information
              received and recorded during any telehealth session, subject to
              the policies of the providers involved in my care. I may be
              charged a fee for copies of records in accordance with applicable
              State rules.
            </p>
            <p>
              I understand that I have the right to withhold or withdraw my
              consent to the use of telehealth at any time, without affecting my
              right to future care. I may revoke my consent orally or in writing
              at any time by contacting aptihealth.
            </p>
            <p>
              I am responsible for deductibles, co-payments, co-insurance or any
              other patient responsibility amounts indicated by my insurance
              carrier and for any services not covered by my insurance. I assume
              ultimate and full financial responsibility for services rendered
              to me if I do not have health insurance or my health insurance
              carrier denies or does not cover my claim for services or I choose
              to not submit claims for payment to my insurance carrier. I
              understand the terms of this form and accept financial
              responsibility with or without the use of insurance coverage.
            </p>
            <p>
              I have read and understand the information above, and all of my
              questions have been answered to my satisfaction.
            </p>
            <p>Your signature on this form indicates that:</p>
            <ul className="disc">
              <li>
                You have read and understand the information provided in this
                form;
              </li>
              <li>
                Your provider has adequately explained to you the recommended
                treatment, along with the risks, benefits, and alternatives;
              </li>
              <li>You have had a chance to ask your providers questions;</li>
              <li>
                You have received all the information you desire concerning the
                treatment; and
              </li>
              <li>
                You authorize and consent to the performance of the treatment.
              </li>
            </ul>
          </div>
        </section>

        <Footer />
      </article>
    );
  }
}

export default Consent;

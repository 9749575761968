import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import WistiaEmbed from '../../components/WistiaEmbed/WistiaEmbed';
import Cookie from "../../components/Cookie/Cookie";

import Header from '../../components/Header/ClientHeader';
import Alert from '../../components/Header/AlertBanner';
import Banner from '../../components/Banner/ClientBanner';
import Steps from '../../components/Steps/Steps';
import Testimonials from '../../components/Testimonials/Testimonials';
import CTA from '../../components/CTA/CTA';
import Prevention from '../../components/Footer/Prevention';
import Footer from '../../components/Footer/Footer';


class Default extends Component {


  render() {
    const pageTitle = this.props.pageTitle;
    const partner = this.props.page;
    const alert = this.props.alert ? <Alert /> : null;
    const alertClass = this.props.alert ?  'show-alert' : null;
    	 
    return (
      <article className={alertClass}>
  			<Helmet>
  				<title>{'aptihealth ' + pageTitle}</title>
  				<meta name="description" content='Your Fast Track to Better Mental Health. Receive personalized, goal-oriented teletherapy that empowers you to make positive life changes and feel better within 90 days.' />
  			</Helmet>
        
        <Header partner={partner} btnText='Log In' btnLink='https://home.aptihealth.com/auth/sign-in' btnStyle='alt' />
        {alert}
        <Banner />

        <section id="introScreening" className='clientIntro'>  						
          <div className='wrap'>
            <CTA btnText='Get Started' btnLink='https://home.aptihealth.com/auth/self-signup' />          
            <div className='copy'>
              <h4>Receive <strong><em>personalized, goal-oriented teletherapy</em></strong> that empowers you to make positive life changes and feel better within 90&nbsp;days.</h4>
            </div>
          </div>
        </section>
        
        <Steps partner={partner} />
  			<Testimonials slides="cdphp" />
  			
  			<section className="pt60 grey clientPages">
          <div className="wrap">
            <div className="two-col pad">
              <div className="col">
                <h3>It&rsquo;s Easy to Use aptihealth</h3>
                <p>All you need is a smartphone or a computer. You will receive a login to&nbsp;access our secure application for:</p>
                <ul className="checks">
                  <li>Your personalized assessment</li>
                  <li>Scheduling appointments</li>
                  <li>Online video therapy sessions</li>
                  <li>Direct messaging your therapist</li>
                </ul>
              </div>
              <div className="col">
                <h3>aptihealth Member Success Stories</h3>
                <p>Watch Hannah’s inspiring journey to learn more about the aptihealth experience.</p>
                <WistiaEmbed hashedId='27a7jiak5v' playerColor='#0CD0A7' />
              </div>
            </div>
            <p className='pcta'>
              <CTA btnText='Get Started' btnLink='https://home.aptihealth.com/auth/self-signup' btnStyle='alt' />          
            </p>
          </div>        
        </section>
          	           			
  			<Prevention/>
  			<Footer/>
  			<Cookie/>
  			
      </article>
  	);
	}
}
export default Default;
import React, { Component } from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


class AnimatedBanner extends Component {

	constructor(props) {
		super(props);
	  this.state = {
	    activeSlide: 0,
	    activeSlide2: 0
	  };
	}

  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      fade: true,
      speed: 1000,
      autoplaySpeed: 6000,
      adaptiveHeight: true,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      afterChange: current => this.setState({ activeSlide2: current })      
    };

	  return (
  			<section id="banner" className={'bg' + this.state.activeSlide + " pt30"} >
  				<div className="wrap">
  				<h1>{this.props.heading}</h1>
  					<div className="slider">
          		<Slider {...settings}>
        				<div className='slide campaign01'></div>
        				<div className='slide campaign02'></div>
        				<div className='slide campaign03'></div>								
        			</Slider>
  					</div>
  				</div>
  			</section>  		  
  		)      
	}
}

export default AnimatedBanner;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Cookie from "../../components/Cookie/Cookie";
import { Link } from "react-router-dom";

import Header from "../../components/Header/ClientHeader";
import Alert from "../../components/Header/AlertBanner";
import Prevention from "../../components/Footer/Prevention";
import Footer from "../../components/Footer/Footer";
import roiReturn from "../../assets/roi-return.png";
import roiAnxiety from "../../assets/roi-anxiety.png";
import roiDepression from "../../assets/roi-depression.png";
import roiFirstAppt from "../../assets/roi-first-appt.png";
import roiFirstTimeInCare from "../../assets/roi-first-time-in-care.png";
import roiReductionInEr from "../../assets/roi-reduction-in-er.png";
import roiReductionInPatient from "../../assets/roi-reduction-in-patient.png";

class Impact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
    };
    this.scrollRef = React.createRef();
  }
  render() {
    const partner = this.props.page;
    const alert = this.props.alert ? <Alert /> : null;
    const alertClass = this.props.alert ? "show-alert" : null;

    return (
      <article className={alertClass}>
        <Helmet>
          <title>{"aptihealth"}</title>
          <meta
            name="description"
            content="Your Fast Track to Better Mental Health. Receive personalized, goal-oriented teletherapy that empowers you to make positive life changes and feel better within 90 days."
          />
        </Helmet>

        <Header partner={partner} />
        {alert}
        {/*<Banner2 page={"roi"} />*/}

        <div
          style={{
            position: "relative",
            width: "100%",
            //maxWidth: "1200px",
            height: "400px",
            display: "flex",
            alignItems: "center",
            marginTop: "77px",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "20px",
              zIndex: 10,
            }}
          >
            <h1
              style={{
                color: "#54565a",
                fontFamily: "'Barlow', sans-serif",
                fontWeight: "bold",
                fontSize: "54px",
                lineHeight: "1.2",
              }}
            >
              Life Changing Care, <br /> Proven ROI
            </h1>
          </div>
          <div className="roiHeaderImage"></div>
        </div>

        <section className="roiIntro">
          <div className="wrap">
            <div className="two-column">
              <div className="col">
                <button
                  className="btn2"
                  onClick={() => {
                    this.setState({ showForm: true });
                    this.scrollRef.current.scrollIntoView();
                  }}
                >
                  Download the report
                </button>
              </div>

              <div className="col">
                <div className="copy">
                  <h4>
                    aptihealth's Advanced Care Program delivers precise
                    interventions and the on-going support needed to improve
                    patient outcomes and reduce health care costs.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="p30">
          <div className="wrap">
            <h2
              className="subhead text-center"
              style={{ color: "#54565a", fontFamily: "'Barlow', sans-serif" }}
            >
              Better Outcomes, Increased Savings.
            </h2>
            <p
              style={{
                fontFamily: "'Helevetica Neue', sans-serif",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              With a dedicated provider network, innovative care model, and data
              driven approach – aptihealth is expanding access to high-quality
              behavioral health care and improving outcomes for even the
              highest-acuity patients.
            </p>
          </div>
          <div className="wrap">
            <h2 style={{ fontFamily: "'Barlow', sans-serif" }}>
              Improved Access
            </h2>
            <div className="two-col">
              <div className="col">
                <img
                  src={roiFirstAppt}
                  alt="First Appointment"
                  className="roiImage"
                />
              </div>
              <div className="col">
                <img
                  src={roiFirstTimeInCare}
                  alt="First Appointment"
                  className="roiImage"
                />
              </div>
            </div>
          </div>
          <div className="wrap">
            <h2 style={{ fontFamily: "'Barlow', sans-serif" }}>
              Decreased Utilization
            </h2>
            <div className="two-col">
              <div className="col">
                <img
                  src={roiReductionInPatient}
                  alt="First Appointment"
                  className="roiImage"
                />
              </div>
              <div className="col">
                <img
                  src={roiReductionInEr}
                  alt="First Appointment"
                  className="roiImage"
                />
              </div>
            </div>
          </div>
          <div className="wrap">
            <h2 style={{ fontFamily: "'Barlow', sans-serif" }}>
              Meaningful Outcomes
            </h2>
            <div className="two-col">
              <div className="col">
                <img
                  src={roiDepression}
                  alt="First Appointment"
                  className="roiImage"
                />
              </div>
              <div className="col">
                <img
                  src={roiAnxiety}
                  alt="First Appointment"
                  className="roiImage"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="pt60 grey clientPages">
          <div className="wrap">
            <div className="two-col pad">
              <div className="col">
                <h2
                  className="subhead"
                  style={{ fontFamily: "'Barlow', sans-serif" }}
                >
                  Strategic, Responsive Care - Positive Financial ROI
                </h2>
                <p
                  style={{
                    fontFamily: "'Helevetica Neue', sans-serif",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  aptihealth's collaborative, data driven approach allows for
                  early identification and intervention - significantly reducing
                  costs for health plans.
                </p>
                <button
                  className="btn2"
                  onClick={() => {
                    this.setState({ showForm: true });
                    this.scrollRef.current.scrollIntoView();
                  }}
                >
                  Learn more
                </button>
              </div>
              <div className="col">
                <img src={roiReturn} alt="ROI Return" className="roiImage" />
              </div>
            </div>
          </div>
        </section>

        <div ref={this.scrollRef}></div>
        {this.state.showForm && (
          <section className="p60">
            <div className="wrap">
              <h3>Please complete the form to download the report</h3>
              <form action="https://submit-form.com/HB9iZngRd" className="form">
                <input
                  type="hidden"
                  name="_redirect"
                  value="https://www.aptihealth.com/pdf/Advanced-Care-Program-Impact-Analysis-May-2024.pdf"
                />
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  required=""
                />
                <label for="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required=""
                />
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  placeholder="Company"
                  required=""
                />
                <button
                  type="submit"
                  className="btn2"
                  style={{ marginTop: "10px" }}
                >
                  Download
                </button>
              </form>
            </div>
          </section>
        )}

        <section className="p60 roiFooter">
          <div className="wrap">
            <div className="two-col">
              <div className="col"></div>
              <div className="col">
                <h2 className="subhead">
                  Interested in learning more about how aptihealth can help
                  support your members? Reach out.
                </h2>
                <Link className="btn2" to="/contact">
                  Contact us
                </Link>
              </div>
            </div>
          </div>
        </section>

        <Prevention />
        <Footer />
        <Cookie />
      </article>
    );
  }
}
export default Impact;

import React, { useState, useEffect } from "react";
import aptiLogoAFFU from "../../assets/apti_offered_affu.png";
import aptiLogoAlbanyMed from "../../assets/apti_amc.jpg";
import aptiLogoFidelis from "../../assets/apti_fidelis.jpg";
import aptiLogoNYSPFFA from "../../assets/apti_offered_nyspffa.png";
import aptiLogoCDPHP from "../../assets/apti_space_CDPHP_logo.svg";
import aptiLogo from "../../assets/logo-dark.png";
import aptiLogoSPHP from "../../assets/apti_sphp_logo.jpg";
import aptiSpaceMVPv2  from "../../assets/apti_space_MVP_v2.jpg";
import CTA from "../../components/CTA/CTA";

import rpiApti from "../../assets/RPI_Apti-Logo.png";
import rpiCDPHP from "../../assets/RPI_CDPHP-Logo.png";
import rpiLogo from "../../assets/RPI_RPI-Logo.png";

export default function Header(props) {
  const [animate, setAnimate] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(function () {
      setAnimate(" loaded");
    }, 1000);

    setTimeout(function () {
      setAnimate(" loaded cta");
    }, 2000);
  }, []);

  const openModal = () => {
    props.openModalHandler();
  };

  const trackEventLink = (event) => {
    console.log("TRACK");

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Register for Event",
      destination: event,
    });
  };

  const partner = props.partner ? "partner" : null;

  return (
    <header
      id="header"
      className={"client " + partner + animate + " " + props.partner}
    >
      <div className="wrap">
        {props.partner === "cdphp" ? (
          <a href="/" className={"logo partner " + props.partner}>
            <span style={{ backgroundImage: "url(" + aptiLogoCDPHP + ")" }}>
              <img src={aptiLogoCDPHP} alt="aptihealth & CDPHP" />
            </span>
          </a>
        ) : props.partner === "mvp" ? (
          <a href="/" className={"logo partner " + props.partner}>
            <span style={{ backgroundImage: "url(" + aptiSpaceMVPv2 + ")" }}>
              <img src={aptiSpaceMVPv2} alt="aptihealth & MVP" />
            </span>
          </a>
        ) : props.partner === "albanymed" ||
          props.partner === "amcemployees" ? (
          <a href="/" className={"logo partner " + props.partner}>
            <span style={{ backgroundImage: "url(" + aptiLogoAlbanyMed + ")" }}>
              <img src={aptiLogoAlbanyMed} alt="aptihealth & Albany Med" />
            </span>
          </a>
        ) : props.partner === "fidelis" ? (
          <a href="/" className={"logo partner " + props.partner}>
            <span style={{ backgroundImage: "url(" + aptiLogoFidelis + ")" }}>
              <img src={aptiLogoFidelis} alt="aptihealth & Fidelis Care" />
            </span>
          </a>
        ) : props.partner === "affu" ? (
          <a href="/" className={"logo partner " + props.partner}>
            <span style={{ backgroundImage: "url(" + aptiLogoAFFU + ")" }}>
              <img
                src={aptiLogoAFFU}
                alt="aptihealth & Albany Firefighters Union"
              />
            </span>
          </a>
        ) : props.partner === "nyspffa" ? (
          <a href="/" className={"logo partner " + props.partner}>
            <span style={{ backgroundImage: "url(" + aptiLogoNYSPFFA + ")" }}>
              <img
                src={aptiLogoNYSPFFA}
                alt="aptihealth & New York State Professional Fire Fighters Association"
              />
            </span>
          </a>
        ) : props.partner === "sphp" ? (
          <a href="/" className={"logo partner " + props.partner}>
            <span style={{ backgroundImage: "url(" + aptiLogoSPHP + ")" }}>
              <img
                src={aptiLogoSPHP}
                alt="aptihealth & St Peter's Health Partners"
              />
            </span>
          </a>
        ) : props.partner === "rpiteam" ? (
          <a href="/" className={"logo partner " + props.partner}>
            <span>
              <img src={rpiApti} alt="AptiHealth" />
            </span>
            <span>
              <img src={rpiCDPHP} alt="CDPHP" />
            </span>
            <span>
              <img src={rpiLogo} alt="CDPHP" />
            </span>
          </a>
        ) : (
          <a href="/" className="logo">
            <span style={{ backgroundImage: "url(" + aptiLogo + ")" }}>
              <img src={aptiLogo} alt="aptihealth" />
            </span>
            <strong>
              Transforming
              <br /> Behavioral Healthcare
            </strong>
          </a>
        )}
        {props.btnText ? (
          <CTA
            btnText={props.btnText}
            btnLink={props.btnLink}
            btnStyle={props.btnStyle}
          />
        ) : null}
        {props.schedule ? (
          <button className="btn alt" onClick={openModal}>
            Schedule a Demo
          </button>
        ) : null}
        {props.webinar ? (
          <button
            className="btn alt"
            data-webinarhash="w66knfo7"
            onClick={(e) => trackEventLink("Webinar 04/22")}
          >
            Register Now
          </button>
        ) : null}
      </div>
    </header>
  );
}

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Banner(props) {
  const page = props.page;
  let headline = (
    <h1>
      Your Fast Track to
      <br /> Better Mental Health
    </h1>
  );
  let settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 1200,
    autoplaySpeed: 5000,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    swipe: false,
    initialSlide: Math.floor(Math.random() * 7),
  };
  let slides = (
    <Slider {...settings}>
      <div id="bg02" className="slide"></div>
      <div id="bg01" className="slide"></div>
      <div id="bg03" className="slide"></div>
      <div id="bg06" className="slide"></div>
      <div id="bg04" className="slide"></div>
      <div id="bg05" className="slide"></div>
      <div id="bg07" className="slide"></div>
    </Slider>
  );

  switch (page) {
    case "affu":
    case "cdphp":
      headline = (
        <h1>
          Mental Health Care <br />
          for CDPHP members
        </h1>
      );
      settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 1200,
        autoplaySpeed: 5000,
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        swipe: false,
        initialSlide: Math.floor(Math.random() * 7),
      };
      slides = (
        <Slider {...settings}>
          <div id="bg02" className="slide"></div>
          <div id="bg01" className="slide"></div>
          <div id="bg03" className="slide"></div>
          <div id="bg06" className="slide"></div>
          <div id="bg04" className="slide"></div>
          <div id="bg05" className="slide"></div>
          <div id="bg07" className="slide"></div>
        </Slider>
      );
      break;
    case "mvp":
      headline = (
        <h1>
          Mental Health Care <br />
          for MVP members
        </h1>
      );
      settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 1200,
        autoplaySpeed: 5000,
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        swipe: false,
        initialSlide: Math.floor(Math.random() * 7),
      };
      slides = (
        <Slider {...settings}>
          <div id="bg02" className="slide"></div>
          <div id="bg01" className="slide"></div>
          <div id="bg03" className="slide"></div>
          <div id="bg06" className="slide"></div>
          <div id="bg04" className="slide"></div>
          <div id="bg05" className="slide"></div>
          <div id="bg07" className="slide"></div>
        </Slider>
      );
      break;
    case "nyspffa":
      headline = (
        <h1>
          Expert Care for Your
          <br /> Emotional Health
          <br /> and Wellness
        </h1>
      );
      settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 1200,
        autoplaySpeed: 5000,
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        swipe: false,
        initialSlide: Math.floor(Math.random() * 4),
      };
      slides = (
        <Slider {...settings}>
          <div id="bg02" className="slide"></div>
          <div id="bg01" className="slide"></div>
          <div id="bg03" className="slide"></div>
          <div id="bg05" className="slide"></div>
        </Slider>
      );
      break;
    case "sphp":
      headline = (
        <>
          <h1>
            Mission:
            <br /> Mental Health
          </h1>
          <h3>
            Even healthcare heroes
            <br /> need help
          </h3>
        </>
      );
      settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 1200,
        autoplaySpeed: 5000,
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        swipe: false,
        initialSlide: Math.floor(Math.random() * 4),
      };
      slides = (
        <Slider {...settings}>
          <div id="bg-hero-01" className="slide"></div>
          <div id="bg-hero-02" className="slide"></div>
          <div id="bg-hero-03" className="slide"></div>
          <div id="bg-hero-04" className="slide"></div>
        </Slider>
      );
      break;
    case "amcemployees":
      slides = false;
      break;
    default:
      break;
  }

  return (
    <section id="banner" className={"client " + page}>
      <div className="wrap">{headline}</div>
      {slides}
    </section>
  );
}

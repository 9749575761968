// Imports
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

// Components
import Header from '../../components/Header/Header';
import Testimonials from '../../components/Testimonials/Testimonials';
import Footer from '../../components/Footer/Footer';
import Modal from '../../components/Modal/Modal';
import Stats from '../../components/Stats/Stats';


// Assets
import videoSrc from'../../assets/videos/bg-home.mp4';
// import infoGraphic from'../../assets/graphic-patient-centric-v2.png';
// import infoGraphicMobile from'../../assets/svg/grphc-journey-mobile.svg';

import providers from'../../assets/svg/icn-providers-v2.svg';
import healthCare from'../../assets/svg/icn-healthplan-v2.svg';

import personalizedCare from'../../assets/svg/icon-lp-personalized-care.svg';
import therapy from'../../assets/svg/icon-lp-therapy.svg';
import medicationManagement from'../../assets/svg/icon-lp-medication.svg';
import directMessaging from'../../assets/svg/icon-lp-direct-messaging.svg';
import twentyFourSeven from'../../assets/svg/icon-lp-twenty-four-seven.svg';
import integration from'../../assets/svg/icn-med-professionals.svg';



class Home extends Component {
  
	state = {
		showModal: false,
		showCaseStudy: false,        		
	}

	openModalHandler = (overlay) => {
    if(overlay === 'cs' || this.props.cs){
		  this.setState({showCaseStudy:true});
		  if(!this.props.modal){
    		this.props.history.push('case-study/');  
		  }	  
    } else {
  		this.setState({showModal:true});
		  if(!this.props.modal){
        this.props.history.push('join/');
		  }	  
    }
	}

	closeModalHandler = () => {
		this.props.history.push('/');
		this.setState({showModal:false});
		this.setState({showCaseStudy:false});		
	}
	
	componentDidMount () {
  	console.log(this.props);
  	
    if (!document.getElementById('wistia_script')) {
      var wistiaScript = document.createElement('script');
      wistiaScript.id = 'wistia_script';
      wistiaScript.type = 'text/javascript';
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
    }
  	
		if(this.props.modal){
			this.openModalHandler();
		}
	}

  

  render() {

    return (
      <article>
				<Helmet>
					<title>aptihealth - Transforming Behavioral Healthcare</title>
					<meta name="description" content="Quickly Connecting People to Integrated Behavioral Healthcare for Results that Matter"/>
				</Helmet>
        
        <Header></Header>
        
        <section id="banner" className='home'>
          <Video autoPlay loop muted>
            <source src={videoSrc} type="video/mp4" />
          </Video>    						
        
          <div className="wrap">
            <h1>Quickly Connecting People to<br/> Integrated Behavioral Healthcare<br/> for Results that Matter</h1>
            <span className="wistia_embed wistia_async_l4n38yx07x popover=true popoverContent=link"><a href="#watch" className='btn'>Watch the Video</a></span>
          </div>
        </section>

        <section className="grey p30">
          <div className="wrap">
          <h3 className='introHead'>Personalized and Comprehensive Behavioral Healthcare</h3>
          <p className='heading'>aptihealth partners with health plans and health systems to integrate and provide access at any point of care. We deliver population-precise, child, adolescent, and adult care programs for every level of acuity—from those struggling with mild symptoms to members with severe and persistent mental illness.</p>
            <div className="three-col audiences">
              <div className="col">
                <h3>Health Plans</h3>
                <img src={healthCare} alt="Health Care"/>
                <p>Mental health places a $238 billion strain on the US healthcare system. Achieve your Triple Aim Goals with aptihealth.</p>
                <Link to='/health-plans/' className='btn sm'>Learn More</Link>                
              </div>
              <div className="col">
                <h3>Health Systems</h3>
                <img src={providers} alt="Health Systems"/>
                <p>Help put your patients into the right care, right away. Learn more about how aptihealth allows you to seamlessly integrate behavioral healthcare.</p>
                <Link to='/health-systems/' className='btn sm'>Integrate Now</Link>                
              </div>
            </div>
          </div>
        </section>
        
        <section className="p30">
          <div className="wrap">
          <h2 className='text-center'>How it Works</h2>
          <p className='headline'>aptihealth provides members ages 5 and older with a fast track to better mental health.</p>
            <div className="three-col steps">
              <div className="col support">
                <img src={personalizedCare} alt="Personalized Care"/>
                <h3>Care</h3>
                <p>All members receive a personalized, goal-oriented care plan.</p>
              </div>
              <div className="col therapy">
                <img src={therapy} alt="Therapy"/>
                <h3>Therapy</h3>
                <p>We provide convenient video therapy and easy-to-schedule online appointments.</p>
              </div>
              <div className="col">
                <img src={medicationManagement} alt="Medication Management"/>
                <h3>Medication Management</h3>
                <p>If recommended, our members receive medication management from a licensed psychiatric&nbsp;prescriber.</p>
              </div>
              <div className="col">
                <img src={directMessaging} alt="Direct Messaging"/>
                <h3>Direct Messaging</h3>
                <p>Members can direct messaging with their therapist for support between sessions.</p>
              </div>
              <div className="col">
                <img src={integration} alt="Care Coordination"/>
                <h3>Care Coordination</h3>
                <p>We coordinate our care with our members physicians or other specialist.</p>
              </div>
              <div className="col">
                <img src={twentyFourSeven} alt="24/7 Support"/>
                <h3>24/7 Support</h3>
                <p>We offer members 24/7 support services through our dedicated helpline. Anytime, anywhere, we&rsquo;re always available.</p>
              </div>
            </div>
            <div className="text-center">
              <p><em>aptihealth quickly connects people who are struggling with an expert care team dedicated to their emotional health and&nbsp;wellness.</em></p>
              <Link className="btn lg" to='/welcome/'>Get Care</Link>
            </div>
            
            
          </div>
        </section>
        
				<Testimonials slides="home" />
        <Stats heading="Driving Breakthrough Results" openModalHandler={this.openModalHandler}  />		

        
        <Modal 
          closeModal={this.closeModalHandler} 
          show={this.state.showCaseStudy} 
          hubspot='ad3fffe4-0a99-4277-9817-2d55c9d7fa25'
        >
          <h3>Sign Up for the Case Study!</h3>
        </Modal>                    
        
        
				<Footer/>

			</article>
    );
  }
}

export default Home;

import React from 'react';
import CookieConsent from "react-cookie-consent";

export default function Cookie(props) {
  return (
    <CookieConsent
      disableStyles={true}
      buttonClasses="btn btn-primary"
      containerClasses="cookieConsent"
    >
      This site uses cookies to offer you a better browsing experience. By continuing to use this website, you agree to our use of cookies.
    </CookieConsent>  
	)
}
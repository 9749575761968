// Imports
import React, { Component } from 'react';
import {Helmet} from "react-helmet";

// Components
import Header from '../../components/Header/Header';
import Banner from '../../components/Banner/Banner';
import ZendeskForm from '../../components/Zendesk/FeedbackForm';
import Footer from '../../components/Footer/Footer';


class Contact extends Component {

  render() {

    return (
      <article>
				<Helmet>
					<title>Get In Touch with aptihealth</title>
					<meta name="description" content=""/>
				</Helmet>
        
        <Header />
        <Banner page='page-contact' heading='Get In Touch'/>
        
        <section className="pt30">
          <div className="wrap">
            <div className="w60">
              <ZendeskForm
                subdomain="aptihealth"
                currentPageURL={window.location}
              />
            </div>
          </div>
        </section>
        
				<Footer/>

			</article>
    );
  }
}

export default Contact;

import React, {Component}from 'react';
import stat01 from'../../assets/svg/grph-stat2-44.svg';
import stat02 from'../../assets/svg/grph-stat2-81.svg';
import stat03 from'../../assets/svg/grph-stat2-66.svg';
import stat04 from'../../assets/svg/grph-stat2-68.svg';
import stat05 from'../../assets/svg/grph-stat2-89.svg';
import stat06 from'../../assets/svg/grph-stat2-100.svg';



class Stats extends Component {

	openModal = () => {
		this.props.openModalHandler('cs');
	}

  render() {

		return (
    <section className={'p60 ' + this.props.background}>
      <div className="wrap text-center">
        <h2>{this.props.heading}</h2>
        <p>In a six-month study across 4 clinical sites with <strong>37 physicians</strong> and <strong>75,000</strong> covered patient&nbsp;lives:</p>
        <div className="three-col stats">
          <div className="col">
            <img src={stat05} alt='89% Increase in Patient Satisfaction'/>                                    
          </div>
          <div className="col">
            <img src={stat02} alt='81% 90-day care retention'/>
          </div>
          <div className="col">
            <img src={stat03} alt='2/3 of patients completed the assessment process within 5 days.'/>
          </div>
          <div className="col">
            <img src={stat01} alt='44% decrease in symptom severity'/>
          </div>
          <div className="col">
            <img src={stat04} alt='68% reduction in emergency room visits'/>
          </div>
          <div className="col">
            <img src={stat06} alt='100% reduction in hospitalizations'/>                                    
          </div>
        </div>
        
        <p>Read the case study to learn more about these results and how aptihealth is transforming behavioral&nbsp;healthcare.</p>
        {/* <button className="btn" onClick={this.openModal}>Read the Full Case Study</button> */}
        <a href="/pdf/Aptihealth_Case_Study_08-14-2019.pdf" className='btn' target='_blank' >Read the Full Case Study</a>
      </div>        
    </section>	
		)
	}
}

export default Stats;

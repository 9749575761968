// Imports
import React, { Component } from 'react';
import {Helmet} from "react-helmet";
// import { Link } from 'react-router-dom';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';


// Components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';


class Privacy extends Component {

	componentDidMount () {
    configureAnchors({offset: -100, scrollDuration: 1000})
	}

  render() {

    return (
      <article id="single">
				<Helmet>
					<title>AptiHealth Privacy Policy</title>
					<meta name="description" content=""/>
				</Helmet>
        
        <Header />
          
        <section className="single privacy">
          <div className="wrap">
            
  <h1>Privacy Policy</h1>
<p><em>Last updated: 10/2/2023</em></p>
<p>This Privacy Policy describes the privacy practices of AptiHealth, Inc. (&ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;) and how we handle personal information that we collect through our website, platform, and mobile applications (collectively, &ldquo;the Service&rdquo; or &ldquo;Services&rdquo;). </p>
<p><strong>Note, AptiHealth is not a medical group. Any telemedicine consults obtained through our Services are provided by independent medical practitioners including, but not limited to, AptiHealth Medical, PLLC (collectively, &ldquo;AptiHealth Medical&rdquo;), an independent medical group with a network of United States based healthcare professionals. AptiHealth and AptiHealth Medical (collectively, &ldquo;we&rdquo; or &ldquo;our&rdquo;) are required by law to maintain the privacy of your health information in accordance with federal and state law and in accordance with the HIPAA Notice of Privacy Practices, available at <a href="/hipaaprivacy">https://aptihealth.com/hipaaprivacy</a>. In the event of a conflict between this Privacy Policy and the HIPAA Notice of Privacy Practices, the HIPAA Notice of Privacy Practices shall prevail. If you do not agree to be bound by those terms, you are not authorized to access or use our Services, and you must promptly exit our Services.</strong></p>
<ScrollableAnchor id={'automatic-data'}>
  <h2>Personal Information We Collect</h2>
</ScrollableAnchor>
<p>We collect personal information from patients and healthcare professionals who use our Services. These individuals may provide information about themselves (e.g., patients who use the Service) as well as about each other (e.g., physicians who provide information about patients). This information may include, but not be limited to:</p>
<ul className="disc">
<li><strong>Contact details</strong>, such as your first and last name, email and mailing addresses, phone number, username and password, and personal information in any emails that you send us.</li>
<li><strong>Biographical and demographic information</strong>, such as date of birth, age, gender, marital status, race, ethnicity, and information regarding any parents or legal guardians.</li>
<li><strong>Health and medical information</strong>, such as medical insurance details, information about physical and mental health conditions and diagnoses, treatments for medical conditions, genetic information, family medical history, medications an individual may take, and other medical and health information you choose to share with us.</li>
<li><strong>Audio and video feed</strong>, when you engage in our telehealth services. We may record your audio and video conversations for quality and training purposes, and to advance and optimize the telehealth program.</li>
<li><strong>Communications</strong> that we exchange with you, including when you contact us with questions, feedback, or otherwise.</li>
<li><strong>Payment and transactional data</strong> needed to complete your orders on or through the Service (including name, payment card information, bank account number, billing information), and your purchase history.</li>
<li><strong>Marketing data</strong>, such as your preferences for receiving our marketing communications, and details about your engagement with them.</li>
<li><strong>Informational communications</strong>, such a appointment reminders, general healthcare information, and wellness programs.</li>
<li><strong>HIPAA-related information.</strong> Under a federal law known as the Health Insurance Portability and Accountability Act (&ldquo;HIPAA&rdquo;), some of the demographic, health and/or health-related  information that we collect or process as part of the Services may be considered &ldquo;Protected Health Information&rdquo; or &ldquo;PHI.&rdquo; AptiHealth works as a &ldquo;Business Associate&rdquo; (a vendor who performs work on behalf of healthcare providers and health plans for the purpose of payment, treatment, or healthcare operations, with whom the healthcare provider or health plan has entered into a separate agreement that specifically indicates how we will protect patient information) to AptiHealth Medical and other healthcare providers and health plans. When AptiHealth receives identifiable information about an individual from or on behalf of a heathcare provider, this information is PHI. HIPAA provides specific protections for the privacy and security of PHI and restricts how PHI is used and disclosed.  As a Business Associate, AptiHealth may only use and disclose PHI in the ways permitted by healthcare providers that we are performing work for. </li>
</ul>
<p>We, our partners, and our service providers may automatically log information about you, your computer or mobile device, and your interaction over time with the Service, our communications, and other online services, such as:</p>
<ul className="disc">
<li><strong>Device data</strong>, such as your computer’s or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising purposes), language settings, mobile device carrier, radio/network information (e.g., WiFi, LTE, 3G).</li>
<li><strong>Location information</strong>, such as city, state, or geographic area.</li>
<li><strong>Online activity data</strong>, such as pages or screens you viewed, how long you spent on a page or screen, the website you visited before browsing to the Service, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access, and whether you have opened our marketing emails or clicked links within them.</li>
<li><strong>Cookies</strong>, which are text files that websites store on a visitor’s device to uniquely identify the visitor’s browser or to store information or settings in the browser for the purpose of helping you navigate between pages efficiently, remembering your preferences, enabling functionality, helping us understand user activity and patterns, and facilitating online advertising.</li>
<li><strong>Local storage technologies</strong>, like HTML5, that provide cookie-equivalent functionality but can store larger amounts of data, including on your device outside of your browser in connection with specific applications.</li>
<li><strong>Web beacons</strong>, also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage or email was accessed or opened, or that certain content was viewed or clicked.</li>
<li><strong>Analytics:</strong> We use Google Analytics, a web analytics service provided by Google, Inc. (&ldquo;Google&rdquo;). Google Analytics uses cookies to help us analyze how users use the Site and enhance your experience when you use the Site. For more information on how Google uses this information, click <a href='http://www.google.com/policies/privacy/partners/' target='_blank' rel="noopener noreferrer">here</a>.</li>
</ul>
<h2>How We Use Your Personal Information</h2>
<p>We use your personal information for the following purposes or as otherwise described at the time of collection:</p>
<p><strong>Service delivery.</strong> We use your personal information to:</p>
<ul className="disc">
<li>provide, operate, and improve the Services, including facilitating your interaction with your therapist and prescriber;</li>
<li>process your payments and complete transactions with you;</li>
<li>communicate with you about the Services, including by sending announcements, updates, security alerts, and support and administrative messages;</li>
<li>understand your needs and interests, and personalize your experience with the Services and our communications; and</li>
<li>provide support for the Services, and respond to your requests, questions, and feedback.</li>
<li><strong>Research and development.</strong> We may use your personal information for research and development purposes, including to analyze and improve the Service and for public health initiatives. As part of these activities, we may create aggregated, de-identified, or other anonymous data from personal information we collect. We make personal information into anonymous or de-identified data by removing data components (such as your name, email address, or other data elements) that make the data personally identifiable to you or through obfuscation or other means. Our use of anonymized or de-identified data is not subject to this Privacy Policy and we may use this anonymous or de-identified data and share it with third parties for any lawful business purpose, including, but not limited to, analyzing and improving the Service and promoting our business. </li>
</ul>
<p><strong>Marketing and advertising.</strong> We, our partners, and our service providers may collect and use some of your personal information for marketing and advertising purposes: </p>
<ul className="disc">
<li><strong>Direct marketing.</strong> We may send you direct marketing communications as permitted by law, including, but not limited to, notifying you of special promotions, offers, and events via postal mail, email, telephone, text message, and other means. You may opt out of our marketing communications as described in the &ldquo;<a href='#online-tracking-optout'>Online tracking opt out</a>&rdquo; section below.</li>
<li><strong>Interest-based advertising.</strong> We and our partners, including third party advertising companies and  social media companies, may display ads on the Service and other online services. These companies may use cookies and similar technologies to collect information about your interactions (including the data described in the &ldquo;<a href="#automatic-data">Automatic data collection</a>&rdquo; section above) over time across the Service, our communications, and other online services, and use that information to serve online ads that they think will interest you. This is called interest-based advertising. We may also share information about our users with these companies to facilitate interest-based advertising to those or similar users on other online platforms. You can learn more about your choices for limiting interest-based advertising in the &ldquo;<a href='#online-tracking-optout'>Online tracking opt out</a>&rdquo; section below.</li>
</ul>

<p><strong>Compliance and protection.</strong> We may use your personal information to:</p>
<ul className="disc">
<li>comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities;</li>
<li>protect our, your, or others’ rights, privacy, safety, or property (including by making and defending legal claims);</li>
<li>audit our internal processes for compliance with legal and contractual requirements and internal policies;</li>
<li>enforce the terms and conditions that govern the Service; and</li>
<li>prevent, identify, investigate, and deter fraudulent, harmful, unauthorized, unethical, or illegal activity, including cyberattacks and identity theft.</li>
</ul>

<h2>How We Share Your Personal Information</h2>
<p>We do not rent or sell your personal information, including your PHI. However, we may disclose your personal information to third parties as described below and as otherwise described in this Privacy Policy or at the time of collection:</p>
<p><strong>Affiliates.</strong> Our subsidiaries and affiliates, for purposes consistent with this Privacy Policy.</p>
<p><strong>Advertising partners.</strong> Third-party advertising companies, including for the interest-based advertising purposes described above.</p>
<p><strong>Healthcare partners.</strong> The healthcare providers and pharmacies with whom we partner to provide our telehealth services and to fulfill your prescription orders, including through Professional Medical Concierge Services, PLLC. </p>
<p><strong>Service providers.</strong> Companies and individuals that provide services on our behalf or help us operate the Service or our business (such as coordinating orders and prescription fulfillment from pharmacy partners, hosting, information technology, payment processing, customer support, email delivery, and website analytics services).</p>
<p><strong>Professional advisors.</strong> Professional advisors, such as lawyers, auditors, bankers, and insurers, where necessary in the course of the professional services that they render to us.</p>
<p><strong>Authorities and others.</strong> Law enforcement, government authorities, and private parties, as we believe in good faith to be necessary or appropriate for the compliance and protection purposes described above.</p>
<p><strong>Business transferees.</strong> Acquirers and other relevant participants in business transactions (or negotiations for such transactions) involving a corporate divestiture, merger, consolidation, acquisition, reorganization, sale, or other disposition of all or any portion of the business or assets of, or equity interests in, AptiHealth or our affiliates (including, in connection with a bankruptcy or similar proceedings).</p>
<p>Please keep in mind that whenever you voluntarily make your personal information available for viewing by third parties or the public on or through our Service, that information can be seen, collected, and used by others. We are not responsible for any use of such information by others.</p>

<h2>Your Choices</h2>
<h3>Accessing, Correcting, or Deleting Your Information</h3>
<p>You can review and change your personal information by logging on to our Services and visiting your &ldquo;Settings &gt; Profile&rdquo; page. You may contact us at the address below to request access to, correct, or delete any personal information that you have provided to us. In addition to the deletion of your personal information, you may also contact us at the address below to request that we delete your account. If AptiHealth deletes your user account, healthcare professionals and other affiliates may still have the right to retain information as required by applicable law, regulations, or their own retention policy. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect. Unless otherwise required by law, AptiHealth will also erase personal information when the personal information is no longer necessary in relation to the purposes for which it was collected or otherwise processed; when you withdraw your consent (where lawfulness of processing was based on your consent) and there is no other legal ground for the processing; when you object to the processing and there are no overriding legitimate grounds for the processing; when your personal information has been unlawfully processed; and when it is necessary to comply with legal obligations.</p>
<p>With respect to any PHI AptiHealth may obtain, you have certain rights under the HIPAA Notice of Privacy Practices.</p>
<h3>Exercising Access and Deletion Rights</h3>
<p>To exercise the access and deletion rights described above, please submit a verifiable consumer request to us by either: </p>
<ul className="disc">
<li>Calling us at (888) 454-3827</li>
<li>Emailing us at <a href="mailto:support@aptihealth.com">support@aptihealth.com</a></li>
</ul>
<p>Only you may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child. You may only make a verifiable consumer request for access twice within a 12-month period. The verifiable consumer request must:</p>
<ul className="disc">
<li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative. </li>
<li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
</ul>
<p><strong>Opt out of marketing communications.</strong> If you do not wish to receive additional commercial emails from us, simply click the unsubscribe link and follow the instructions to unsubscribe your email address. If you have unsubscribed but continue to receive email from us, you may report this to us by emailing us at <a href="mailto:privacy@aptihealth.com">privacy@aptihealth.com</a>. Please note that unsubscribe requests may take up to 7 – 10 days to process. You will have the ability to opt out of any marketing or advertising communications, but we may still send account related communications for purposes important to the Services, such as password recovery, or other issues relevant to your account.</p>
<ScrollableAnchor id={'online-tracking-optout'}>
  <p><strong>Online tracking opt out</strong> There are a number of ways to opt out of having your online activity and device data collected through the Service, which we have summarized below:</p>
</ScrollableAnchor>
<ul className="disc">
<li><strong>Blocking cookies in your browser.</strong> Most browsers let you remove or reject cookies, including cookies used for interest-based advertising. To do this, follow the instructions in your browser settings. Many browsers accept cookies by default until you change your settings. For more information about cookies, including how to see what cookies have been set on your device and how to manage and delete them, visit <a href='https://www.allaboutcookies.org' target='_blank' rel="noopener noreferrer">www.allaboutcookies.org</a>.</li>
<li><strong>Blocking advertising ID use in your mobile settings.</strong> Your mobile device settings may provide functionality to limit use of the advertising ID associated with your mobile device for interest-based advertising purposes.</li>
<li><strong>Using privacy plug-ins or browsers.</strong> You can block our websites from setting cookies used for interest-based ads by using a browser with privacy features, like <a href='https://brave.com/' target='_blank' rel="noopener noreferrer">Brave</a>, or installing browser plugins like <a href='https://www.eff.org/privacybadger' target='_blank' rel="noopener noreferrer">Privacy Badger</a>, <a href='https://www.ghostery.com/' target='_blank' rel="noopener noreferrer">Ghostery</a>, or <a href='https://ublock.org/' target='_blank' rel="noopener noreferrer">uBlock Origin</a>, and configuring them to block third party cookies/trackers. You can also opt out of Google Analytics by downloading and installing the browser plug-in available at: <a href='https://tools.google.com/dlpage/gaoptout' target='_blank' rel="noopener noreferrer">tools.google.com/dlpage/gaoptout</a>.</li>
<li>
  <strong>Platform opt outs.</strong> The following advertising partners offer opt-out features that let you opt out of use of your information for interest-based advertising:
  <ul className='sublist'>
    <li>Google: <a href='https://adssettings.google.com' target='_blank' rel="noopener noreferrer">adssettings.google.com</a></li>
    <li>Facebook: <a href='https://www.facebook.com/about/ads' target='_blank' rel="noopener noreferrer">www.facebook.com/about/ads</a></li>
    <li>Twitter: <a href='https://twitter.com/settings/personalization' target='_blank' rel="noopener noreferrer">twitter.com/settings/personalization</a></li>
  </ul>
</li>
<li>
  <strong>Advertising industry opt-out tools.</strong> You can also use these opt-out options to limit use of your information for interest-based advertising by participating companies:
  <ul className='sublist'>
    <li>Digital Advertising Alliance for Websites: <a href='https://optout.aboutads.info' target='_blank' rel="noopener noreferrer">optout.aboutads.info</a></li>
    <li>Digital Advertising Alliance for Mobile Apps: <a href='https://www.youradchoices.com/appchoices' target='_blank' rel="noopener noreferrer">www.youradchoices.com/appchoices</a></li>
    <li>Network Advertising Initiative: <a href='https://optout.networkadvertising.org/' target='_blank' rel="noopener noreferrer">optout.networkadvertising.org/</a></li>
  </ul>
</li>
</ul>
<p>Note that these opt out mechanisms are specific to the device or browser on which they are exercised, so you will need to opt out on every browser and device that you use. We are not responsible for the privacy practices of these third parties, and the information practices of these third parties are not covered by this Privacy Policy.</p>

<p><strong>Do Not Track.</strong> Some Internet browsers may be configured to send &ldquo;Do Not Track&rdquo; signals to the online services that you visit. We currently do not respond to "Do Not Track" or similar signals. To find out more about "Do Not Track," please visit <a href='https://www.allaboutdnt.com' target='_blank' rel="noopener noreferrer">www.allaboutdnt.com</a>.</p>
<p><strong>Declining to provide information.</strong> We need to collect personal information to provide certain services. If you do not provide the information we identify as required or mandatory, we may not be able to provide those services.</p>

<h2>Other Sites and Services</h2>
<p>The Service may contain links to websites and other online services operated by third parties. In addition, our content may be integrated into web pages or other online services that are not associated with us. These links and integrations are not an endorsement of, or representation that we are affiliated with, any third party. We do not control websites or online services operated by third parties, and we are not responsible for their actions.</p>

<h2>Security</h2>
<p>We employ a number of technical, organizational, and physical safeguards designed to protect the personal information we collect. However, no security measures are failsafe and we cannot guarantee the security of your personal information.</p>

<h2>Use by Minors</h2>
<p>Our Services are not intended for children under the age of eighteen (18) without parental consent. If you are under 18 years of age, we must obtain consent by your parent or legal guardian to this Privacy Policy and our End-User License Agreement and your parent must create an account before you can create your account to use or access our Services. Once we obtain consent and the accounts are created, your subsequent use of the Services must be done under the supervision of your parent or legal guardian. If you are a parent or legal guardian of a minor child under 18 years of age, you may create an account with AptiHealth, submit your and your minor child’s personal information, and agree to this Privacy Policy and the End-User License Agreement on your and your minor child’s behalf. Any information you provide us on behalf of your minor child will be treated in accordance with this Privacy Policy. We do not knowingly collect information for individuals under the age of 18 for whom we have not obtained adequate consent pursuant to the above. If we learn that we have received any information for an individual under the age of 18 for whom we have not obtained adequate consent pursuant to the above, we will only use that information to respond directly to that child (or a parent or legal guardian) to inform them that they cannot use our Service, and subsequently we will delete that information from our own servers. If you believe we have impermissibly collected personal information from someone under the age of 18, please contact us using the information below.</p>

<h2>Job Applicants</h2>
<p>When you visit the &ldquo;Careers&rdquo; portion of our website, we collect the information that you provide to us in connection with your job application. This includes business and personal contact information, professional credentials and skills, educational and work history, and other information of the type that may be included in a resume. This may also include diversity information that you voluntarily provide. We use this information to facilitate our recruitment activities and process employment applications, such as by evaluating a job candidate for an employment activity, and monitoring recruitment statistics.</p>

<h2>Changes to This Privacy Policy</h2>
<p>We may update this Privacy Policy from time to time. When we update the Privacy Policy, we will revise the &ldquo;Effective Date&rdquo; date above and post the new Privacy Policy. We recommend that you review the Privacy Policy each time you visit the Services to stay informed of our privacy practices. In all cases, your use of the Service after the effective date of any modified Privacy Policy indicates your acceptance of the modified Privacy Policy.</p>

<h2>How to Contact Us</h2>
<p>You can reach us by email at <a href='mailto:privacy@aptihealth.com'>privacy@aptihealth.com</a> or at the following mailing address:</p>
<p>AptiHealth, Inc<br/>
340 Broadway, Suite 7<br/>
Saratoga Springs, NY 12866
</p>
</div>
</section>

                                    
				<Footer/>

			</article>
    );
  }
}

export default Privacy;
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import $ from 'jquery';

import Header from '../../components/Header/ClientHeader';
// import CTA from '../../components/CTA/CTA';
import Prevention from '../../components/Footer/Prevention';
import Footer from '../../components/Footer/Footer';
import WistiaEmbed from '../../components/WistiaEmbed/WistiaEmbed';


// import benita from '../../assets/profile-benita-zahn.jpg';
import vanessa from'../../assets/profile-vanessa-bobb.jpg';
import alex from'../../assets/team-alex.jpg';
import james from'../../assets/profile-james.jpg';
// import cal from'../../assets/svg/icon-calendar.svg';
// import clock from'../../assets/svg/icon-clock.svg';



class Event extends Component {

  componentDidMount() {
    
    const script = document.createElement("script");

    script.src = "https://event.webinarjam.com/register/w66knfo7/embed-button";
    script.async = true;

    document.body.appendChild(script);
    
    
    $(document).ready(function(){
      $('.toggle').click(function(){
        var member = $(this).data('bio');
        if($(this).hasClass('open')){
          $('#'+member).removeClass('openMember');
          $(this).removeClass('open');
        } else {
          $('#'+member).addClass('openMember');
          $(this).addClass('open');
        }
      });
    });
  }  
  
  trackEventLink = (event) => {
    console.log('TRACK');
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event' : 'Register for Event',
      'destination' : event
    });

	}   

  render() {	 
    return (
      <article>
  			<Helmet>
  				<title>aptihealth Webinar: Supporting Parents&rsquo; and Teachers&rsquo; Mental Health</title>
  				<meta name="description" content='Please join us on Thursday, April 22 at 7:00 PM ET for practical information and actionable tips for managing stress, burnout, and accessing care.' />
  			</Helmet>
        
        <Header partner='cdphp' btnText='Get Care' btnStyle='alt' btnLink='/welcome' />

        <section className='event'>  						
          <div className='wrap'>
            <div className='two-col pad'>
              <div className='col w40'>
                <h1>Webinar: Supporting Parents&rsquo; and Teachers&rsquo; Mental Health</h1>
                <h5>Thank you for joining Benita Zahn and our panel of healthcare experts to learn practical information and actionable tips for managing stress, burnout, and accessing&nbsp;care. </h5>
                <h5>A recording of the webinar is available if you would like to watch it again, or were unable to join us. </h5>
            
              </div>
              <div className='col w60'>
                <WistiaEmbed hashedId='7rkpvq08xh' playerColor='#0CD0A7' />
              </div>
            </div>              
          </div>
        </section>
        
        <section className="pt30">
          <div className='wrap'>
            <div className='two-col pad divide'>
              <div className='col'>
                <p>The COVID-19 pandemic has upended our education system and, subsequently, created a physically and emotionally draining situation for both teachers and parents. More than 40 percent of U.S. adults are reporting symptoms of anxiety and depression. Nearly 30 percent of teachers nationally are reporting that the pandemic has made them more likely to leave teaching or retire early.</p>
                <p>This free webinar was sponsored by <strong>CDPHP</strong> and <strong>aptihealth</strong>.</p>
              </div>
              <div className='col'>
                <p><strong>Benita Zahn</strong> moderates this panel of health experts to discuss:</p>                              
                <ul className="checks">
                  <li>Practical information and actionable tips for managing stress and avoiding burnout</li>
                  <li>Signs and symptoms that may indicate it&rsquo;s time to seek professional care</li>
                  <li>Ways to access personalized and comprehensive mental healthcare – easily and quickly</li>
                </ul>
              </div>
            </div>                
          </div>
        </section>
        
        <section className="panelists">
          <div className="wrap divide">
          
            <h2>Panelists</h2>
          
            <div className="three-col team">

              <div className="col">
                <div id="vanessa" className="member">
                  <button className="toggle" data-bio='vanessa'><span>Read Bio</span></button>                
                  <div className="hold">
                    <img src={vanessa} alt='Dr. Vanessa Bobb'/>
                    <h3>Vanessa Bobb, MD PhD FAPA <span>CDPHP, Behavioral Health Medical Director, Children&rsquo;s Services</span></h3>
                    <div className="bio">
                      <div>
                        <p>Vanessa Toney Bobb MD, PhD, FAPA, joined CDPHP in July 2019 and serves as behavioral health medical director of children&rsquo;s services. In this role, Dr. Bobb oversees the unique behavioral health needs of CDPHP members 21 years of age and younger. In addition to oversight of commercial and Medicaid lines of business, Dr. Bobb directs the Medicaid managed care children&rsquo;s system implementation, and co-chairs the children&rsquo;s advisory committee.</p>
                        <p>Dr. Bobb has vast clinical experience within the Columbia and Cornell University Hospital systems, the New York State Office of Mental Health, the Administration for Children&rsquo;s Services (ACS), and within child welfare organizations. Dr. Bobb has treated and provided consultation for youth and adults with complex psychiatric comorbidities. Dr. Bobb has presented at local, national, and international conferences, and developed workshops on topics related to mental health and culture, self-esteem in minority youth, early onset schizophrenia, cognitive behavioral therapy, and HIV prevention.</p>
                        <p>Board certified in child and adolescent psychiatry and adult psychiatry, Dr. Bobb completed child, adolescent, and adult residency training at New York Presbyterian Hospital. Dr. Bobb completed a public psychiatry fellowship at Columbia University, and she received an MD/PhD from Brown University. She has served as clinical faculty to the child and adolescent psychiatry fellowship at SUNY Downstate Medical Center, and has lectured at Columbia University public psychiatry fellowship program, New York Presbyterian child and adolescent psychiatry program, and the University of Hawaii at Hilo.</p>
                        <p>Dr. Bobb is a Fellow of the American Psychiatric Association. She was a 2019 peer reviewer for the National Institutes of Health Child Health and Human Development, Scientific Review Branch, and is a past executive committee member of New York Public Psychiatry. Dr. Bobb was also previously recognized as a Castle Connolly top doctor.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div id="alex" className="member">
                  <button className="toggle" data-bio='alex'><span>Read Bio</span></button>                
                  <div className="hold">
                    <img src={alex} alt='Alex Marsal, PhD'/>
                    <h3>Alex Marsal, PhD <span>Co-Founder, Chief Clinical &amp; Science Officer</span></h3>
                    <div className="bio">
                      <div>
                        <p>Dr. Alex Marsal is a co-founder and the Chief Clinical and Science Officer of aptihealth. He also founded The Recovery Institute and Vanguard Behavioral Solutions aimed at innovating and field-testing patient self-assessments, therapeutic relationship building, and person centered, multidimensional behavioral health treatment. His leadership experience includes 10 years of hospital-based behavioral health administration which resulted in care delivery transformations yielding system efficiencies, improved outcomes, and greater patient satisfaction.</p>
                        <p>With over 30 years of behavioral health experience, Alex&rsquo;s expertise includes providing psychotherapy services to populations with dual-diagnosis, depressive and anxiety disorders, marital difficulties, and PTSD/abusive experiences. His research focus includes the assessment and treatment of high cost, high need populations. Alex is a member of the American Psychological Association and National Register of Health Service Providers in Psychology.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div id="james" className="member">
                  <button className="toggle" data-bio='james'><span>Read Bio</span></button>                
                  <div className="hold">
                    <img src={james} alt='James P. Aram, MD'/>
                    <h3>James P. Aram, MD <span>Brunswick Family Practice</span></h3>
                    <div className="bio">
                      <div>
                        <p>Dr. Aram received a Bachelor of Science degree from St. Lawrence University, a Master of Science degree from Rensselaer Polytechnic Institute, and medical degree from the University of Texas at San Antonio School of Medicine.</p>
                        <p>He is board certified in Family Medicine and Geriatrics and is an attending physician at Samaritan Hospital and St. Mary’s Hospital in Troy. Over the years, he has served on numerous hospital committees and held various leadership positions on the Medical-Dental Staffs of both hospitals. Dr. Aram is an Associate Clinical Professor in the Department of Family Medicine at Albany Medical College and serves as a community preceptor for medical students attending the Medical School.</p>
                        <p>Dr. Aram’s practice encompasses all phases of general medical care, without regard to age, sex, or disease state. The practice offers chronic care management, as well as urgent care needs in a comprehensive fashion, promoting health maintenance and wellness in keeping with the current philosophy incorporated in the Patient Centered Medical Home model.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                        
          </div>  
          </div>
        </section>      
  			
  			<section className="pt60 grey clientPages">
          <div className="wrap">
            <div className="two-col pad">
              <div className="col">
                <h3>About CDPHP<sup>&reg;</sup></h3>
                <p>Established in 1984, CDPHP is a physician-founded, member-focused and community-based not-for-profit health plan that offers high-quality affordable health insurance plans to members in 26 counties throughout New York. CDPHP is also on <a href="https://www.facebook.com/CDPHP/" target="_blank" rel="noopener noreferrer">Facebook</a>, <a href="https://twitter.com/CDPHP" target="_blank" rel="noopener noreferrer">Twitter</a>, <a href="https://www.linkedin.com/company/cdphp/" target="_blank" rel="noopener noreferrer">LinkedIn</a>, and <a href="https://www.instagram.com/cdphp/" target="_blank" rel="noopener noreferrer">Instagram</a>.</p>
              </div>
              <div className="col">
                <h3>About aptihealth</h3>
                <p>aptihealth, inc. is a behavioral health engagement company that uses technology to seamlessly integrate physical and behavioral healthcare. Its groundbreaking platform connects medical providers, behavioral health specialists, behavioral health prescribers, and patients with its proprietary screening, assessment, and treatment management protocols. aptihealth&rsquo;s digital platform provides a seamless and effective approach to virtual, intelligent integrated care that helps patients get better faster and stay better longer. aptihealth provides those in need of behavioral healthcare with a fast-track to better mental health.</p>
              </div>
            </div>
            
          </div>        
        </section>
        
    	  <Prevention/> 
    	  
        <section id="cta" className="p30 dark lg">
          <div className="wrap">          
            <h3>For fast access to an expert care team dedicated to your emotional health and wellness, <strong>get started with aptihealth today</strong>. Learn more about our personalized, goal-oriented teletherapy that helps you feel better within 90 days.</h3>
            <Link className="btn" to="/welcome">Get Started</Link>
          </div>        
        </section>  
    	     	           			
  			
  			<Footer/>
  
      </article>
  	);
	}
}
export default Event;
import React from "react";
import ReactDOM from "react-dom";
import "./main.scss";
import App from "./containers/App";
import { unregister } from "./registerServiceWorker";
import LogRocket from "logrocket";

ReactDOM.render(<App />, document.getElementById("apti"));
unregister();

LogRocket.init("sbdwkl/aptihealth-poc", {
  rootHostname: "aptihealth.com",
});

import React from 'react';

const banner = (props) => {
	return (
		<section id="banner" className={'secondary '+ props.page}>
			<div className="wrap">
				{props.eyebrow ? <h3>{props.eyebrow}</h3>: null}
				<h1>{props.heading}</h1>
			</div>						
		</section>
	)
}

export default banner;
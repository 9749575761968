// Imports
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Collapse from "@kunukn/react-collapse";
import cx from "classnames/bind";

// Components
import Modal from "../../components/Modal/Modal";
import Footer from "../../components/Footer/Footer";
import Banner from "../../components/Banner/Banner";
import Header from "../../components/Header/Header";

class Demo extends Component {
  state = {
    showModal: false,
    index: 1,
    showCaseStudy: false,
  };

  onToggle = (index) =>
    this.setState((state) => ({ index: state.index === index ? null : index }));

  openModalHandler = (overlay) => {
    if (overlay === "cs" || this.props.cs) {
      this.setState({ showCaseStudy: true });
      if (!this.props.modal) {
        this.props.history.push("case-study/");
      }
    } else {
      this.setState({ showModal: true });
      if (!this.props.modal) {
        this.props.history.push("integrate/");
      }
    }
  };

  closeModalHandler = () => {
    this.props.history.push("/health-systems/primary-care/");
    this.setState({ showModal: false });
    this.setState({ showCaseStudy: false });
  };

  componentDidMount() {
    if (this.props.modal) {
      this.openModalHandler();
    }
  }

  render() {
    const alertClass = this.props.alert ? "show-alert" : null;

    return (
      <article className={alertClass}>
        <Helmet>
          <title>aptihealth Platform Demo</title>
          <meta name="description" content="" />
        </Helmet>
        <Header />
        <Banner page="page-primary" heading="aptihealth Platform Demo Videos" />

        <section id="" className="p30">
          <div className="wrap">
            <h5>
              The following videos showcase the aptihealth platform including
              referral capabilities, patient registration, assessments, virtual
              guided sessions, and much more. All demos are shown in test
              environments with test data.
            </h5>
          </div>
        </section>

        <section id="accordion" className="p30 laptop">
          <div className="wrap">
            <div className="accordion">
              <ul>
                <li className={cx("item", { active: this.state.index === 1 })}>
                  <h4 onClick={() => this.onToggle(1)}>
                    <span>Partner Referral Path</span>
                  </h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 1}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {(collapseState) => (
                      <div className={"app__content " + collapseState}>
                        <p>
                          This video demonstrates the process of referring a
                          patient through one of our primary referral paths: the
                          partner referral. You'll see a referring partner log
                          in, refer a patient, and get a glimpse of the
                          information available to referring partners
                          post-referral. Watch the video{" "}
                          <a
                            href="https://www.loom.com/share/0016f0ff6eec45558f243c610c72c85e"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            here
                          </a>{" "}
                        </p>
                      </div>
                    )}
                  </Collapse>
                </li>

                <li className={cx("item", { active: this.state.index === 2 })}>
                  <h4 onClick={() => this.onToggle(2)}>Public Referral Path</h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 2}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {(collapseState) => (
                      <div className={"app__content " + collapseState}>
                        <p>
                          This video demonstrates the process of referring a
                          patient through one of our primary referral paths: the
                          public referral. Unlike a partner referral, you'll see
                          an individual navigate to our website, refer a patient
                          directly, and schedule their first appointment. Watch
                          the video{" "}
                          <a
                            href="https://www.loom.com/share/afe6a8ec3fb1488eaa50565bb38cca37?sid=67e788ad-84fc-4503-bc7f-1f7637955fd1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            here
                          </a>{" "}
                        </p>
                      </div>
                    )}
                  </Collapse>
                </li>

                <li className={cx("item", { active: this.state.index === 3 })}>
                  <h4 onClick={() => this.onToggle(3)}>
                    Patient Registration from Referral
                  </h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 3}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {(collapseState) => (
                      <div className={"app__content " + collapseState}>
                        <p>
                          This video demonstrates the patient registration
                          process for patients who have been referred to
                          aptihealth by a provider. Similar to our Self
                          Registration, you'll watch a patient enter their
                          information and complete registration. Watch the video{" "}
                          <a
                            href="https://www.loom.com/share/92205a33227741ec9826a75fad656d7d?sid=7df1570e-7bf6-40cc-8cd3-8b8373310ffb"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            here
                          </a>{" "}
                        </p>
                      </div>
                    )}
                  </Collapse>
                </li>

                <li className={cx("item", { active: this.state.index === 4 })}>
                  <h4 onClick={() => this.onToggle(4)}>Self Registration</h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 4}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {(collapseState) => (
                      <div className={"app__content " + collapseState}>
                        <p>
                          This video demonstrates a patient signing up for
                          aptihealth from our website. You'll see the patient
                          enter their information, verify their insurance, and
                          sign in for the first time. Watch the video{" "}
                          <a
                            href="https://www.loom.com/share/1daf0b4a997b409188ed80222e638074?sid=a6bd0300-46b0-43e4-9e71-b1ca52159f9d"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            here
                          </a>{" "}
                        </p>
                      </div>
                    )}
                  </Collapse>
                </li>

                <li className={cx("item", { active: this.state.index === 5 })}>
                  <h4 onClick={() => this.onToggle(5)}>
                    Completing an A5 and scheduling first appointment
                  </h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 5}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {(collapseState) => (
                      <div className={"app__content " + collapseState}>
                        <p>
                          This video demonstrates the initial steps of the
                          patient journey after registration: completing the A5
                          screening and scheduling an initial appointment, the
                          Diagnostic Interview. Watch the video{" "}
                          <a
                            href="https://www.loom.com/share/07afc197947b4839bfd09e603679b9e0?sid=f9f06713-aed7-45e1-b597-2d91a390648b"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            here
                          </a>{" "}
                        </p>
                      </div>
                    )}
                  </Collapse>
                </li>

                <li className={cx("item", { active: this.state.index === 6 })}>
                  <h4 onClick={() => this.onToggle(6)}>
                    Therapy Session (Patient POV)
                  </h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 6}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {(collapseState) => (
                      <div className={"app__content " + collapseState}>
                        <p>
                          This video demonstrates the patient experience of
                          attending a therapy session. You'll see the patient
                          join the session, answer waiting room questions,
                          connect with the provider, leave the session, and rate
                          their experience afterward. Watch the video{" "}
                          <a
                            href="https://www.loom.com/share/5dd79d9cb15340cc9e7ea4fa05d7de8d"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            here
                          </a>{" "}
                        </p>
                      </div>
                    )}
                  </Collapse>
                </li>

                <li className={cx("item", { active: this.state.index === 7 })}>
                  <h4 onClick={() => this.onToggle(7)}>
                    Guided Therapy Session (Provider POV)
                  </h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 7}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {(collapseState) => (
                      <div className={"app__content " + collapseState}>
                        <p>
                          This video demonstrates a provider's perspective
                          conducting a therapy guided session. You'll see a
                          provider review the previous session note and connect
                          to the call, record notes during the call, review a
                          draft note, and sign/submit the note. Watch the video{" "}
                          <a
                            href="https://www.loom.com/share/97f0a8afb8654aa5bd99d811b3262028"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            here
                          </a>{" "}
                        </p>
                      </div>
                    )}
                  </Collapse>
                </li>

                <li className={cx("item", { active: this.state.index === 8 })}>
                  <h4 onClick={() => this.onToggle(8)}>
                    Secure In-App Messaging
                  </h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 8}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {(collapseState) => (
                      <div className={"app__content " + collapseState}>
                        <p>
                          This video demonstrates a patient's perspective using
                          secure in-app messaging to communicate with their care
                          team between sessions. You'll see a patient manage
                          messages with their therapist, prescriber, case
                          manager, and peer specialist. Watch the video{" "}
                          <a
                            href="https://www.loom.com/share/c0bd3f5f00cd46828f450f32e980af06"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            here
                          </a>{" "}
                        </p>
                      </div>
                    )}
                  </Collapse>
                </li>
                <li className={cx("item", { active: this.state.index === 9 })}>
                  <h4 onClick={() => this.onToggle(9)}>
                    Patient Rescreening (A5R)
                  </h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 9}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {(collapseState) => (
                      <div className={"app__content " + collapseState}>
                        <p>
                          This video demonstrates a patient rescreen. You'll
                          watch the patient complete the A5R and get a look at
                          the questions asked every 90 days. Watch the video{" "}
                          <a
                            href="https://www.loom.com/share/42122c4b988c41ba94339e331abed3b3"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            here
                          </a>{" "}
                        </p>
                      </div>
                    )}
                  </Collapse>
                </li>
                <li className={cx("item", { active: this.state.index === 21 })}>
                  <h4 onClick={() => this.onToggle(21)}>
                    Data Platform and Analytics
                  </h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 21}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {(collapseState) => (
                      <div className={"app__content " + collapseState}>
                        <p>
                          This video showcases the capabilities of aptihealth's
                          Data Platform and analytics. Watch the video{" "}
                          <a
                            href="https://www.loom.com/share/61c4767ca86541b2883e7003d9322dfa?sid=da506372-2201-4fca-9f76-d9e99d1c82e3"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            here
                          </a>{" "}
                        </p>
                      </div>
                    )}
                  </Collapse>
                </li>
                <li className={cx("item", { active: this.state.index === 22 })}>
                  <h4 onClick={() => this.onToggle(22)}>
                    Administrative Features
                  </h4>
                  <Collapse
                    className="collapse"
                    isOpen={this.state.index === 22}
                    onChange={({ state }) => {
                      this.setState({ item1: state });
                    }}
                    onInit={({ state }) => {
                      this.setState({ item1: state });
                    }}
                  >
                    {(collapseState) => (
                      <div className={"app__content " + collapseState}>
                        <p>
                          Watch the video{" "}
                          <a
                            href="https://www.loom.com/share/eeebf1cc0d4445d89f11e29d921ae792?sid=1fc4159e-16bb-468c-bbfb-471a924528cb"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            here
                          </a>{" "}
                        </p>
                      </div>
                    )}
                  </Collapse>
                </li>
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "30px",
              }}
            >
              {this.state.index === 1 && (
                <>
                  <div>
                    <a
                      href="https://www.loom.com/share/0016f0ff6eec45558f243c610c72c85e"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Partner Referral Path - Watch Video</p>
                    </a>
                    <a
                      href="https://www.loom.com/share/0016f0ff6eec45558f243c610c72c85e"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ maxWidth: "500px" }}
                        src="img/platform-demo.png"
                        alt="Partner Referral Path"
                      />
                    </a>
                  </div>
                </>
              )}
              {this.state.index === 2 && (
                <>
                  <div>
                    <a
                      href="https://www.loom.com/share/afe6a8ec3fb1488eaa50565bb38cca37"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Public Referral Path - Watch Video</p>
                    </a>
                    <a
                      href="https://www.loom.com/share/afe6a8ec3fb1488eaa50565bb38cca37"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ maxWidth: "500px" }}
                        src="img/platform-demo.png"
                        alt="Public Referral Path"
                      />
                    </a>
                  </div>
                </>
              )}
              {this.state.index === 3 && (
                <>
                  <div>
                    <a
                      href="https://www.loom.com/share/92205a33227741ec9826a75fad656d7d"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Patient Registration from Referral - Watch Video</p>
                    </a>
                    <a
                      href="https://www.loom.com/share/92205a33227741ec9826a75fad656d7d"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ maxWidth: "500px" }}
                        src="img/platform-demo.png"
                        alt="Patient Registration from Referral"
                      />
                    </a>
                  </div>
                </>
              )}
              {this.state.index === 4 && (
                <>
                  <div>
                    <a
                      href="https://www.loom.com/share/1daf0b4a997b409188ed80222e638074"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Self Registration - Watch Video</p>
                    </a>
                    <a
                      href="https://www.loom.com/share/1daf0b4a997b409188ed80222e638074"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ maxWidth: "500px" }}
                        src="img/platform-demo.png"
                        alt="Self Registration"
                      />
                    </a>
                  </div>
                </>
              )}
              {this.state.index === 5 && (
                <>
                  <div>
                    <a
                      href="https://www.loom.com/share/07afc197947b4839bfd09e603679b9e0?sid=7472f4e1-7919-4728-acfa-ae2464b616b7"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>
                        Completing an A5 and scheduling first appointment -
                        Watch Video
                      </p>
                    </a>
                    <a
                      href="https://www.loom.com/share/07afc197947b4839bfd09e603679b9e0?sid=7472f4e1-7919-4728-acfa-ae2464b616b7"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ maxWidth: "500px" }}
                        src="img/platform-demo.png"
                        alt="Completing an A5 and scheduling first appointment"
                      />
                    </a>
                  </div>
                </>
              )}
              {this.state.index === 6 && (
                <>
                  <div>
                    <a
                      href="https://www.loom.com/share/5dd79d9cb15340cc9e7ea4fa05d7de8d"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Therapy Session (Patient POV) - Watch Video</p>
                    </a>
                    <a
                      href="https://www.loom.com/share/5dd79d9cb15340cc9e7ea4fa05d7de8d"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ maxWidth: "500px" }}
                        src="img/platform-demo.png"
                        alt="Therapy Session (Patient POV)"
                      />
                    </a>
                  </div>
                </>
              )}
              {this.state.index === 7 && (
                <>
                  <div>
                    <a
                      href="https://www.loom.com/share/97f0a8afb8654aa5bd99d811b3262028"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Guided Therapy Session (Provider POV) - Watch Video</p>
                    </a>
                    <a
                      href="https://www.loom.com/share/97f0a8afb8654aa5bd99d811b3262028"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ maxWidth: "500px" }}
                        src="img/platform-demo.png"
                        alt="Guided Therapy Session (Provider POV)"
                      />
                    </a>
                  </div>
                </>
              )}
              {this.state.index === 8 && (
                <>
                  <div>
                    <a
                      href="https://www.loom.com/share/c0bd3f5f00cd46828f450f32e980af06"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Secure In-App Messaging - Watch Video</p>
                    </a>
                    <a
                      href="https://www.loom.com/share/c0bd3f5f00cd46828f450f32e980af06"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ maxWidth: "500px" }}
                        src="img/platform-demo.png"
                        alt="Secure In-App Messaging"
                      />
                    </a>
                  </div>
                </>
              )}
              {this.state.index === 9 && (
                <>
                  <div>
                    <a
                      href="https://www.loom.com/share/42122c4b988c41ba94339e331abed3b3"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Patient Rescreening (A5R) - Watch Video</p>
                    </a>
                    <a
                      href="https://www.loom.com/share/42122c4b988c41ba94339e331abed3b3"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ maxWidth: "500px" }}
                        src="img/platform-demo.png"
                        alt="Patient Rescreening (A5R)"
                      />
                    </a>
                  </div>
                </>
              )}
              {this.state.index === 21 && (
                <>
                  <div>
                    <a
                      href="https://www.loom.com/share/61c4767ca86541b2883e7003d9322dfa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Data Platform and Analytics - Watch Video</p>
                    </a>
                    <a
                      href="https://www.loom.com/share/61c4767ca86541b2883e7003d9322dfa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ maxWidth: "500px" }}
                        src="img/platform-demo.png"
                        alt="Data Platform and Analytics"
                      />
                    </a>
                  </div>
                </>
              )}
              {this.state.index === 22 && (
                <>
                  <div>
                    <a
                      href="https://www.loom.com/share/eeebf1cc0d4445d89f11e29d921ae792"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>Administrative Features - Watch Video</p>
                    </a>
                    <a
                      href="https://www.loom.com/share/eeebf1cc0d4445d89f11e29d921ae792"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ maxWidth: "500px" }}
                        src="img/platform-demo.png"
                        alt="Administrative Features"
                      />
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>

        <section id="cta" className="p30 dark">
          <div className="wrap sm">
            <h3>
              Interested in how aptihealth provides fast, virtual-first access
              to behavioral healthcare for children, teens, and adults?{" "}
              <strong>Click Learn More and we'll be in touch</strong>.
            </h3>
            <Link className="btn" to="/contact">
              Learn More
            </Link>
            {/* <Link className="btn" onClick={this.openModalHandler}>Learn More</Link> */}
          </div>
        </section>

        <Modal
          closeModal={this.closeModalHandler}
          show={this.state.showModal}
          hubspot="aa07c68d-62fc-4e3a-986c-75d4538201f1"
        >
          <h3>Learn More About aptihealth</h3>
        </Modal>

        <Modal
          closeModal={this.closeModalHandler}
          show={this.state.showCaseStudy}
          hubspot="ad3fffe4-0a99-4277-9817-2d55c9d7fa25"
        >
          <h3>Sign Up for the Case Study!</h3>
        </Modal>

        <Footer />
      </article>
    );
  }
}

export default Demo;

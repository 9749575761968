import React from 'react';
import WistiaEmbed from '../../components/WistiaEmbed/WistiaEmbed';

const intro = (props) => {
	return (
    <section id={props.bg === 'campaign' ? 'introduction' : ''} className={'intro '+ props.bg + ' ' + (props.video || props.photo ? '' : 'full')}>  						
      <div className="wrap">
        {props.bg === 'campaign' ? <h3>Access a deeper connection to do the best work of your&nbsp;life</h3> : null }
        <div className="copy">
          {props.children}
        </div>
        {props.photo ? <img src={props.photo} alt=''/> : null}
        {props.video ? <div className="vid"><WistiaEmbed hashedId={props.video} playerColor='#0CD0A7' /></div> : null}
      </div>
    </section>
	)
}

export default intro;
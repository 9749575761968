import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import data from "./data"; 

// Components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import WistiaEmbed from '../../components/WistiaEmbed/WistiaEmbed';
import ReactPlayer from 'react-player';


class Post extends Component {
  
  state = {
    articleID: null,
    articleData: {},
    articleContent: null,
    glensFallsAbout: false,
    cmhAbout: false,
    cdphpAbout: false,
    cdphpPhoto: false,
    ellisAbout: false,
    pandionAbout: false,
    pandionTitle: false,
    seriesB:false,
    sphpAbout: false,
    wmhd:false,
    mhm23: false,
    ptsd: false
  }  
  
  componentDidMount () {
    const article = this.props.match.params.article;
    this.setState({articleID: article});
    console.log(this.props.match.params.article);
    
    const selectedArticle = data.Posts.find(function (obj) { return obj.Id === article });
    console.log(selectedArticle);
    
    if(selectedArticle){
    
      this.setState({articleData: selectedArticle});
      
      const selectedArticleContent = selectedArticle['Content'] ? selectedArticle['Content'].map((anObjectMapped, index) => {
        return (
           <p key={index} dangerouslySetInnerHTML={{ __html: `${anObjectMapped.paragraph}`}} />
        );
      }) : null;
      this.setState({articleContent: selectedArticleContent});
      console.log(selectedArticleContent);

      const selectedArticleVideo = selectedArticle['VideoUrl'] ? <div className='player-wrapper'><ReactPlayer className='react-player' url={selectedArticle['VideoUrl']} width='100%' height='100%' controls /></div> : null;
      this.setState({articleVideo: selectedArticleVideo});      
      
      
      // Conditional for Glens Falls about
      if(this.props.match.params.article === 'virtual-behavioral-healthcare-glens-falls-hospital'){
        this.setState({glensFallsAbout: true});
      }
  
      // CDPHP Announcment
      if(this.props.match.params.article === 'partnership-with-cdphp'){
        this.setState({cdphpAbout: true, cdphpPhoto: true});
      }

      // CDPHP Webinar
      if(this.props.match.params.article === 'benita-zahn-hosts-mental-health-panel-discussion'){
        this.setState({cdphpAbout: true, cdphpWebinar: true });
      }
      
      // Conditional for CMH About
      if(this.props.match.params.article === 'partnership-with-columbia-memorial-health'){
        this.setState({cmhAbout: true});
      }
      
      // Ellis
      if(this.props.match.params.article === 'ellis-medicine-and-aptihealth'){
        this.setState({ellisAbout: true});
      }    
      
      // Pandion
      if(this.props.match.params.article === 'pandion-and-aptihealth'){
        this.setState({pandionAbout: true, pandionTitle: true});
      }  
      
      // Series B
      if(this.props.match.params.article === 'aptihealth-raises-series-b-funding'){
        this.setState({seriesB: true});
      } 
      
      // Series B
      if(this.props.match.params.article === 'saint-peters-and-aptihealth'){
        this.setState({sphpAbout: true});
      } 
      
      // WMHD
      if(this.props.match.params.article === 'world-mental-health-day-2022'){
        this.setState({wmhd: true});
      } 

      // MHM
      if(this.props.match.params.article === 'mental-health-month-2023'){
        this.setState({mhm23: true});
      } 

      // PTSD
      if(this.props.match.params.article === 'ptsd'){
        this.setState({ptsd: true});
      }       
      
    } else {
		  this.props.history.push('/news/');
    }
    
  }  

  render() {
    
    const currentArticle = this.state.articleData;
    const currentArticleContent = this.state.articleContent;
    const currentArticleVideo = this.state.articleVideo;
       
    const cmhAboutContent = this.state.cmhAbout ? (
      <div>
        <h4>About Columbia Memorial Health</h4>
        <p>Columbia Memorial Health is an advanced multi-specialty healthcare system serving more than 100,000 residents in Columbia, Greene and Dutchess counties at more than 35 primary care and specialty care centers. In addition, CMH’s inpatient hospital, located in Hudson, has served our region since 1893 and is one of the most innovative and technically advanced facilities in the Hudson Valley. Columbia Memorial Health, Albany Med, Saratoga Hospital and Glens Falls Hospital are part of the Albany Med Health System, the largest locally governed health system in the region. Learn more at <a href="https://www.columbiamemorialhealth.org" target="_blank" rel="noopener noreferrer">www.columbiamemorialhealth.org</a>.</p>
      </div>
    ) : null;
    
    const glensFallsAboutContent = this.state.glensFallsAbout ? (
      <div>
        <h4>About Glens Falls Hospital</h4>
        <p>Glens Falls Hospital (GFH) maintains a service area that spans 6,000 square miles across 5 diverse counties. Founded in 1897, GFH today operates an advanced health care delivery system featuring more than 20 regional facilities. A vast array of specialized medical and surgical services are provided in addition to coronary care, behavioral health care, rehabilitation and wellness and others. The main hospital campus is home to the C.R. Wood Cancer Center, the Joyce Stock Snuggery birthing center, the Breast Center and a chronic wound healing center. GFH is a not-for-profit organization and the largest employer in New York&rsquo;s Adirondack region, with over 2,500 employees and a medical staff of over 540 providers. For more information visit <a href="https://www.glensfallshospital.org" target="_blank" rel="noopener noreferrer">www.GlensFallsHospital.org</a>.</p>
        <h4>About the Albany Med Health System</h4>
        <p>Glens Falls Hospital is an affiliate of the Albany Med Health System, which also includes Albany Medical Center, Columbia Memorial Health and Saratoga Hospital. The region&rsquo;s largest locally governed health system, it has 1,520 beds, more than 800 physicians and 125 outpatient locations throughout the Capital Region. </p>
      </div>
    ) : null;
    

    const cdphpAbout = this.state.cdphpAbout ? (
      <div>
        <h4>About CDPHP<sup>&reg;</sup></h4>
        <p>Established in 1984, CDPHP is a physician-founded, member-focused and community-based not-for-profit health plan that offers high-quality affordable health insurance plans to members in 26 counties throughout New York. CDPHP is also on <a href="https://www.facebook.com/CDPHP/" target="_blank" rel="noopener noreferrer">Facebook</a>, <a href="https://twitter.com/CDPHP" target="_blank" rel="noopener noreferrer">Twitter</a>, <a href="https://www.linkedin.com/company/cdphp/" target="_blank" rel="noopener noreferrer">LinkedIn</a>, and <a href="https://www.instagram.com/cdphp/" target="_blank" rel="noopener noreferrer">Instagram</a>.</p>
      </div>
    ) : null;


    const cdphpPhoto = this.state.cdphpPhoto ? (
      <figure className='cdphpPhoto'>
        <img src="/img/dr-john-bennett-and-dan-pickett.jpg" alt="Dr. John Bennett and Dan Pickett having coffee" />
        <caption>Dan Pickett, CEO of aptihealth, has coffee with Dr. John Bennett, president and CEO of CDPHP, at Halfmoon Diner in Clifton Park, NY while talking about meeting the mental health care needs of the community. </caption>
      </figure>
    ) : null;
    
    const ellisAbout = this.state.ellisAbout ? (
      <div>
        <h4>About Ellis Medicine</h4>
        <p><a href="http://www.ellismedicine.org/" target="_blank" rel="noopener noreferrer">Ellis Medicine</a> is a 438-bed community and teaching healthcare system serving New York’s Capital Region. With five campuses — Ellis Hospital, McClellan Street Health Center, Bellevue Woman’s Center, Mohawk Harbor and the Medical Center of Clifton Park — and five additional service locations, 3,000 employees and more than 700 members of its medical staff, Ellis Medicine is proud to provide a lifetime of care for patients. Ellis offers an extensive array of inpatient and outpatient services — including cardiac, cancer, emergency, mental health, and women&rsquo;s services.</p>
      </div>
    ) : null;
    
    const pandionAbout = this.state.pandionAbout ? (
      <div>
        <h4>About Pandion Optimization Alliance Companies</h4>
        <p>Pandion Optimization Alliance is a <a href="https://www.pandionalliance.com/core-services/sourcing-and-purchasing/what-is-a-gpo" target="_blank" rel="noopener noreferrer">Group Purchasing Organization (GPO)</a> and Supply Chain Consulting Company. As a pioneer in the group purchasing and supply chain industries, our reputation is built on excellence dating back to 1946. Our vast experiences help us provide access to best-in-class contracts at the local, regional, and national levels for virtually every product or service — from medical supplies to food and beverages, office and cleaning supplies, capital equipment, and more. As a progressive player Pandion has positioned itself as a resource for digital health contracts and in the fast-growing e-commerce marketplace. For more information visit, <a href="http://www.pandionalliance.com/" target="_blank" rel="noopener noreferrer">www.pandionalliance.com</a>.</p>
      </div>
    ) : null;
    
    const wmhdDisclaimers = this.state.wmhd ? (
      <ol className='wmhd-disc'>
        <li><a href='https://www.who.int/campaigns/world-mental-health-day/2022' target='_blank'>https://www.who.int/campaigns/world-mental-health-day/2022</a></li>
        <li><a href='https://www.weforum.org/agenda/2018/05/depression-prevents-many-of-us-from-leading-healthy-and-productive-lives-being-the-no-1-cause-of-ill-health-and-disability-worldwide' target='_blank'>https://www.weforum.org/agenda/2018/05/depression-prevents-many-of-us-from-leading-healthy-and-productive-lives-being-the-no-1-cause-of-ill-health-and-disability-worldwide</a></li>
        <li><a href='https://clubhouse-intl.org/our-impact/about-mental-illness/?gclid=EAIaIQobChMItJ6Yxui_-gIVRQJMCh37UwShEAAYAyAAEgKnG_D_BwE' target='_blank'>https://clubhouse-intl.org/our-impact/about-mental-illness/?gclid=EAIaIQobChMItJ6Yxui_-gIVRQJMCh37UwShEAAYAyAAEgKnG_D_BwE</a></li>
        <li><a href='https://clubhouse-intl.org/wp-content/uploads/2021/07/Clubhouse-International-Infographic-Update_07-23-21.pdf' target='_blank'>https://clubhouse-intl.org/wp-content/uploads/2021/07/Clubhouse-International-Infographic-Update_07-23-21.pdf</a></li>
      </ol> 
    ) : null;    
    
    const pandionTitle = this.state.pandionTitle ? ', Improving Provider Access to Patient-Centered Mental Health Services In New York State' : null;            
    
    const cdphpWebinar = this.state.cdphpWebinar ? (
      <>
        <div className='row first'>
          <h4>WHO:</h4>
          <div>
            <h5>Moderator</h5>
            <ul className='disc first'>
              <li><strong>Benita Zahn, DPC</strong>, <em>Anchor / Reporter WNYT – WNYA</em></li>
            </ul>
            <h5>Panelists</h5>
            <ul className='disc'>
              <li><strong>Vanessa Bobb, MD PhD FAPA</strong>, <em>CDPHP, Behavioral Health Medical Director, Children&rsquo;s Services</em></li>
              <li><strong>Alex Marsal, PhD</strong>, <em>aptihealth, Co-founder, Chief Clinical and Science Officer</em></li>
              <li><strong>James P. Aram, MD</strong>, <em>Brunswick Family Practice</em></li>
            </ul>
          </div>
        </div>
        <div className='row'>        
          <h4>WHAT: </h4>
          <div><p><strong>Webinar: Supporting Parents&rsquo; and Teachers&rsquo; Mental Health</strong></p></div>
        </div>
        
        <div className='row'>        
          <h4>WHERE: </h4>
          <div><p>via webinar, access link emailed upon registration</p></div>
        </div>
        
        <div className='row'>        
          <h4>WHEN: </h4>
          <div>
            <p><strong>Thursday, May 13, 2021</strong><br/>
            7:00 – 8:00 PM panel discussion followed by Q&A </p>
          </div>
        </div>
        
        <div className='row'>        
          <h4>COST: </h4>
          <div><p>Sponsored by CDPHP and aptihealth, the session is free to participants</p></div>
        </div>
        
        <div className='newsVideo'>
          <h2>Webinar Replay</h2>
          <p>To learn practical information and actionable tips for managing stress, burnout, and accessing care a recording of the webinar is&nbsp;available.</p>
          <WistiaEmbed hashedId='7rkpvq08xh' playerColor='#0CD0A7' />
        </div>
        
      </>
    ) : null;

    
    const plural = this.state.glensFallsAbout ? 's' : null;

    const glensFallsMedia = this.state.glensFallsAbout ? (
      <p>
        <strong>Raymond Agnew</strong><br/>
        Glens Falls Hospital<br/>
        518-926-1000<br/>
        <a href="mailto:ragnew@glensfallshosp.org">ragnew@glensfallshosp.org</a>
      </p>
    ) : null;


    const mhm23 = this.state.mhm23 ? (
      <figure className='cdphpPhoto'>
        <a href="/pdf/MHM_2023_Poster_Calendar.pdf" target="_blank"><img src="/img/MHM_2023_Poster_Calendar.jpg" alt="Mental Health Month 2023" /></a>
        <caption>Download the Mental Health Month PDF</caption>
      </figure>
    ) : null;

    const ptsd = this.state.ptsd ? (
      <div>
      <h2>What is PTSD?</h2>
      <p>Post-Traumatic Stress Disorder (PTSD) is a mental health condition that can develop in anyone who has experienced or witnessed a traumatic event. Common symptoms of PTSD include:</p>
      <ul class="disc">
      <li>Intrusive memories or flashbacks of the traumatic event.</li>
      <li>Avoidance of situations, places, or people that trigger distressing memories.</li>
      <li>Negative changes in thoughts and mood, such as feeling numb or detached.</li>
      <li>Increased anxiety, irritability, and difficulty sleeping.</li>
      <li>Hyper-vigilance and exaggerated startle response.</li>
      </ul>
      <h2>When should you seek help for PTSD?</h2>
      <p>Many people will experience a traumatic event at some point in their lifetime and it’s normal to have symptoms in the days and weeks after the event. But when symptoms persist for months and are interfering with your daily life and well-being, it’s important to seek help. You should consider reaching out to your doctor or a mental health provider if you are experiencing: </p>
      <ul class="disc">
      <li>Ongoing distressing memories or nightmares related to the traumatic event.</li>
      <li>Intense anxiety or panic attacks triggered by reminders of the trauma.</li>
      <li>Difficulty functioning at work, in relationships, or in social settings.</li>
      <li>Feelings of depression, hopelessness, or thoughts of self-harm.</li>
      <li>Substance abuse or other harmful coping mechanisms.</li>
      </ul>
      <h2>Are PTSD treatments effective?</h2>
      <p>You don’t have to live with PTSD symptoms forever. There are many effective treatment options for PTSD that can help reduce or get rid of PTSD symptoms entirely. </p>
      <p>Such treatment options include several cognitive base therapies as well as two FDA approved medications.  </p>
      <p>It's important to work with a qualified mental health professional who can tailor your treatment to your individual needs and support your journey towards recovery.</p>
      <p>If you or someone you know is struggling with PTSD, remember that you are not alone, and it is never too late to ask for help. Start today by registering at aptihealth.com/welcome. Sign up in minutes and start care in days.  </p>
      
      <ol class="wmhd-disc">
      <li>National Center for PTSD. (2023). What is PTSD?  www.ptsd.va.gov.</li>
      <li>National Institute of Mental Health. (2023). Post-Traumatic Stress Disorder. www.nimh.nih.gov.</li>
      </ol>
      </div>
      
    ) : null;    

    const cdphpShareImg01 = this.state.cdphpAbout ? <meta property="og:image" content="https://www.aptihealth.com/img/aptihealth-cdphp-share.jpg" />  : null;
    const cdphpShareImg02 = this.state.cdphpAbout ? <meta property="og:image:url" content="https://www.aptihealth.com/img/aptihealth-cdphp-share.jpg" />  : null;
    const cdphpShareImg03 = this.state.cdphpAbout ? <meta property="og:image:secure_url" content="https://www.aptihealth.com/img/aptihealth-cdphp-share.jpg" />  : null;
    
    let currentArticleTitle;
    if (this.state.seriesB) { 
      currentArticleTitle = 'aptihealth Raises $50 Million Series B to Scale Higher Acuity Behavioral Healthcare Services';
    } else if(this.state.sphpAbout) {
      currentArticleTitle = 'St. Peter’s Health Partners and aptihealth Enter Into Agreement Aimed at Addressing Growing Behavioral Health Needs for Colleagues and Community';
    } else {
      currentArticleTitle = currentArticle['Title'];
    }

    const currentArticleSubhead = currentArticle['Subhead'] ? <h3 dangerouslySetInnerHTML={{ __html: currentArticle['Subhead']}}></h3> : null;

  
    const {seriesB} = this.state;

    const sphpAbout = this.state.sphpAbout ? (
      <div>
        <h4>About St. Peters Health Partners</h4>
        <p>With nearly 11,000 employees in more than 170 locations, St. Peter&rsquo;s Health Partners is one of the Capital Region&rsquo;s largest and most comprehensive not-for-profit integrated health care networks, and provides high-quality, compassionate, and sophisticated care to thousands of people every day. St. Peter&rsquo;s Health Partners is a member of Trinity Health, one of the nation&rsquo;s largest Catholic health systems. For more information, please visit <a href="https://www.sphp.com" target="_blank" rel="noopener noreferrer">www.SPHP.com</a>.</p>
      </div>
    ) : null;    
    
    return (
      <article id="single">
				<Helmet>
					<title>{currentArticleTitle}</title>
					<meta name="description" content={currentArticle['Subhead']}/>
          <meta property="og:title" content={currentArticleTitle} />
          <meta property="og:url" content={window.location.href} />
          {cdphpShareImg01}
          {cdphpShareImg02}
          {cdphpShareImg03}
				</Helmet>
        
        <Header />

        <section id={currentArticle['Id']} className="single post">
          <div className="wrap">
            <span className="date">{currentArticle['Date']}</span>
            <h1>{currentArticleTitle}{pandionTitle}</h1>
            {currentArticleSubhead}
            <div>
              {mhm23}
              {ptsd}
              {cdphpPhoto}
              {currentArticleVideo}              
              {currentArticleContent}              
              {cdphpWebinar}
              {wmhdDisclaimers}
              <p><Link to="/news/" className="btn back"><span>Back to all News</span></Link></p>
            </div>                  
          </div>
        </section>

        <section className="  grey post single">
          <div className="wrap">
            {cdphpAbout}
            {glensFallsAboutContent}
            {cmhAboutContent}
            {ellisAbout}
            {pandionAbout}
            {sphpAbout}
            {seriesB ? 
              <div>
              <h4>About aptihealth</h4> 
              <p>aptihealth is improving higher acuity behavioral healthcare for populations who need it most—one patient at a time. The company’s virtual-first model and proprietary screening, assessment, and treatment programs give members fast, convenient access to precise, personalized care. Headquartered in Saratoga Springs and employing over 200, aptihealth has raised over $65 million in funding from leading international private equity firms. The company’s care program and data insights are driving breakthroughs in mental health understanding, treatment, outcomes, and cost reduction. Learn more at: <a href="/">www.aptihealth.com</a>.</p>             
              <h4>About Takeda Digital Ventures</h4> 
              <p>Takeda Digital Ventures (TDV) is the digital health venture arm of Takeda, a global, values-based, R&D-driven biopharmaceutical leader headquartered in Japan, committed to discovering and delivering life-transforming treatments, guided by a commitment to patients, people and the planet. Takeda Digital Ventures invests in companies that are using digital technologies to improve patient outcomes and improve delivery of care in areas that contribute to Takeda&rsquo;s data and digital transformation. Learn more at: <a href="https://www.takeda.com/tdv" target="_blank" rel="noopener noreferrer">www.takeda.com/tdv</a>.</p>              
              </div> :
            <div>            
            <h4>About aptihealth</h4>
            <p>aptihealth is improving higher acuity behavioral healthcare for populations who need it most—one patient at a time. The company’s virtual-first model and proprietary screening, assessment, and treatment programs give members fast, convenient access to precise, personalized care. Headquartered in Saratoga Springs and employing over 200, aptihealth has raised over $65 million in funding from leading international private equity firms. The company’s care program and data insights are driving breakthroughs in mental health understanding, treatment, outcomes, and cost reduction. Learn more at: <a href="/">www.aptihealth.com</a>.</p>             
            <p>&copy;2023 aptihealth, inc. All rights reserved. aptihealth, the aptihealth logo, and other aptihealth marks are trademarks of aptihealth, inc. in the United States and/or other countries. Other company names, product names, and logos may be trademarks of the respective companies with which they&rsquo;re associated.</p>
            </div>}
            
            <h4>Media Contact{plural}</h4>
            {glensFallsMedia}
            <p>
              <strong>Amanda Conway</strong><br/>
              <a href="mailto:aconway@aptihealth.com">aconway@aptihealth.com</a><br/>
            </p>
          </div>
        </section>
        
        <Footer /> 
               
      </article>
		)
		
	}
}
export default Post;
import React, { Component } from "react";
import PropTypes from "prop-types";
import NumberFormat from 'react-number-format';
import { sendFeedback, formatPayload } from "./FeedbackFormAPI";

// The SuccessMessage replaces the form if the API succesfully returns
const SuccessMessage = () => <div className="p40"><h3>Thanks for your inquiry!</h3><p>A team member will follow up within the next few business days. </p></div>;

// The ErrorMessage is displayed at the top of the form if API returns error
const ErrorMessage = () => (
  <p className="error">Sorry, we could not process your feedback right now.</p>
);

class FeedbackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      type: '',
      feedback: '',
      formErrors: {},
      submitError: false,
      isLoading: false,
      isSubmitted: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }  

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitError: false });

    let formResults = {
      'first_name': this.state.first_name,
      'last_name': this.state.last_name,
      'email': this.state.email,
      'phone': this.state.phone,
      'type': this.state.type,
      'feedback': this.state.feedback
    };

    // If fields are valid, set state to loading and make API POST request
    const errors = this.validateForm(formResults);
    const isDisabled = Object.keys(errors).some(x => errors[x]);

    if (!isDisabled) {    
      this.setState({ isLoading: true });
      const payload = formatPayload(
        this.props.feedbackSubject,
        this.state.email,
        this.state.first_name + ' ' + this.state.last_name,
        this.state.phone,
        this.state.type,
        this.state.feedback,
        this.props.currentPageURL
      );
      const response = await sendFeedback(payload, this.props.subdomain);

      // If we dont get a success response, set submitError to true to show error message
      if (!response.request) {
        this.setState({ isLoading: false, submitError: true });
        return;
      }

      // If we get a success response, set isSubmitted to true to show the success message
      this.setState({ isLoading: false, isSubmitted: true });
      
    } else {
      this.setState({formErrors: errors});
    }
  }


  validateForm = (formResults) => {
    let formErrors = {};

    if (formResults.first_name.length < 2) {
      formErrors.first_name = 'Required';
    }

    if (formResults.last_name.length < 2) {
      formErrors.last_name = 'Required';
    }
    if (formResults.feedback.length < 2) {
      formErrors.feedback = 'Required';
    }

    if (!formResults.phone || formResults.phone.length < 10) {
      formErrors.phone = 'Required';
    } else if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(formResults.phone)) {
      formErrors.phone = 'Invalid phone number';
    }

    if (!formResults.email) {
      formErrors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formResults.email)) {
      formErrors.email = 'Invalid email address';
    }

    if (!formResults.type) {
      formErrors.type = 'Required';
    }
    return formErrors;
  }


  render() {
    if (this.state.isSubmitted) {
      return (
        <div role="alert" aria-live="polite" aria-atomic="true">
          <SuccessMessage />
        </div>
      );
    } else {
      return (
        <form onSubmit={this.handleSubmit} className="form">
          <div role="alert" aria-live="polite" aria-atomic="true">
            {this.state.submitError && <ErrorMessage />}
          </div>
          <p className="required">All fields are required</p>
          <p className="name">
            <label>Name</label>
            <span>
              <input
                name='first_name'
                id='first_name'
                type='text'
                placeholder='First'
                className={'first_name ' + (this.state.formErrors.first_name ? 'error' : '')}
                value={this.state.first_name}
                onChange={this.handleInputChange} />
            </span>
            <span>
              <input
                name='last_name'
                id='last_name'
                type='text'
                placeholder='Last'
                className={'last_name ' + (this.state.formErrors.last_name ? 'error' : '')}
                value={this.state.last_name}
                onChange={this.handleInputChange} />
            </span>
          </p>

          <p>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="text"
              name="email"
              placeholder="example@email.com"
              className={'email ' + (this.state.formErrors.email ? 'error' : '')}
              value={this.state.email}
              onChange={this.handleInputChange}
            />
          </p>

          <p>
            <label>Phone</label>
            <NumberFormat
              format='(###) ###-####'
              mask='_'
              name='phone'
              id='phoneNum'
              type='tel'
              placeholder='(XXX) XXX-XXXX'
              className={'phone ' + (this.state.formErrors.phone ? 'error' : '')}
              value={this.state.phone}
              onChange={this.handleInputChange}
            />
          </p>

          <p>
            <label>
              I'm a (choose one):
              <select 
                id="type" 
                name="type" 
                value={this.state.type} 
                onChange={this.handleInputChange} 
                className={'phone ' + (this.state.formErrors.phone ? 'error' : '')}
              >
                <option value="">--</option>
                <option value="Current Patient">Current Patient</option>
                <option value="Potential Patient">Potential Patient</option>
                <option value="Health System">Health System</option>
                <option value="Health Plan">Health Plan</option>
                <option value="Primary Care Physician">Primary Care Physician</option>
                <option value="Other">Other</option>              
              </select>
            </label>            
          </p>

          <p>
            <label htmlFor="feedback">Message</label>
            <textarea
              id="feedback"
              name="feedback"
              rows="8"
              value={this.state.feedback}
              className={'feedback ' + (this.state.formErrors.feedback ? 'error' : '')}
              onChange={this.handleInputChange}
              error={this.state.feedbackError}
            />
          </p>

          <button type="submit" className="btn">
            {this.state.isLoading ? "Submitting feedback" : "Contact Us"}
          </button>
        </form>
      );
    }
  }
}

FeedbackForm.defaultProps = {
  feedbackSubject: "Contact Form Submission",
};

FeedbackForm.propTypes = {
  subdomain: PropTypes.string,
  feedbackSubject: PropTypes.string,
  currentPageURL: PropTypes.object
};

export default FeedbackForm;

// Imports
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import data from "./data"; 

// Components
import Header from '../../components/Header/Header';
import Banner from '../../components/Banner/Banner';
import Article from '../../components/Article/Article';
import Footer from '../../components/Footer/Footer';
import Modal from '../../components/Modal/Modal';


class News extends Component {
  
	state = {
		showCaseStudy: false,        		
	}

	openModalHandler = () => {
    this.setState({showCaseStudy:true});
    if(!this.props.modal){
      this.props.history.push('case-study/');  
    }
	}

	closeModalHandler = () => {
		this.props.history.push('/news/');
		this.setState({showCaseStudy:false});		
	}  

  componentDidMount () {
		if(this.props.modal){
			this.openModalHandler();
		}
	}

  render() {

    return (
      <article id="company">
		<Helmet>
			<title>aptihealth In The News</title>
		</Helmet>
        
        <Header />
        <Banner page='page-news' heading='In The News' eyebrow="Resources"/>

        <section className="p30">
          <div className="wrap">
          
            <div className="news">
            
              {
                data.Posts.map((post, index) => {
                  return (
                    <Article
                      key={post.Id}
                      figure={post.Photo}
                      link={post.Id}
                      title={post.Title}
                      date={post.Date}
                    />
                  );
                })
              } 

            </div>
                                        
          </div>
        </section>
        
        <section id="cta" className="p30 dark">
          <div className="wrap sm">
            <h3>Learn how aptihealth is<br/> <strong>Transforming Behavioral Healthcare</strong>.</h3>
            <a href="/pdf/Aptihealth_Case_Study_08-14-2019.pdf" className='btn' target='_blank' >Read the Case Study</a>
            {/* <Link className="btn" onClick={this.openModalHandler}>Read the Case Study</Link> */}
          </div>        
        </section>

        <Modal 
          closeModal={this.closeModalHandler} 
          show={this.state.showCaseStudy} 
          hubspot='ad3fffe4-0a99-4277-9817-2d55c9d7fa25'
        >
          <h3>Sign Up for the Case Study!</h3>
        </Modal>         
                        
				<Footer/>

			</article>
    );
  }
}

export default News;

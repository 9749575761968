import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Cookie from "../../components/Cookie/Cookie";

import Header from '../../components/Header/ClientHeader';
import Footer from '../../components/Footer/Footer';
import CTA from '../../components/CTA/CTA'
import Modal from '../../components/Modal/Modal';
import Stats from '../../components/Stats/Stats';

import supportPatients from'../../assets/svg/icn-group-heart-KO.svg';
import appDemo from'../../assets/cdphp-app-demo.png';

import organization from '../../assets/svg/icon-hcp-steps-organization.svg';
import info from '../../assets/svg/icn-results.svg';
import schedule from'../../assets/svg/icon-hcp-steps-schedule.svg';
import personalizedCare from'../../assets/svg/icon-lp-personalized-care.svg';
import therapy from'../../assets/svg/icon-lp-therapy.svg';
import medicationManagement from'../../assets/svg/icon-lp-medication.svg';
import directMessaging from'../../assets/svg/icon-lp-direct-messaging.svg';
import twentyFourSeven from'../../assets/svg/icon-lp-twenty-four-seven.svg';
import integration from'../../assets/svg/icn-med-professionals.svg';


class Pcp extends Component {
  
	state = {
		showModal: false,
		showCaseStudy: false,        		
	}
	
	componentDidMount () {
  	console.log(this.props);  	
		if(this.props.modal){
			this.openModalHandler();
		}
	} 	

	openModalHandler = (overlay) => {
    if(overlay === 'cs' || this.props.cs){
		  this.setState({showCaseStudy:true});
		  if(!this.props.modal){
    		this.props.history.push('case-study/');  
		  }	  
    } else {
  		this.setState({showModal:true});
		  if(!this.props.modal){
        this.props.history.push('join/');
		  }	  
    }
	}

	closeModalHandler = () => {
		this.props.history.push('/referpatient/');
		this.setState({showModal:false});
		this.setState({showCaseStudy:false});		
	} 
 
  render() {	 
    return (
      <article>
  			<Helmet>
  				<title>aptihealth is Your Patient’s Fast Track to Better Mental Health</title>
  				<meta name="description" content='When a patient presents with behavioral health challenges, they need a specialist’s care as soon as possible. Referring your patient to aptihealth connects them with a virtual behavioral care team within days.' />
  			</Helmet>
        
        <Header btnText='Refer Your Patient' btnLink='https://home.aptihealth.com/app/hcp-workflow' btnStyle='' />
                
        <section id="banner" className='secondary hcp'>
    			<div className="wrap">
    			  <div>
      				<h1>Your Patient’s Fast Track to&nbsp;Better Mental Health</h1>
      				<p>When a patient presents with behavioral health challenges, they need a specialist’s care as soon as possible. Referring your patient to aptihealth connects them with a virtual behavioral care team within days.</p>
              <CTA btnText="Refer Your Patient" btnLink="https://home.aptihealth.com/app/hcp-workflow" btnStyle=""/>
            </div>
    			</div>						
    		</section>
        
  			<section className="p30 grey">
          <div className="wrap">
            <h2 className='subhead'>Refer Patients in 3 Easy Steps</h2>
            <p className='headline left'>Get your patients connected to virtual behavioral healthcare in minutes with our user-friendly online referral form. Once referred, your patient will receive an email to complete registration.</p>
            <div className="three-col hcp-steps">                
              <div className='col'>
                <img src={organization} alt="100%"/>                
                <strong>Confirm Your Organization</strong>
              </div>
              <div className='col'>
                <img src={info} alt="100%"/>                
                <strong>Enter Patient Information</strong>
              </div>
              <div className='col'>
                <img src={schedule} alt="100%"/>                
                <strong>Schedule Their Consultation</strong>
              </div>
            </div>
          </div>        
        </section>
        
        
        <section className="pt30 hcp">
          <div className="wrap">
            <h2 className='subhead'>Access to a Dedicated Care Team Within&nbsp;Days</h2>
            <p className='headline left'>Our licensed therapist and prescribers deliver personalized, goal-oriented care that empowers patients to make positive life changes. Evidence-based workflows for assessment and treatment help patients feel better within 90&nbsp;days.</p>
            <div className="three-col steps">
              <div className="col support">
                <img src={personalizedCare} alt="Personalized Care"/>
                <h3>Care</h3>
                <p>All patients receive a personalized, goal-oriented care plan.</p>
              </div>
              <div className="col therapy">
                <img src={therapy} alt="Therapy"/>
                <h3>Therapy</h3>
                <p>We provide convenient video therapy and easy-to-schedule online appointments.</p>
              </div>
              <div className="col">
                <img src={medicationManagement} alt="Medication Management"/>
                <h3>Medication Management</h3>
                <p>If recommended, our patients receive medication management from a licensed psychiatric&nbsp;prescriber.</p>
              </div>
              <div className="col">
                <img src={directMessaging} alt="Direct Messaging"/>
                <h3>Direct Messaging</h3>
                <p>Members can direct messaging with their therapist for support between sessions.</p>
              </div>
              <div className="col">
                <img src={integration} alt="Care Coordination"/>
                <h3>Care Coordination</h3>
                <p>We coordinate our care with our patients physicians or other specialist.</p>
              </div>
              <div className="col">
                <img src={twentyFourSeven} alt="24/7 Support"/>
                <h3>24/7 Support</h3>
                <p>We offer patients 24/7 support services through our dedicated helpline. Anytime, anywhere, we&rsquo;re always available.</p>
              </div>
            </div>
            <p className='desc'>aptihealth’s experts are dedicated to patients’ success—frequency of therapy, medication management, and their care plan are all personalized to help your patient feel better faster. aptihealth accepts health insurance, co-pays may apply.</p>                
          </div>
        </section>        
        
        {/*<section className="pt30 results hcp">
          <div className="wrap">
            <h2 className='subhead'>Access to a Dedicated Care Team Within&nbsp;Days</h2>
            <p className='headline left'>Our licensed therapist and prescribers deliver personalized, goal-oriented care that empowers them to make positive life changes. Evidence-based workflows for assessment and treatment help patients feel better within 90&nbsp;days.</p>
            <div className="two-col">
              <div className="col">
                <h3>90-Day Care Program</h3>
                <ul className="disc">
                  <li>Personalized online assessment</li>
                  <li>Weekly online therapy</li>
                  <li>Bi-weekly medication management, if needed</li>
                  <li>Direct messaging with their therapist for support between sessions</li>
                  <li>24/7 support services through our dedicated helpline</li>
                  <li>Recurring screenings and progress tracking</li>
                </ul>
              </div>
            
              <div className="col">
                <h3>Extended Care Program</h3>
                <ul className="disc">
                  <li>Seamless transition from 90 day care</li>
                  <li>Bi-weekly online therapy</li>
                  <li>Monthly medication management, if needed</li>
                  <li>Direct messaging with their therapist for support between sessions</li>
                  <li>24/7 support services through our dedicated helpline</li>
                  <li>Recurring screenings and progress tracking</li>
                </ul>
              </div>
            </div>
            <p className='desc'>aptihealth’s experts are dedicated to patients’ success—frequency of therapy, medication management, and their care plan are all personalized to help your patient feel better faster. aptihealth accepts health insurance, co-pays may apply.</p>    
          </div>
        </section> */}       
        
        
        <section id="cta" className="p30 dark pcp hcp">
          <div className="wrap">   
            <img src={supportPatients} alt="Support Patients Today"/> 
            <div>                                
              <h3> Get Patients Support Today</h3>
              <p>Follow-up care for patients with mental illness is critical to improving their physical and mental well-being, and has been linked to fewer repeat emergency department visits and increased compliance with treatment. aptihealth can help by providing the necessary follow-up care within 7 days post-discharge or ED&nbsp;visit.</p>
              <p><CTA btnText="Refer Your Patient" btnLink="https://home.aptihealth.com/app/hcp-workflow" btnStyle="white"/></p>              
            </div>
          </div>        
        </section>         
        
  			<section className="pt60">
          <div className="wrap">
            <div className="two-col pad">
              <div className="col w55">
                <h2 className='subhead'>It&rsquo;s Easy for Patients to Use aptihealth</h2>
                <p>All they need is a video-enabled smartphone or computer. Upon referral, they&rsquo;ll receive an email to complete their registration and login to access aptihealth&rsquo;s secure member portal for:</p>
                <ul className="checks">
                  <li>A personalized assessment</li>
                  <li>Scheduling appointments</li>
                  <li>Online video therapy sessions</li>
                  <li>Direct messaging with a therapist</li>
                </ul>
              </div>
              <div className="col w45">
                <img src={appDemo} className='demoPhoto' alt="aptihealth iPhone Demo" />
              </div>
            </div>
          </div>        
        </section>        
        
        <Stats heading="Driving Breakthrough Results" background='grey' openModalHandler={this.openModalHandler}  />		
        
        <Modal 
          closeModal={this.closeModalHandler} 
          show={this.state.showCaseStudy} 
          hubspot='ad3fffe4-0a99-4277-9817-2d55c9d7fa25'
        >
          <h3>Sign Up for the Case Study!</h3>
        </Modal>                    
        
        
				<Footer/>
  			<Cookie/>
						
  
      </article>
  	);
	}
}
export default Pcp;